/**
 * @author Ahmed Serag
 * @date 2020-07-06
 * @description Implementation of Menu context that holds active branch's menu.
 * @filename menu-context.ts
 */
import * as React from "react";
import { Category } from "interfaces/category";
import { Branch } from "interfaces/branch";

/**
 * Menu context that holds active branch's menu.
 */
export const MENU_CONTEXT: React.Context<{
  /**
   * set of categories  inside the menu.
   */
  categories: Category[];
  /**
   * id of the active category.
   */
  activeCategoryId?: number;
  /**
   * id of the active sub-category.
   */
  activeSubCategoryId?: number;
  /**
   * branch from where the menu is displayed.
   */
  branch: Branch;
  /**
   * a boolean which is true if the menu is loading
   */
  loadingMenu?: boolean;
  /**
   * update current menu with new branch and it's new menu.
   *
   * @param branch new active branch to display it's menu.
   */
  updateMenu: (branch: Branch) => Promise<unknown>;
  /**
   * update active tab in menu listing based on selected sub-category
   */
  updateActiveTab: (
    activeCategoryId: number,
    activeSubCategoryId: number
  ) => void;
}> = React.createContext({
  categories: null,
  branch: null,
  updateMenu: null,
  updateActiveTab: null
});
