/**
 * @author Ahmed Serag
 * @date 2019-07-15
 * @description live location trail layout.
 * @filename live-location.tsx
 */
import React from "react";
import Toastr from "toastr";

/**
 * React Component that renders live location Layout.
 *
 * @export
 * @class LiveLocation
 * @extends {React.Component}
 */
export class LiveLocation extends React.Component<
  unknown,
  { location: GeolocationPosition }
> {
  constructor(props: unknown) {
    super(props);

    this.state = {
      location: null
    };
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        location => {
          this.setState({ location });
        },
        e => {
          console.error("watchPosition", e);
          Toastr.error(e);
        },
        {
          enableHighAccuracy: true
        }
      );
    } else {
      Toastr.error("Browser doesn't support geolocation");
    }
  }

  render(): React.ReactNode {
    return (
      <div>
        {this.state.location && (
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAVKwNtnbkeo5BHMUulmyVZGPH0f-gfFKg&center=${this.state.location.coords.latitude},${this.state.location.coords.longitude}&size=400x400`}
            alt=""
          />
        )}
      </div>
    );
  }
}
