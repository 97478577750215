/**
 * @author Raghda Wessam
 * @date 2020-07-24
 * @description product customizations page
 * @filename custom-product.tsx
 */
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  ProductCustomization,
  ProductSize,
  ProductVariantOption,
  ProductExtra
} from "interfaces/product";
import { Product as ProductUtilities } from "utilities/product";
import { ROUTES } from "consts/routes";
import { ORDER_CONTEXT } from "contexts/order-context";
import { exist as _exist } from "utilities/common";
import { CreateOrderItemInput } from "interfaces/inputs/create-order-item-input";
import {
  addToCartEvent as _addToCartEvent,
  viewItemEvent as _viewItemEvent
} from "utilities/gtag-events";
import { MENU_CONTEXT } from "contexts/menu-context";
import { Category } from "interfaces/category";
import ReactPixel from "react-facebook-pixel";
import { CustomProductSizes } from "./custom-product-sizes";
import { CustomProductVariants } from "./custom-product-variants";
import { CustomProductExtras } from "./custom-product-extras";
import { QuantityCounter } from "../../common/quantity-counter";

/**
 * state used by Customizable Product Component.
 */
interface CustomProductState {
  /**
   * a boolean which is true if the product is still loading.
   */
  isLoading: boolean;
  productDetails?: ProductCustomization;
  availableVariants?: Record<string, ProductVariantOption[]>;
  selectedSize?: ProductSize;
  selectedExtras?: Record<
    string,
    {
      id: number;
      quantity: number;
      itemPrice: number;
    }
  >;
  selectedVariants?: Record<string, ProductVariantOption>;
  selectedQuantity: number;
  defaultExtras?: any;
  isUsingVariantExtras: boolean;
}

export class CustomProduct extends React.Component<
  RouteComponentProps<{
    productId: string;
    branchId: string;
  }>,
  CustomProductState
> {
  declare context: React.ContextType<typeof ORDER_CONTEXT>;

  constructor(
    props: RouteComponentProps<{
      productId: string;
      branchId: string;
    }>
  ) {
    super(props);
    this.state = {
      isLoading: true,
      isUsingVariantExtras: false,
      selectedVariants: {},
      selectedExtras: {},
      selectedQuantity: 1
    };

    this.onSelectSize = this.onSelectSize.bind(this);
    this.onSelectVariant = this.onSelectVariant.bind(this);
    this.onSelectExtra = this.onSelectExtra.bind(this);
    this.onProductQuantityUpdate = this.onProductQuantityUpdate.bind(this);
    this.onAddItem = this.onAddItem.bind(this);
    this.getTotalPrice = this.getTotalPrice.bind(this);
    this.firstBogoItem = this.firstBogoItem.bind(this);
  }

  componentDidMount() {
    const { productId, branchId } = this.props.match.params;

    ProductUtilities.getProductCustomizations(branchId, productId)
      .then(customizations => {
        if (!customizations) {
          throw new Error("Error loading product details");
        }
        ReactPixel.track("ViewContent", {
          ProductId: productId,
          ProductName: customizations.name,
          BranchId: branchId,
          Price: customizations.price
        });

        const availableVariants: Record<string, ProductVariantOption[]> = {};
        const selectedVariants: Record<string, ProductVariantOption> = {};
        const selectedSize: ProductSize = customizations.sizes?.find(size => {
          return size.default;
        });
        const defaultExtras = customizations.extras;

        customizations.variants.sort((a, b) => b.new - a.new);

        for (const variant of customizations.variants) {
          if (_exist(availableVariants[variant.variant_category?.name])) {
            availableVariants[variant.variant_category?.name].push(variant);
          } else {
            availableVariants[variant.variant_category?.name] = [variant];
          }
          if (variant.default) {
            selectedVariants[variant.variant_category?.name] = variant;
          }
        }

        _viewItemEvent({
          id: `${customizations.id}`,
          name: customizations.name
        });

        this.setState({
          availableVariants,
          selectedSize,
          selectedVariants,
          productDetails: customizations,
          isLoading: false,
          isUsingVariantExtras: false,
          defaultExtras
        });
        if (
          customizations.variants.length > 0 &&
          customizations.variants[0].new &&
          customizations.is_subscription_product === false
        ) {
          this.onSelectVariant(customizations.variants[0]);
        }
      })
      .catch(error => {
        console.error(error);
        this.props.history.push(ROUTES.error.path);
      });
  }

  onSelectSize(sizeId: number) {
    const selectedSize = this.state.productDetails.sizes.find(
      size => size.id === sizeId
    );
    this.setState({ selectedSize });
  }

  onSelectVariant(variantOption: ProductVariantOption) {
    const selectedVariants = this.state.selectedVariants;
    selectedVariants[variantOption.variant_category.name] = variantOption;
    if (variantOption.variant_extras.length > 0) {
      const productDetails = this.state.productDetails;

      productDetails.extras = variantOption.variant_extras;

      this.setState({
        productDetails,
        selectedExtras: {},
        isUsingVariantExtras: true
      });
    } else {
      const productDetails = this.state.productDetails;
      productDetails.extras = this.state.defaultExtras;
      if (this.state.isUsingVariantExtras === true) {
        this.setState({
          productDetails,
          selectedExtras: {},
          isUsingVariantExtras: false
        });
      } else {
        this.setState({ productDetails });
      }
    }
    this.setState({ selectedVariants });
  }

  onSelectExtra(extra: ProductExtra, quantity: number) {
    const selectedExtras = this.state.selectedExtras;
    selectedExtras[`${extra.id}`] = {
      quantity,
      id: extra.id,
      itemPrice: extra.price
    };
    this.setState({ selectedExtras });
  }

  /**
   * update the total number of product quantity
   *
   * @param {number} quantity
   * @memberof CustomProduct
   */
  onProductQuantityUpdate(quantity: number) {
    this.setState({ selectedQuantity: quantity });
  }

  onAddItem(menu: Category[]) {
    const orderItem: CreateOrderItemInput = {
      id: this.state.productDetails.id,
      quantity: this.state.selectedQuantity,
      price: this.getTotalPrice(),
      product: this.state.productDetails
    };
    const selectedVariantsKeys = Object.keys(this.state.selectedVariants);
    const selectedExtrasKeys = Object.keys(this.state.selectedExtras);

    if (this.state.selectedSize) {
      orderItem.size = this.state.selectedSize.id;
    }

    if (selectedExtrasKeys.length > 0) {
      orderItem.extras = selectedExtrasKeys.map(key => {
        if (this.state.selectedExtras[key].quantity > 0) {
          return {
            id: this.state.selectedExtras[key].id,
            quantity: this.state.selectedExtras[key].quantity
          };
        }
        return null;
      });

      // remove empty extras
      orderItem.extras = orderItem.extras.filter(extra => {
        return extra != null;
      });
    }

    if (selectedVariantsKeys.length > 0) {
      orderItem.variants = selectedVariantsKeys.map(key => {
        return this.state.selectedVariants[key].id;
      });
    }

    _addToCartEvent({
      currency: "EGP",
      value: `${orderItem.price}`,
      items: [
        {
          id: `${orderItem.id}`,
          name: orderItem?.product?.name,
          quantity: orderItem.quantity,
          category: ProductUtilities.getProductSubCategory(
            menu,
            orderItem?.product
          )?.name
        }
      ]
    });
    this.context.updateItem(orderItem, `${Date.now()}`);
    this.props.history.goBack();
  }

  getTotalPrice(): number {
    let totalPrice: number =
      this.state.selectedSize?.price ?? this.state.productDetails.price ?? 0;

    for (const variantCategory of Object.keys(this.state.selectedVariants)) {
      totalPrice += this.state.selectedVariants[variantCategory].price;
    }

    for (const extra of Object.keys(this.state.selectedExtras)) {
      if (!Number.isNaN(this.state.selectedExtras[extra].itemPrice)) {
        totalPrice +=
          this.state.selectedExtras[extra].itemPrice *
          this.state.selectedExtras[extra].quantity;
      }
    }

    totalPrice *= this.state.selectedQuantity;

    if (this.state.productDetails.campaigns?.campaign_percentage) {
      totalPrice -=
        (Number(this.state.productDetails.campaigns?.campaign_percentage) *
          totalPrice) /
        100;
    }

    return totalPrice;
  }

  /**
   * check if the item is bogo and it's the first time to add it.
   * @returns boolean which is true if this is the first item to be selected.
   */
  firstBogoItem(): boolean {
    if (!this.state.productDetails?.campaigns?.has_bogo) {
      return false;
    }
    let firstItem = false;
    const orderItems: CreateOrderItemInput[] = [];
    for (const item in this.context.items) {
      if (this.context.items[item].id === this.state.productDetails.id) {
        orderItems.push(this.context.items[item]);
      }
    }

    if (orderItems.length === 0 || orderItems.length % 2 !== 0) {
      firstItem = true;
    }
    return firstItem;
  }

  render(): React.ReactNode {
    if (this.state.isLoading) {
      return <div />;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { extras, sizes, name, description, image, is_subscription_product } =
      this.state.productDetails ?? {};
    const descriptionBlocks = description.split("\\n\\n");
    const { branchId } = this.props.match.params;
    const availableVariantsKeys = Object.keys(this.state.availableVariants);

    return (
      <div className="cart__container custom-product__container">
        <div className="cart__header">
          <button
            className="button secondary-font"
            type="button"
            onClick={() => {
              this.props.history.push(ROUTES.BranchMenu.getPath(branchId));
            }}
          >
            Back
          </button>
          <div className="custom-product__img-wrapper">
            <img
              className="custom-product__img"
              src={image}
              alt="Cilantro’s signature Coffee Mocha Frappe"
            />
          </div>
        </div>
        <div className="cart__items">
          <div className="custom-product__description">
            <h1 className="title primary-font">{name}</h1>
            {descriptionBlocks.map(block => (
              <>
                <p className="description">{block}</p>
                <br />
              </>
            ))}
          </div>
          <div className="custom-product__selection">
            {sizes?.length > 0 && !is_subscription_product && (
              <MENU_CONTEXT.Consumer>
                {value => {
                  // TODO: MOVE SIZES FILTERING TO BACKEND!!
                  const isColdCoffee = value?.categories?.find(c =>
                    c.subcategories?.find(
                      sub =>
                        sub.name.toLowerCase() === "iced coffee" &&
                        sub.products?.find(
                          p => p.id === this.state.productDetails.id
                        )
                    )
                  );

                  if (
                    isColdCoffee &&
                    value?.branch?.sector?.name?.toLowerCase() === "sahel"
                  ) {
                    return <></>;
                  }
                  return (
                    <CustomProductSizes
                      sizes={sizes}
                      onChangeSizeSelection={this.onSelectSize}
                      selectedItem={this.state.selectedSize}
                    />
                  );
                }}
              </MENU_CONTEXT.Consumer>
            )}
            {availableVariantsKeys.length > 0 && !is_subscription_product && (
              <CustomProductVariants
                variants={this.state.availableVariants}
                selectedVariants={this.state.selectedVariants}
                onSelectVariant={this.onSelectVariant}
              />
            )}
            {extras?.length > 0 && !is_subscription_product && (
              <CustomProductExtras
                extras={extras}
                selectedExtras={this.state.selectedExtras}
                onSelectExtra={this.onSelectExtra}
              />
            )}
            {!is_subscription_product && (
              <div className="custom-product__quantity">
                <span>Quantity</span>
                <QuantityCounter
                  minQuantity={1}
                  currentQuantity={this.state.selectedQuantity}
                  onQuantityUpdate={this.onProductQuantityUpdate}
                />
              </div>
            )}
          </div>
        </div>
        <MENU_CONTEXT.Consumer>
          {value => (
            <div className="button__wrapper button__wrapper--fixed">
              <button
                className="button--primary button--fixed primary-font"
                type="button"
                onClick={() => {
                  ReactPixel.track("AddToCart", {
                    ProductId: this.state.productDetails.id,
                    ProductName: this.state.productDetails.name,
                    BranchId: branchId,
                    Price: this.getTotalPrice().toFixed(2)
                  });
                  return this.onAddItem(value.categories);
                }}
              >
                <div className="button-content">
                  Add to cart
                  <div className="total-price">
                    <span className="price-title secondary-font">Total:</span>
                    <span className="item-price secondary-font">
                      {this.getTotalPrice().toFixed(2)}
                      <span className="currency secondary-font">EGP</span>
                    </span>
                  </div>
                </div>
              </button>
              {value?.branch?.discount > 0 ? (
                <div className="discount">
                  <p>Enjoy {value.branch.discount}% off your order!</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </MENU_CONTEXT.Consumer>
      </div>
    );
  }
}

CustomProduct.contextType = ORDER_CONTEXT;
