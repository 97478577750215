/**
 * @author Ahmed Serag
 * @date 2021-04-26
 * @description Order Tracking card component
 * @filename order-tracking-card.tsx
 */
import React from "react";

export interface OrderTrackingCardProps {
  steps: {
    label: string;
    completed?: boolean;
  }[];
}

export class OrderTrackingCard extends React.Component<OrderTrackingCardProps> {
  render(): React.ReactNode {
    const REMAINING_STEP = (
      <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1">
        <g
          id="Design"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
        >
          <g
            id="Track-order-shared-location/01"
            transform="translate(-335.000000, -300.000000)"
            stroke="#FFF6DF"
            strokeWidth="1.2"
          >
            <g id="tracking" transform="translate(33.000000, 277.000000)">
              <g id="check/empty" transform="translate(303.000000, 24.000000)">
                <path
                  fill="#bf5627"
                  d="M7.77562971,15 C13.2233937,15 15,11 15,7.5 C15,4 10.9017576,0 7.77562971,0 C4.64950184,0 0,1.69024376 0,7.5 C0,13.3097562 2.32786567,15 7.77562971,15 Z"
                  id="Oval"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );

    const COMPLETED_STEP = (
      <svg width="26px" height="27px" viewBox="0 0 26 27" version="1.1">
        <g
          id="Design"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Track-order-shared-location/01"
            transform="translate(-156.000000, -295.000000)"
          >
            <g id="tracking" transform="translate(33.000000, 277.000000)">
              <g id="check/filled" transform="translate(123.000000, 18.123221)">
                <path
                  d="M12,25.3237633 C21.2118035,27.0402253 24,18.4767786 24,12.8767786 C24,7.27677864 19.0064719,4.07497191 12.4410075,0.876778645 C5.87554321,-2.32141462 0,3.58116866 0,12.8767786 C0,22.1723886 2.7881965,23.6073014 12,25.3237633 Z"
                  id="Oval-Copy-2"
                  fill="#F3CFB2"
                />
                <path
                  d="M9.61977488,10.9294155 C8.59666974,14.2276576 8.59666974,15.8767786 9.61977488,15.8767786 C11.1544326,15.8767786 20.9876335,2.24564826 24.597727,2.24564826"
                  id="Path-2"
                  stroke="#BF5627"
                  strokeWidth="2.2"
                  strokeLinecap="round"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );

    return (
      <div className="order-tracking-card">
        {this.props.steps.map((step, index) => {
          return (
            <div
              key={index}
              className={`order-step${step.completed ? " completed" : ""}`}
            >
              <div className="icon-wrapper">
                {step.completed ? COMPLETED_STEP : REMAINING_STEP}
              </div>
              {step.label}
            </div>
          );
        })}
      </div>
    );
  }
}
