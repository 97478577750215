/**
 * @author Ahmed Serag
 * @date 2021-01-26
 * @description Implementation of gtag related utilities.
 * @filename gtag-events.ts
 */

export function pageViewEvent(
  // eslint-disable-next-line no-restricted-globals
  page_location: string = location.host,
  page_path?: string,
  page_title?: string
): void {
  (window as any).gtag("event", "page_view", {
    page_location,
    page_path,
    page_title
  });
}

export interface PurchaseEventParameters {
  affiliation?: string;
  coupon?: string;
  currency?: string;
  shipping?: number;
  tax?: number;
  transaction_id?: string;
  value?: number;
  items?: PurchaseItem[];
}

export interface PurchaseItem {
  id: string;
  name: string;
  brand?: string;
  category?: string;
  coupon?: string;
  list_name?: string;
  list_position?: number;
  price?: number;
  quantity?: number;
  variant?: string;
}

export function purchaseEvent(params: PurchaseEventParameters) {
  (window as any).gtag("event", "purchase", params);
}

export interface ViewItemEventParameters {
  id: string;
  name: string;
  brand?: string;
  category?: string;
  coupon?: string;
  list_name?: string;
  list_position?: number;
  price?: number;
  quantity?: number;
  variant?: string;
}

export function viewItemEvent(params: ViewItemEventParameters) {
  (window as any).gtag("event", "view_item", params);
}

export interface AddToCartEventParameters {
  currency?: string;
  items?: ViewItemEventParameters[];
  value?: string;
}

export function addToCartEvent(params: AddToCartEventParameters) {
  (window as any).gtag("event", "add_to_cart", params);
}

export interface RemoveFromCartEventParameters {
  currency?: string;
  items?: ViewItemEventParameters[];
  value?: string;
}

export function RemoveFromCartEvent(params: RemoveFromCartEventParameters) {
  (window as any).gtag("event", "remove_from_cart", params);
}

export interface BeginCheckoutEventParameters {
  coupon?: string;
  currency?: string;
  items: ViewItemEventParameters[];
  value?: number;
}

export function beginCheckoutEvent(params: BeginCheckoutEventParameters) {
  (window as any).gtag("event", "begin_checkout", params);
}

export interface CheckoutProgressEventParameters {
  coupon?: string;
  currency?: string;
  checkout_option?: string;
  checkout_step?: number;
  value?: number;
  items?: ViewItemEventParameters[];
}

export function CheckoutProgressEvent(params: CheckoutProgressEventParameters) {
  (window as any).gtag("event", "checkout_progress", params);
}

export interface SetCheckoutOptionParameters {
  checkout_option?: string;
  checkout_step?: number;
  value?: number;
}

export function SetCheckoutOptionEvent(params: SetCheckoutOptionParameters) {
  (window as any).gtag("event", "set_checkout_option", params);
}
