/**
 * @author Raghda Wessam
 * @date 2020-07-24
 * @description custom product variants component.
 * @filename custom-product-variants.tsx
 */
import React from "react";
import { ProductVariantOption } from "interfaces/product";
import { CollapsibleItem } from "../../common/collapsible-item";
import STICKERS from "../../../definitions/consts/stickers";

interface CustomProductVariantsProps {
  variants: Record<string, ProductVariantOption[]>;
  selectedVariants: Record<string, ProductVariantOption>;
  onSelectVariant: (variant: ProductVariantOption) => void;
}

/**
 * React component to custom product available variants.
 */
export class CustomProductVariants extends React.Component<
  CustomProductVariantsProps
> {
  render(): React.ReactNode {
    const variantsKeys = Object.keys(this.props.variants);

    return (
      <>
        {variantsKeys.map(variantKey => {
          const variantOptions = this.props.variants[variantKey];

          return (
            <CollapsibleItem
              key={variantKey}
              title={variantKey}
              styles="variants__container"
              initialOpen
              selectedItemName={
                this.props.selectedVariants[variantKey]
                  ? this.props.selectedVariants[variantKey].name
                  : ""
              }
              selectedItemPrice={
                this.props.selectedVariants[variantKey]
                  ? this.props.selectedVariants[variantKey].price
                  : 0
              }
            >
              <ul className="option__list general__list">
                {variantOptions.map(option => {
                  const active = this.props.selectedVariants[variantKey]
                    ? this.props.selectedVariants[variantKey].id === option.id
                    : false;
                  const className = active
                    ? option.new
                      ? "option-list-item option-list-item--new-active"
                      : "option-list-item option-list-item--active"
                    : option.new
                    ? "option-list-item option-list-item--new"
                    : "option-list-item";

                  const component = STICKERS[option.name] ? (
                    <img
                      src={STICKERS[option.name]}
                      alt="sticker"
                      width="50"
                      height="50"
                    />
                  ) : (
                    option.name
                  );

                  return (
                    <li
                      key={option.id}
                      className={className}
                      onClick={() => {
                        this.props.onSelectVariant(option);
                      }}
                    >
                      {component}
                    </li>
                  );
                })}
              </ul>
            </CollapsibleItem>
          );
        })}
      </>
    );
  }
}
