/**
 * @author Ahmed Serag
 * @date 2021-5-25
 * @description Implementation of google maps utilities.
 * @filename map.ts
 */

import { exist, isEmpty } from "./common";

/**
 * implementation of different maps related functionalities.
 *
 * @export
 * @class Map
 */
export class Maps {
  /**
   * instance of geocoder class for converting addresses/LatLong.
   *
   * @static
   * @type {google.maps.Geocoder}
   * @memberof Map
   */
  static geocoder: google.maps.Geocoder;

  /**
   * loads google maps script if not loaded.
   *
   * @static
   * @returns {Promise<void>}
   * @memberof Maps
   */
  public static load(): Promise<void> {
    let promise: Promise<void>;

    if (exist(window, ["google"])) {
      promise = Promise.resolve();
    } else {
      promise = new Promise(
        (resolve: () => void, reject: (error: any) => void) => {
          // create the script tag that loads the google maps sdk
          const googleMapScript = document.createElement("script");
          googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.MAPS_API_KEY}&libraries=geometry,places`;
          // add the script tag to the document.
          window.document.body.appendChild(googleMapScript);

          googleMapScript.addEventListener("load", () => {
            this.geocoder = new google.maps.Geocoder();
            resolve();
          });
          googleMapScript.addEventListener("error", e => {
            reject(e);
          });
        }
      );
    }

    return promise;
  }

  /**
   * initialize Map instance or return the current map instance if found.
   *
   * @static
   * @param {React.RefObject<HTMLDivElement>} mapRef ReactRef of the div to render the map in it.
   * @returns {Promise<any>}
   * @memberof Map
   */
  public static init(
    mapRef: React.RefObject<HTMLDivElement>,
    mapOptions?: google.maps.MapOptions
  ): Promise<google.maps.Map> {
    let map: google.maps.Map;
    let promise: Promise<any> = Promise.resolve();

    if (!exist(window, ["google"])) {
      promise = Maps.load();
    }

    return promise.then(() => {
      if (exist(mapRef.current)) {
        map = new google.maps.Map(mapRef.current, {
          center: {
            lat: 30.0444,
            lng: 31.2357
          },
          streetViewControl: false,
          clickableIcons: false,
          mapTypeControl: false,
          zoom: 18,
          ...mapOptions
        });
      }
      return Promise.resolve(map);
    });
  }

  /**
   * do reverse geocoding for a latLong and return
   * a promise with the retrieved address.
   *
   * @static
   * @returns {Promise<google.maps.GeocoderResult>} promise to get the address
   * of the LatLong address.
   * @memberof Map
   */
  public static getAddressFromLatLng(
    lat: number,
    lng: number
  ): Promise<google.maps.GeocoderResult> {
    return new Promise(
      (
        resolve: (address: google.maps.GeocoderResult) => void,
        reject: (error: any) => void
      ): void => {
        if (!exist(this.geocoder)) {
          reject(null);
        }

        this.geocoder.geocode(
          {
            location: {
              lat,
              lng
            }
          },
          (
            result: google.maps.GeocoderResult[],
            status: google.maps.GeocoderStatus
          ) => {
            if (status !== google.maps.GeocoderStatus.OK) {
              reject(status);
            }

            if (!isEmpty(result)) {
              resolve(result[0]);
            } else {
              reject(null);
            }
          }
        );
      }
    );
  }

  /**
   * add a marker in the map to a specific place with configuration.
   *
   * @static
   * @param {google.maps.map} [map] map instance that the place Marker should be assigned to.
   * @param {google.maps.GeocoderResult} [place] place that needs to assign the marker for.
   * @returns {PlaceMarker}
   * @memberof Maps
   */
  public static addMarkerToPosition(
    map: google.maps.Map,
    position: {
      lat: number;
      lng: number;
    }
  ): google.maps.Marker {
    let marker: google.maps.Marker;
    if (exist(window, ["google"])) {
      marker = new google.maps.Marker({
        map,
        draggable: false,
        position
      });
      map.setCenter(position);
    }
    return marker;
  }
}
