/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * @author Ahmed Elshantaly
 * @date 2020-08-05
 * @description Free gift page
 * @filename free-gift.tsx
 */
import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import FreeGiftIllustration from "static/images/illustrations/free-gift-illustration.svg";
import { ORDER_CONTEXT } from "contexts/order-context";
import { exist, isEmpty } from "utilities/common";
import { ROUTES } from "consts/routes";
import { Gift, GIFTS_LEVELS, SAHEL_GIFTS_LEVELS } from "consts/gifts";
import { Order } from "utilities/order";
import Toastr from "toastr";
import { MENU_CONTEXT } from "contexts/menu-context";
import { PromocodeType } from "interfaces/order";
import { Product } from "interfaces/product";
import { Branch } from "interfaces/branch";
import Loader from "../../common/loader";

/**
 * React component to render free gift page.
 */
export class FreeGift extends React.Component<
  RouteComponentProps,
  { selectedGiftIndex: number }
> {
  declare context: React.ContextType<typeof ORDER_CONTEXT>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      selectedGiftIndex: 0
    };
    this.getAvailableGifts = this.getAvailableGifts.bind(this);
  }

  onSelectGift(giftComment: string, freeItem?: Product) {
    if (this.context.promocodeFreeProducts) {
      if (freeItem) {
        this.context.updateItem(
          {
            quantity: 1,
            id: freeItem.id,
            promocode_gift: true,
            product: freeItem,
            price: freeItem.price
          },
          `${Date.now()}`
        );
      }
      this.props.history.goBack();
      return;
    }

    if (this.context.order?.id) {
      Order.updateOrderComment(giftComment, this.context.order.id)
        .then(() => {
          this.props.history.push(
            ROUTES.Confirmation.getPath(this.context.order.id)
          );
        })
        .catch(error => {
          Toastr.error(error);
        });
    }
  }

  getAvailableGifts(branch: Branch): Gift[] | Product[] {
    let availableGifts: Gift[];

    if (exist(this.context.order?.gift)) {
      // TODO: don't count on values from DB!
      if (branch?.sector?.name?.toLowerCase() === "sahel") {
        availableGifts = SAHEL_GIFTS_LEVELS[`${this.context.order?.gift}`];
      } else {
        availableGifts = GIFTS_LEVELS[`${this.context.order?.gift}`];
      }
    }

    if (this.context.promocodeFreeProducts) {
      availableGifts = this.context.promocodeFreeProducts;
    }

    return availableGifts;
  }

  render(): React.ReactNode {
    return (
      <MENU_CONTEXT.Consumer>
        {value => {
          const availableGifts = this.getAvailableGifts(value.branch) ?? [];

          if (
            !exist(this.context?.order?.gift) &&
            this.context?.promocodeType !== PromocodeType.freeGift
          ) {
            return <Redirect to={ROUTES.SelectBranch.path} />;
          }
          if (this.context.loadingOrder) {
            return <Loader />;
          }

          return (
            <div className="free-gift">
              <div className="free-gift__image">
                <img src={FreeGiftIllustration} alt="free-gift" />
              </div>
              <div className="free-gift__message">
                <div className="free-gift__congrats">
                  Congratulations You got a free gift
                </div>
                <div className="free-gift__choose-gift">
                  {this.context?.order?.gift === 2 &&
                  this.context?.promocodeType !== PromocodeType.freeGift ? (
                    <>
                      {" "}
                      <p className="gift-subtitle">
                        Our menu is all yours! Choose your favorite menu item as
                        a gift!
                      </p>
                      <p className="gift-info">
                        ..and it does not stop here, keep ordering to receive
                        all the gifts over again!
                      </p>
                    </>
                  ) : this.context?.order?.gift > 1 ? (
                    "You can choose at store one of the following products and collect with order"
                  ) : !isEmpty(availableGifts) ? (
                    "choose one of the following gifts"
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="free-gift__gifts">
                {availableGifts?.map((gift, index) => {
                  return (
                    <div
                      key={index}
                      className="free-gift__gifts-wrapper"
                      onClick={() => {
                        if (this.state.selectedGiftIndex !== index) {
                          this.setState({ selectedGiftIndex: index });
                        }
                      }}
                    >
                      <div
                        className={`free-gift__gifts-item${
                          this.state.selectedGiftIndex === index
                            ? " free-gift__gifts-item-selected"
                            : ""
                        }`}
                        key={index}
                      >
                        <img src={gift.image} alt="" />
                      </div>
                      <p
                        className={
                          this.state.selectedGiftIndex === index
                            ? "selected"
                            : ""
                        }
                      >
                        {gift.name}
                      </p>
                    </div>
                  );
                })}
                {isEmpty(availableGifts) &&
                  this.context?.promocodeType === PromocodeType.freeGift && (
                    <p className="gift-info">
                      Sorry, The gifts are out of stock, please contact the
                      Barista!
                    </p>
                  )}
              </div>
              <button
                type="button"
                onClick={() => {
                  const comment = this.context?.order?.comment
                    ? `${this.context?.order?.comment} 
                    gift: ${
                      availableGifts
                        ? availableGifts[this.state.selectedGiftIndex]?.name
                        : ""
                    }`
                    : `gift: ${
                        availableGifts
                          ? availableGifts[this.state.selectedGiftIndex]?.name
                          : ""
                      }`;
                  const selectedGift =
                    availableGifts[this.state.selectedGiftIndex] ?? null;
                  this.onSelectGift(comment, selectedGift as Product);
                }}
              >
                Redeem Gift
              </button>
            </div>
          );
        }}
      </MENU_CONTEXT.Consumer>
    );
  }
}
FreeGift.contextType = ORDER_CONTEXT;
