/**
 * @author Ahmed Serag
 * @date 2020-07-05
 * @description Select branch Layout of the App.
 * @filename select-branch.tsx
 */
import React from "react";
import orderBy from "lodash.orderby";
import {
  Branch as BranchInterface,
  BranchAvailability
} from "interfaces/branch";
import Toastr from "toastr";
import { Branch as BranchUtilities } from "utilities/branch";
import {
  exist as _exist,
  getParamFromSearchUrl as _getParamFromSearchUrl,
  isEmpty as _isEmpty,
  getHoursDifference as _getHoursDifference
} from "utilities/common";
import { USER_CONTEXT } from "contexts/user-context";
import loyaltyIllustration from "static/images/illustrations/loyalty-illustration.svg";
import SubscriptionCup from "static/images/illustrations/subscription-cup.svg";
import SubscriptionTitle from "static/images/illustrations/sub-car-title.svg";
import Spearator from "static/images/illustrations/spreator.svg";
import Logo from "static/images/icons/logo.svg";
import { MENU_CONTEXT } from "contexts/menu-context";
import { RouteComponentProps } from "react-router-dom";
import { ROUTES } from "consts/routes";
import { pageViewEvent } from "utilities/gtag-events";
import { ORDER_CONTEXT } from "contexts/order-context";
import SelectField from "../../common/select-field";
import { LoyaltyStampCard } from "../../common/loyalty-stamp-card";
import Loader from "../../common/loader";

interface SelectBranchState {
  branches: BranchInterface[];
  isLoading: boolean;
  selectedBranch?: BranchInterface;
}

/**
 * React component to render select branch page.
 */
export class SelectBranch extends React.Component<
  RouteComponentProps,
  SelectBranchState
> {
  declare context: React.ContextType<typeof MENU_CONTEXT>;

  constructor(props: RouteComponentProps) {
    super(props);
    BranchUtilities.removeBranchFromLocalStorage();
    this.state = {
      branches: [],
      isLoading: true
    };
    this.fetchBranchMenu = this.fetchBranchMenu.bind(this);
    this.getButtonStyle = this.getButtonStyle.bind(this);
    this.saveTableNumber = this.saveTableNumber.bind(this);
  }

  componentDidMount() {
    // save table number from url
    // this.saveTableNumber();
    pageViewEvent(undefined, this.props.location.pathname, "select-branch");
    BranchUtilities.listBranches().then(branches => {
      this.setState(
        {
          branches,
          isLoading: false
        },
        () => {
          const locationParams = _getParamFromSearchUrl(
            "branch_id",
            this.props.location.search
          );
          if (locationParams) {
            const selectedBranch = this.state.branches.find(
              f => `${f.UUID}` === `${locationParams}`
            );
            this.setState({ selectedBranch });
          }
        }
      );
    });
  }

  /**
   * return button style classes for 3 different states
   * state could be normal, loading or disabled
   *
   * @returns {string}
   * @memberof SelectBranch
   */
  getButtonStyle(): string {
    const buttonBaseClass = "button--primary";
    let buttonClass = "button--primary";
    if (!this.state.selectedBranch) {
      buttonClass = `${buttonBaseClass} button--disabled`;
    }
    if (this.state.isLoading) {
      buttonClass = `${buttonBaseClass} button--loading`;
    }
    return buttonClass;
  }

  saveTableNumber() {
    const tableNumberParams = _getParamFromSearchUrl(
      "table_number",
      this.props.location.search
    );
    if (!_isEmpty(tableNumberParams)) {
      const currentDate = new Date(Date.now());
      // save in localStorage
      localStorage.setItem(
        "table-number-timestamp",
        JSON.stringify(currentDate.toString())
      );
      localStorage.setItem(
        "order-table-number",
        JSON.stringify(tableNumberParams.toString())
      );
    } else if (!_isEmpty(localStorage.getItem("table-number-timestamp"))) {
      // check previous saved table number
      const previousTableNumberTimestamp = new Date(
        localStorage.getItem("table-number-timestamp")
      );
      const currentDate = new Date(Date.now());
      if (
        _getHoursDifference(currentDate, previousTableNumberTimestamp) >= 12
      ) {
        // remove from localStorage if 12 or more hours passed
        localStorage.removeItem("table-number-timestamp");
        localStorage.removeItem("order-table-number");
      }
    }
  }

  fetchBranchMenu() {
    if (!_exist(this.state.selectedBranch?.id)) {
      // TODO handle error trying to load the branch menu without selecting a branch.
      throw new Error("you need to select a branch first");
    }
    if (this.state.selectedBranch.availability === BranchAvailability.closed) {
      Toastr.options.positionClass = "toast-top-full-width";
      Toastr.error("Branch is currently Busy or closed");
    }

    this.setState({ isLoading: true });
    return this.context.updateMenu(this.state.selectedBranch);
  }

  render(): React.ReactNode {
    const orderedBranches = orderBy(
      this.state.branches,
      ["sector_id"],
      ["desc"]
    );
    const selectOptions = orderedBranches?.map(branch => {
      return {
        value: `${branch.id}`,
        label: branch.name
      };
    });
    return (
      <>
        <div className="select-branch">
          <div className="select-branch__container illustration-wrapper">
            <img
              className="illustration-wrapper__logo"
              src={Logo}
              alt="Cilantro logo"
            />
          </div>
          <div className="card">
            <h2 className="card--title primary-font">Select Branch</h2>
            <SelectField
              placeholder={
                this.state.selectedBranch
                  ? this.state.selectedBranch.name
                  : "Search for a branch"
              }
              options={selectOptions}
              onChange={value => {
                const selectedBranch = this.state.branches.find(
                  f => Number(f.id) === Number(value.value)
                );
                this.setState({ selectedBranch });
              }}
            />
            <USER_CONTEXT.Consumer>
              {value => {
                return (
                  <>
                    {value.user?.subscriptions[0] && (
                      <>
                        <div className="subscription-cards">
                          <div className="img-wrapper">
                            <img
                              className="subscription-cup-img"
                              src={SubscriptionCup}
                              alt="subscription Cup"
                            />
                          </div>
                          <ul className="general__list subscription-list ">
                            <li>
                              <img
                                className="subscription-card-title"
                                src={SubscriptionTitle}
                                alt="subscription Cup"
                              />
                            </li>
                            <li className="primary-font subscription-list-item-name">
                              {
                                value.user?.subscriptions[0]
                                  ?.subscription_product?.name
                              }
                            </li>
                            <li className="primary-font subscription-list-item-bottom">
                              coffee club membership!
                            </li>
                          </ul>
                        </div>
                        <div>
                          <ul className="general__list card-info-container">
                            <li className="card-info-items-one">
                              <span className="card-info-text primary-font">
                                Expiry:{" "}
                              </span>
                              <span>
                                {new Date(
                                  value.user?.subscriptions[0]?.end_date
                                ).toLocaleDateString("en-uk", {
                                  day: "numeric",
                                  month: "long"
                                })}
                              </span>
                            </li>
                            <li className="card-info-items-two">
                              <span className="card-info-text primary-font">
                                Balance:{" "}
                              </span>
                              <span>
                                {value.user?.subscriptions[0]?.remaining_drinks}{" "}
                                Cups Left
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="img-wrapper">
                          <img src={Spearator} alt="loyalty steps" />
                        </div>
                      </>
                    )}
                  </>
                );
              }}
            </USER_CONTEXT.Consumer>

            <div className="img-wrapper">
              <img
                className="loyalty-img"
                src={loyaltyIllustration}
                alt="loyalty steps"
              />
            </div>

            <ul className="general__list loyalty-steps ">
              <li className="loyalty-list-item primary-font">
                Collect 4 stamps
              </li>
              <li className="loyalty-list-item primary-font">
                Receive a free gift
              </li>
              <li className="loyalty-list-item primary-font">
                Repeat for better gifts!
              </li>
            </ul>
            <USER_CONTEXT.Consumer>
              {value => {
                return (
                  <>
                    {value.user && (
                      <div className="confirmation__loyalty">
                        <LoyaltyStampCard
                          loyaltyProgress={value.user.loyalty_progress}
                        />
                      </div>
                    )}
                  </>
                );
              }}
            </USER_CONTEXT.Consumer>
            <div className="button__wrapper">
              <ORDER_CONTEXT.Consumer>
                {value => {
                  return (
                    <button
                      className={`primary-font ${this.getButtonStyle()}`}
                      type="button"
                      onClick={() => {
                        if (
                          this.context.branch?.id !==
                          this.state.selectedBranch?.id
                        ) {
                          value.clearOrder();
                        }
                        this.fetchBranchMenu().then(() => {
                          this.props.history.push(
                            ROUTES.BranchMenuCategories.getPath(
                              `${this.state.selectedBranch?.id}`
                            )
                          );
                        });
                      }}
                    >
                      {!this.state.isLoading && "Get Started"}
                    </button>
                  );
                }}
              </ORDER_CONTEXT.Consumer>
            </div>
          </div>
        </div>
        {this.context.loadingMenu && <Loader />}
      </>
    );
  }
}
SelectBranch.contextType = MENU_CONTEXT;
