/**
 * @author Ahmed Serag
 * @date 2019-08-05
 * @description Terms and conditions Layout of the App.
 * @filename terms-and-conditions.tsx
 */
import React from "react";

export class TermsAndConditions extends React.Component {
  render(): React.ReactNode {
    return (
      <div className="terms-and-conditions">
        <h1>Terms and Conditions</h1>
        <article>
          <h2>Cilantro Privacy Statement</h2>
          <p>Last Revised: August 10, 2020</p>
          <p>
            This Privacy Statement (&quot;Statement&quot;) applies to the
            website located at cilantrocafe.net, shop.cilantrocafe.net, Cilantro
            applications, and any other websites or applications associated with
            Cilantro brands or products that direct the viewer or user to this
            Statement. In this Statement, the terms &quot;Cilantro,&quot;
            &quot;we,&quot; and &quot;us&quot; refers to Cilantro Corporation
            and its respective subsidiaries and affiliated companies. The term
            “your device&quot; refers to any computer, tablet, smart phone or
            other device you are using to access our websites or to operate the
            Cilantro mobile applications. Websites that are owned and operated
            by Cilantro may contain links to websites that are owned and
            operated by other companies.
          </p>
          <p>
            This Statement does not apply to websites and services that are
            owned and operated by third parties. This Statement went into effect
            on the date noted at the top of this webpage. We may update this
            Statement from time to time. We encourage you to look for updates
            and changes to this Statement by checking this date when you access
            our websites and mobile applications.
          </p>

          <h3>
            <u>Information We Collect</u>
          </h3>
          <p>
            As you use our websites and mobile applications or visit one of our
            stores, we collect information about you and the services you use.
            The information we collect falls into three different categories:
            (1) information you give us; (2) information we collect from you
            automatically; and (3) information we collect from other sources.
            Some examples of when we collect this information include when you
            browse or make a purchase on one of our websites; create a Cilantro
            account; use our website or mobile application to purchase, reload
            or redeem a Cilantro Card; use the remote order and pay
            functionality in our mobile applications; buy or send a gift card or
            e-gift; or participate in a survey or promotion.
          </p>
          <h3>
            <u>Information You Give Us</u>
          </h3>
          <p>
            Some information we collect is provided when you use our services.
            This may include your first and last name, username, password, email
            address, postal address, phone number, financial account information
            such as a credit card number, birthday, demographic information
            (such as your gender), and any other information you give us. You
            may also permit us to access information directly from your device,
            such as information in the “contacts list&quot;. You may also
            provide us information about other people, including when you direct
            us to send a gift card or e-gift.
          </p>
          <h3>
            <u>Information We Collect Automatically</u>
          </h3>
          <p>
            Some information is collected by us automatically, including when
            you access our websites, download and use our Cilantro applications
            or otherwise use our services or install our applications. This
            information includes:
          </p>
          <p>
            <b>Purchasing Information</b> – We may collect information about
            your transactions in our stores, on our websites or via our Cilantro
            mobile applications including what products you purchase, how
            frequently you purchase them and any rewards or promotions
            associated with a purchase.
          </p>
          <p>
            <b>Device and Usage Information</b> – We may collect information
            about your browser or device. This information may include the type
            of device you are using, your operating system, your browser, your
            internet service provider, your domain name, your internet protocol
            (IP) address, your device and mobile ad identifiers, the website
            that referred you to our website, the web pages you view (including
            the date and time you viewed them), the services or functionality
            you access or use (including the date and time of your access or
            use), and the subject of the ads you click or scroll over. To
            collect this information,
            <b> we use cookies, web beacons and similar technologies.</b>
          </p>
          <p>
            <b>Location Information</b> – poses of enhancing or facilitating our
            services, such as enabling the functionality of our websites or
            mobile applications that provide you with information about stores
            near you, enabling you to remotely order and pay for our products
            and services, or have certain Cilantro products delivered by a
            third-party vendor. We may also use information about the location
            of the device you are using to help us understand how our websites,
            mobile applications, and other services and functionality are being
            used and to deliver more relevant advertising. If you want to opt
            out of the collection of this location information, please see the
            section below titled
            <b>
              <u>“Your Choices”.</u>
            </b>
          </p>
          <h3>Information We Collect from Other Sources</h3>
          <p>
            Some information we collect is publicly available. For example, we
            may collect information you submit to a blog, a chat room, or a
            social network like Facebook, Twitter or Google+. We may also
            collect information about you from other companies and
            organizations. By gathering additional information about you, we can
            correct inaccurate information, enhance the security of your
            transactions, and give you product recommendations and special
            offers that are more likely to interest you.
          </p>
          <h3>
            <u>How We Use Your Information</u>
          </h3>
          <p>
            We may use the information we collect about you in a variety of
            ways, including to:
          </p>
          <ul>
            <li>
              process your purchases of or requests for products and services;
            </li>
            <li>
              communicate with you about orders, purchases, returns, services,
              accounts, programs,contests, and sweepstakes;
            </li>
            <li>
              respond to your customer service inquiries and requests for
              information;
            </li>
            <li>post your comments or statements on our websites;</li>
            <li>send you personalized promotions and special offers;</li>
            <li>
              inform you about our brands, products, events, or other
              promotional purposes;
            </li>
            <li>
              maintain, improve, and analyze our websites, mobile applications,
              ads, and the products and services we offer;
            </li>
            <li>detect, prevent, or investigate security breaches or fraud;</li>
            <li>
              maintain appropriate records for internal administrative purposes;
            </li>
            <li>
              facilitate the functionality of our websites and mobile
              applications;
            </li>
            <li>
              deliver gift cards or e-gifts in accordance with your
              instructions;
            </li>
            <li>
              provide important product safety information and notice of product
              recalls.
            </li>
          </ul>

          <h3>
            <u>How We Share Your Information</u>
          </h3>
          <p>We may share your information in the following circumstances:</p>
          <p>
            <b>When We Work Together</b> – We may share information between and
            among Cilantro Corporation, its subsidiaries, and affiliated
            companies for purposes of management and analysis, decision making,
            and other business purposes. For example, we may share your
            information with our subsidiaries and affiliated companies to
            administer our loyalty programs, process orders and requests, and
            expand and promote our product and service offerings.
          </p>
          <p>
            <b>When We Work with Service Providers</b> – We may share your
            information with service providers that provide us with support
            services, such as credit card processing, website hosting, email and
            postal delivery, location mapping, product and service delivery,
            analytics services, or conducting academic research.
          </p>
          <p>
            <b>When We Work on Business Transactions</b> – If we become involved
            with a merger or another situation involving the transfer of some or
            all of our business assets, we may share your information with
            business entities or people involved in the negotiation or transfer.
          </p>
          <p>
            <b>When Sharing Helps Us Protect Lawful Interests</b> – We may
            disclose your information if we believe that the disclosure is
            required by law, if we believe that the disclosure is necessary to
            enforce our agreements or policies, or if we believe that the
            disclosure will help us protect the rights, property, or safety of
            Cilantro or our customers or partners.
          </p>
          <p>
            <b>When We Work with Marketing Service Providers</b> – We may share
            your information with marketing service providers to assess, develop
            and provide you with promotions and special offers that may interest
            you, administer contests, sweepstakes and events or for other
            promotional purposes.
          </p>
          <p>
            <b>When You Give Consent</b> – We may share information about you
            with other companies if you give us permission or direct us to share
            the information.
          </p>
          <p>
            <b>When the Information Does Not Identify You</b> – We may share
            your information in a way that does not directly identify you. For
            example, we may share information about your use of our websites and
            mobile applications in a manner that does not identify you or may
            combine information about the nature or frequency of your
            transactions with similar information about other people and share
            the aggregated information for statistical analysis and other
            business purposes.
          </p>
          <p>
            <b>When You Post on Our Websites</b> – If you post information on a
            blog or another part of our websites, the information that you post
            may be seen by other visitors to our websites, including your user
            name.
          </p>
          <h3>
            <u>How We May Allow Others to Collect Your Information</u>
          </h3>
          <p>
            When you use our websites or mobile applications, we may allow third
            parties to collect device and usage information and location
            information across your different devices through mobile software
            development kits,
            <b> cookies, web beacons and similar technologies.</b>
            These third parties collect this information for the following
            purposes and for other purposes consistent with their own privacy
            policies:
          </p>
          <p>
            <b>To Display Ads for Products or Services</b> – We allow some
            advertising companies to collect this information in order to
            display ads that are most relevant to you across your different
            devices and on our own and others’ websites and mobile apps. Please
            see the
            <b>
              <u> “Your Choices”</u>
            </b>
            section of this Statement for more information about opting out of
            targeted advertising and controlling the use of
            <b> cookies, web beacons and similar technologies.</b>
          </p>
          <p>
            <b>To Understand the Use of Our Services</b> – We allow certain
            service providers to collect this information to help us understand
            how our websites and mobile applications are used and to assist us
            in improving the content and operation of our online properties.
            These service providers collect aggregate statistical usage data
            that is not matched or linked to any individual user.
          </p>
          <p>
            <b>To Make the Services of Other Companies Work on Our Websites</b>–
            We allow companies to use cookies, web beacons and similar
            technologies. to enhance the operations of particular services and
            functionality of our websites and mobile applications. For example,
            we allow Adobe to set and enable special cookies that are necessary
            to deliver video content for Adobe Flash Player. These cookies are
            called Flash cookies.
          </p>
          <p>
            <b>To Link Your Activity on Our Websites to Your Social Networks</b>
            – We have added certain features to our websites and mobile
            applications that allow social networks (such as Facebook, Twitter,
            and Google+) to track the activities of their members or collect
            certain information when they use our website or mobile
            applications. These features may include technologies called
            &quot;plug-ins&quot; and &quot;widgets.&quot; Plug-ins create a
            direct link between two websites, and widgets are interactive
            mini-programs that provide other companies’ services on our
            websites. If you are concerned about the way a social network is
            tracking your activity, please contact the social network or review
            its privacy policy. For example, you can review Facebook’s data-use
            policy at
            <a href="https://www.facebook.com/about/privacy/">
              https://www.facebook.com/about/privacy/
            </a>
            . <br />
          </p>
          <p>
            <b>Cookies, web beacons and similar technologies</b>
            <br />
            We and others may use a variety of technologies to collect
            information about your device and use of our websites and mobile
            applications. These technologies include cookies, web beacons, java
            scripts, entity tags, and HTML local storage. Most web browsers can
            be programmed to accept or reject the use of some or all of these
            technologies, although you must take additional steps to disable or
            control other technologies. For more information, please see the
            section of this Statement titled
            <b>
              <u> “Your Choices”</u>
            </b>
          </p>
          <p>
            <b>Cookies</b> – Cookies are small data files that are sent from a
            website’s server and are stored on your device’s hard drive either
            for only the duration of your visit (&quot;session cookies&quot;) or
            for a fixed period (&quot;persistent cookies&quot;). Cookies contain
            information that can later be read by a web server.
          </p>
          <p>
            <b>Web Beacons</b> – Web beacons are small, transparent images that
            are embedded in web pages, applications, and emails that are
            sometimes called “clear gifs,&quot; “single pixel gifs&quot;,
            &quot;page tags&quot; or “web bugs.&quot; We use web beacons to
            track the web pages you visit, to test the effectiveness of our
            marketing, and to find out if an email has been opened and acted on.
          </p>
          <p>
            <b>Java Scripts</b> – Java scripts are code snippets embedded in
            various parts of websites and applications that facilitate a variety
            of operations including accelerating the refresh speed of certain
            functionality or monitoring usage of various online components.
          </p>
          <p>
            <b>Entity Tags</b> – Entity Tags are HTTP code mechanisms that allow
            portions of websites to be stored or “cached&quot; within your
            browser and validates these caches when the website is opened,
            accelerating website performance since the web server does not need
            to send a full response if the content has not changed.
          </p>
          <p>
            <b>HTML5 Local Storage</b> – HTML5 local storage allows data from
            websites to be stored or &quot;cached&quot; within your browser to
            store and retrieve data in HTML5 pages when the website is
            revisited. <br />
            These and similar technologies are used for the following purposes:
          </p>
          <p>
            <b>Services and Functionality</b> – Some of these technologies are
            required to allow you to access and use our websites or mobile
            applications and the various services and functionality we offer.
            Without these technologies, some of the services and functionality
            on our websites and mobile applications would not work properly.
          </p>
          <p>
            <b>Performance Monitoring</b> – Some of these technologies help us
            analyze and estimate traffic and assess the volume of use of
            services and functionality on websites and mobile applications. They
            show us how visitors and customers interact with our digital
            properties, whether there are any errors, the degree to which
            certain web pages, applications, services or functionality are
            accessed and used and how they are performing or operating. When
            these technologies are used for performance monitoring, no
            information that identifies you is collected.
          </p>
          <p>
            <b>User Convenience</b> – Some of these technologies enhance the
            ease of use of our websites and mobile applications and the services
            and functionality they make available by accelerating load and
            refresh times and remembering information that you have provided on
            prior visits to our websites or when you last used a website or
            mobile application service or functionality.
          </p>
          <p>
            <b>Marketing</b> – Some of these technologies are used to tailor
            your experience on our website, within our mobile applications, or
            across your applications or devices by controlling the promotions,
            advertisements and other marketing messages that may appear when you
            access or use these or other digital properties. These technologies
            also help us learn which services and functionality you are using
            and how you are accessing information about us. We may use this
            information to personalize your visit to a website or use of a
            mobile application or to send you relevant promotions and
            advertisements.
          </p>
          <h3>
            <u>Your Choices</u>
          </h3>
          <h3>Promotional Communication Choices</h3>
          <p>
            You can opt out of receiving promotional emails and mailings by
            informing us of your preference at the time you sign up for a
            Cilantro account, modifying your promotional preferences online in
            your account’s profile management section, or following the opt out
            instructions in the promotional emails we send you. Similarly, you
            may opt in to receive text messages, telephone calls and mailings.
            Where our mobile applications allow for the delivery of “push
            notifications&quot; you can also opt out of receiving these
            notifications by toggling the “Notifications&quot; switches within
            our mobile applications to &quot;off.&quot; By downloading and using
            our mobile application, you may receive promotional messages,
            offers, news and information about Cilantro or our business partners
            within the mobile application itself. These “in app&quot; messages
            are part of our mobile application’s functionality and cannot be
            turned off. If you do not want to receive “in app&quot; messages,
            offers, news and information, do not download or use our mobile
            application.
            <p>
              Please note that if you opt out of receiving promotional
              communications from us, we may still send you transactional
              communications, including emails about your accounts or purchases.
            </p>
          </p>
          <p>
            <b>Location Information</b> – You may be able to adjust the settings
            of your device so that information about your physical location is
            not sent to us or third parties by (a) disabling location services
            within the device settings; or (b) denying certain websites or
            mobile applications permission to access location information by
            changing the relevant preferences and permissions in your mobile
            device or browser settings. Please note that your location may be
            derived from your WiFi, Bluetooth, and other device settings. See
            your device settings for more information.
          </p>
          <p>
            <b>Voice Transmissions</b> – You can ensure you do not send us or
            any third parties any transmissions of your voice by not using the
            voice ordering feature. In addition, you may be able to adjust the
            settings of your device so that your voice transmissions cannot be
            sent to us or third parties by (a) disabling microphone and speech
            recognition services within the device settings; or (b) denying
            certain websites or mobile applications permission to access
            microphone and speech recognition services by changing the relevant
            preferences and permissions in your mobile device or browser
            settings.
          </p>
          <p>
            <b>Cookies, Web Beacons and Similar Technologies</b> – You may be
            able to reject cookies, web beacons, entity tags and HTML5 local
            storage by adjusting the appropriate settings in your browser. Each
            browser is different, but many common browsers (Internet Explorer,
            Chrome, Firefox, and Safari) have preferences or options that may be
            adjusted to allow you to either accept or reject cookies and certain
            other technologies before they are set or installed or allow you to
            remove or reject the use or installation of certain technologies
            altogether. If you want to learn the correct way to modify your
            browser settings, please use the Help menu in your browser.
          </p>
          <p>
            <b>Interest-Based Ads</b> – Many advertising companies that collect
            information for interest-based advertising are members of the
            Digital Advertising Alliance or the Network Advertising Initiative,
            both of which maintain websites where people can opt out of
            interest-based advertising from their members. To opt-out on these
            pages,visit <a href="www.AboutAds.info"> www.AboutAds.info</a> and
            <a href="www.networkadvertising.org"> www.networkadvertising.org</a>
            .
          </p>
          <p>
            <b>Adobe Flash Player Technology</b> – We allow Adobe to set and
            enable special cookies that are necessary to deliver video content
            for Adobe Flash Player. You cannot remove Flash cookies simply by
            changing your browser settings. If you would like to limit the
            websites that can store information in Flash cookies on your device,
            you must visit the Adobe website:
            <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">
              http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
            </a>
            .
          </p>
          <p>
            <b>&quot;Do Not Track&quot; Technology</b> – Some newer web browsers
            have a &quot;Do Not Track&quot; preference that transmits a &quot;Do
            Not Track&quot; header to the websites you visit with information
            indicating that you do not want your activity to be tracked. We
            currently do not respond to browser &quot;Do Not Track&quot;
            signals.
          </p>
          <h3>
            <u>How to Manage Your Account Information</u>
          </h3>
          <p>
            Upon request we will provide you with information about whether we
            hold any of your personal information. You may access, correct, or
            remove your personal information by visiting
            <a href="https://www.cilantrocafe.net/contact-us/">
              https://www.cilantrocafe.net/contact-us/
            </a>{" "}
            .As required by law for taxes and other record keeping purposes, we
            are unable to completely delete an account.{" "}
          </p>
          <h3>How We Protect Your Information</h3>
          <p>
            Cilantro protects your information using technical, physical, and
            administrative security measures to reduce the risk of loss, misuse,
            unauthorized access, disclosure or modification of your information.
            While we have employed security technologies and procedures to
            assist safeguarding your personal information, no system or network
            can be guaranteed to be 100% secure.
          </p>
        </article>
      </div>
    );
  }
}
