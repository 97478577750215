/**
 * @author Raghda Wessam
 * @date 2020-07-25
 * @description collapsible component for custom products.
 * @filename collapsible-item.tsx
 */
import React from "react";
import { exist as _exist } from "utilities/common";

interface CollapsibleItemProps {
  title: string;
  // selectedItem: any;
  styles?: string;
  initialOpen?: boolean;
  selectedItemPrice?: number;
  selectedItemName?: string;
}

/**
 * React component to custom product available extras.
 */
export class CollapsibleItem extends React.Component<
  CollapsibleItemProps,
  {
    isOpen: boolean;
  }
> {
  constructor(props: CollapsibleItemProps) {
    super(props);
    const isOpen = _exist(this.props.initialOpen)
      ? this.props.initialOpen
      : true;
    this.state = { isOpen };
    this.toggleItem = this.toggleItem.bind(this);
  }

  toggleItem() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render(): React.ReactNode {
    const { title, styles } = this.props;
    return (
      <div
        className={
          styles ? `collapse__container ${styles}` : "collapse__container"
        }
      >
        <div
          className={
            this.state.isOpen
              ? "collapse__header collapse__header--active"
              : "collapse__header"
          }
          tabIndex={0}
          role="button"
          onClick={() => {
            this.toggleItem();
          }}
        >
          <h3 className="title secondary-font">
            {title}{" "}
            <span className="selection">
              {" "}
              {this.props.selectedItemName
                ? ` | ${this.props.selectedItemName}`
                : ""}
            </span>
          </h3>
          {this.props.selectedItemPrice > 0 && (
            <p className="item-price">
              {this.props.selectedItemPrice}
              <span className="currency">EGP</span>
            </p>
          )}
        </div>
        <div
          className={
            this.state.isOpen
              ? "collapse__content collapse__content--open"
              : "collapse__content"
          }
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
