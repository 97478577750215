/**
 * @author Raghda Wessam
 * @date 2020-08-10
 * @description implementation of loader component
 * to be used while loading.
 * @filename loader.tsx
 */
import React from "react";

export default class Loader extends React.Component {
  render() {
    return (
      <div className="loader__wrapper">
        <div className="loader__img-wrapper">
          <svg
            className="loader__img"
            width="53"
            height="88"
            viewBox="0 0 53 88"
            fill="none"
          >
            <path
              id="loader-path"
              d="M26.8039 2.74221C24.901 7.10446 21.2878 8.70605 17.2614 10.1158C15.9915 10.5606 8.16395 12.4377 10.266 15.2366C13.2641 19.2283 17.8657 21.5983 22.4872 22.3298C27.1086 23.0609 31.7611 22.2808 36.1349 20.8843C37.3223 20.5053 38.5424 20.0495 39.3764 19.0856C40.21 18.122 40.5222 16.5042 39.7513 15.3857C39.2532 14.6628 38.432 14.2979 37.6452 14.024C32.9585 12.3933 28.0041 12.6977 23.188 13.0198C19.1046 13.2935 14.7889 13.6527 11.5729 16.2111C8.28794 18.8239 6.7322 23.3653 6.4844 27.8586C6.23657 32.3516 7.13279 36.8849 7.94082 41.3662C9.38735 49.3925 10.5658 57.4589 11.4723 65.5439C12.2877 72.8127 13.426 81.7735 21.3998 83.9127C28.4959 85.8166 36.7066 85.1915 41.4387 79.6098C43.4903 77.1898 43.7181 74.046 44.2422 70.864C46.2349 58.7653 47.1978 46.1003 46.6766 33.5733C46.5604 30.7757 46.8534 27.3376 45.4298 24.7678C44.85 23.7209 43.379 23.5675 42.6899 24.449C41.9587 25.3842 42.3018 27.9406 42.2423 29.1494C41.8229 37.6645 41.4542 46.1853 41.0865 54.7062C40.9181 58.6124 40.7276 62.5169 40.5788 66.4253C40.4003 71.114 40.6642 76.3487 35.7804 79.2618C33.9395 80.3598 31.7488 80.6037 29.5998 80.256C26.5781 79.7671 22.2891 80.1671 19.7843 77.2158C17.6124 74.6565 17.1579 71.1674 16.4231 67.9729C13.9477 57.2157 11.8724 46.1081 11.7426 35.1333C11.7164 32.8968 11.7639 30.6153 12.4701 28.5563C12.8425 27.4708 13.5387 26.3612 14.6216 26.2454C15.2938 26.1741 15.9745 26.5139 16.5312 26.9688C18.2782 28.3961 19.0141 30.8232 19.3971 33.1057C19.7801 35.3874 19.9392 37.7652 20.9578 39.8459C22.7657 43.5387 25.9113 43.6985 28.3535 41.0168C29.4975 39.7605 30.376 38.1193 30.8226 36.4027C31.2337 34.8219 30.5469 31.1419 31.9328 30.2955C32.9289 29.687 34.3124 30.1856 35.1412 31.1319C35.97 32.0775 36.3569 33.3581 36.6332 34.6027C37.7844 39.7873 36.9409 47.3149 33.071 50.788C31.3647 52.3194 28.9739 52.8239 26.6919 52.5318C23.5938 52.135 20.2106 49.6455 17.8815 46.5814C12.3837 39.3472 5.54579 50.3196 5.54579 50.3196"
              stroke="#BF5627"
              strokeWidth="1.2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
    );
  }
}
