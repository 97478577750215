import Aly1 from "static/images/stickers/Aly1.png";
import Aly2 from "static/images/stickers/Aly2.png";
import Aly3 from "static/images/stickers/Aly3.png";
import Aly4 from "static/images/stickers/Aly4.png";
import Aly5 from "static/images/stickers/Aly5.png";
import Youssef1 from "static/images/stickers/Youssef1.png";
import Youssef2 from "static/images/stickers/Youssef2.png";
import Youssef3 from "static/images/stickers/Youssef3.png";
import Youssef4 from "static/images/stickers/Youssef4.png";
import Youssef5 from "static/images/stickers/Youssef5.png";
import Farah1 from "static/images/stickers/Farah1.png";
import Farah2 from "static/images/stickers/Farah2.png";
import Farah3 from "static/images/stickers/Farah3.png";
import Farah4 from "static/images/stickers/Farah4.png";
import Farah5 from "static/images/stickers/Farah5.png";
import Rawan1 from "static/images/stickers/Rawan1.png";
import Rawan2 from "static/images/stickers/Rawan2.png";
import Rawan3 from "static/images/stickers/Rawan3.png";
import Rawan4 from "static/images/stickers/Rawan4.png";
import Rawan5 from "static/images/stickers/Rawan5.png";

export default {
  Aly1,
  Aly2,
  Aly3,
  Aly4,
  Aly5,
  Youssef1,
  Youssef2,
  Youssef3,
  Youssef4,
  Youssef5,
  Farah1,
  Farah2,
  Farah3,
  Farah4,
  Farah5,
  Rawan1,
  Rawan2,
  Rawan3,
  Rawan4,
  Rawan5
};
