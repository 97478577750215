/**
 * @author Raghda Wessam
 * @date 2020-07-05
 * @description Select field layout.
 * @filename select-field.tsx
 */
import React from "react";
import Select from "react-select";

interface SelectFieldProps {
  options: {
    value: string;
    label: string;
  }[];
  onChange: () => void;
  placeholder: string;
}

const SelectField: React.FunctionComponent<SelectFieldProps> = props => {
  return (
    <div className="select__wrapper">
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={props.options}
        placeholder={
          props.placeholder ? props.placeholder : "Select the nearest branch"
        }
        onChange={props.onChange}
      />
    </div>
  );
};

export default SelectField;
