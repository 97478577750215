/**
 * @author Raghda Wessam
 * @date 2020-07-22
 * @description Implementation of user context
 * @filename user-context.ts
 */
import * as React from "react";
import { User } from "interfaces/user";

/**
 * user context that holds under user creation.
 */
export const USER_CONTEXT: React.Context<{
  /**
   * created user from BE.
   */
  user?: User;
  /**
   * a function to update current user
   */
  updateUser: (user: User, lastLogin?: number) => void;
  /**
   * refetch current user.
   */
  refreshUser: () => Promise<User>;
  /**
   * remove user registered card
   */
  removeCard: () => Promise<User>;
  /**
   * timestamp of the last time the user tried to login.
   * should be used in resend verification/login requests.
   */
  lastLogin?: number;
  /**
   * a boolean which is true if the user is loading.
   */
  isLoading?: boolean;
}> = React.createContext({
  updateUser: null,
  removeCard: null,
  refreshUser: null
});
