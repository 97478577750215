/**
 * @author Ahmed Serag
 * @date 2019-07-15
 * @description start point of the react application that uses
 * react dom to manipulate the root div.
 * @filename index.tsx
 */
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
  BrowserRouter,
  Route,
  Switch,
  RouteComponentProps,
  Redirect
} from "react-router-dom";
import { MENU_CONTEXT } from "contexts/menu-context";
import { ORDER_CONTEXT } from "contexts/order-context";
import { USER_CONTEXT } from "contexts/user-context";
import { Branch as BranchInterface } from "interfaces/branch";
import { Category } from "interfaces/category";
import { CreateOrderItemInput } from "interfaces/inputs/create-order-item-input";
import { User as UserInterface } from "interfaces/user";
import {
  Order as OrderInterface,
  OrderType,
  PaymentMethod,
  PromocodeType
} from "interfaces/order";
import { Order as OrderUtilities } from "utilities/order";
import { User as UserUtilities } from "utilities/user";
import { Branch as BranchUtilities } from "utilities/branch";
import {
  exist,
  getParamFromSearchUrl as _getParamFromSearchUrl,
  isEmpty
} from "utilities/common";
import { Product } from "interfaces/product";
import Toastr from "toastr";
import ReactPixel from "react-facebook-pixel";
import { ROUTES } from "./definitions/consts/routes";
import { ScrollToTop } from "./react-components/common/scroll-to-top";

/**
 * state of application component.
 *
 * @interface AppState
 */
interface AppState {
  /**
   * id of the Active branch.
   */
  activeBranch?: BranchInterface;
  /**
   * a boolean which is true if loading active branch menu.
   */
  loadingBranchMenu?: boolean;
  /**
   * menu of the active branch.
   */
  menu?: Category[];
  menuActiveTab?: {
    categoryId: number;
    subCategoryId: number;
  };

  /**
   * items selected to create the order.
   */
  selectedOrderItems: Record<string, CreateOrderItemInput>;
  /**
   * under creation order that holds order details validated from the API.
   */
  order?: OrderInterface;
  /**
   * minimum order price that's eligible for a stamp.
   */
  orderStampPrice?: number;
  /**
   * comment added to the order
   */
  orderComment?: string;

  /**
   * type of the order created in terms of delivery.
   */
  orderType: OrderType;
  /**
   * type of the car the order delivered to.
   */
  pickupCarType?: string;
  /**
   * color of the car the order delivered to.
   */
  pickupCarColor?: string;
  /**
   * estimated arrival time for delivery.
   */
  pickupETA?: string;

  /**
   *order table number
   *
   * @type {string}
   * @memberof AppState
   */
  tableNumber?: string;

  /**
   * customer table number
   *
   * @type {string}
   * @memberof AppState
   */
  orderTableNumber?: string;
  /**
   * a boolean which is true if the order is loading.
   */
  loadingOrder: boolean;
  /**
   * price of selected items excluding unavailable ones.
   */
  selectedItemsPrice?: number;
  /**
   * current logged in user
   */
  user?: UserInterface;
  /**
   * timestamp of the last time the user tried to login.
   */
  lastUserLogin?: number;
  /**
   * a boolean which is true if loading user data.
   */
  loadingUser?: boolean;

  /**
   * promocode to be used to create the order
   */
  promocode?: string;

  /**
   * discount percentage applied by the promocode
   */
  promocodePercentage?: number;

  /**
   * amount reduced by the promocode
   */
  promocodeDiscountAmount?: number;

  promocodeType?: PromocodeType;

  promocodeFreeProducts?: Product[];
}

/**
 * the Start point of the project.
 *
 * @class App
 * @extends {React.Component<{}, AppState>}
 */
class App extends React.Component<unknown, AppState> {
  static storeTableNumberFromUrl() {
    const tableNumber = _getParamFromSearchUrl(
      "table_number",
      window.location.search
    )[0];

    if (tableNumber) {
      OrderUtilities.addTableNumberToLocalStorage(tableNumber);
    }
  }

  static calculateSelectedItemsPrice(
    selectedOrderItems: Record<string, CreateOrderItemInput>
  ) {
    let selectedItemsPrice = 0;

    selectedItemsPrice = Object.keys(selectedOrderItems).reduce((prev, cur) => {
      if (
        selectedOrderItems[cur].is_free_gift ||
        selectedOrderItems[cur].promocode_gift
      ) {
        return prev;
      }

      return prev + selectedOrderItems[cur].price;
    }, selectedItemsPrice);
    return selectedItemsPrice;
  }

  constructor(props: unknown) {
    super(props);
    const {
      comment,
      items,
      order,
      itemsPrice,
      tableNumber
    } = OrderUtilities.loadOrderDetailsFromLocalStorage();
    const activeBranch = BranchUtilities.getActiveBranchFromLocalStorage();
    // eslint-disable-next-line object-curly-newline
    this.state = {
      order,
      activeBranch,
      orderType: "in-store",
      loadingBranchMenu: !!activeBranch,
      orderComment: comment,
      tableNumber,
      loadingOrder: !!order,
      selectedOrderItems: items ?? {},
      selectedItemsPrice: itemsPrice ?? 0
    };
    this.updateMenu = this.updateMenu.bind(this);
    this.updateOrderItem = this.updateOrderItem.bind(this);
    this.CreateOrder = this.CreateOrder.bind(this);
    this.updateOrderItem = this.updateOrderItem.bind(this);
    this.removeOrderItem = this.removeOrderItem.bind(this);
    this.updateCurrentUser = this.updateCurrentUser.bind(this);
    this.updateOrderComment = this.updateOrderComment.bind(this);
    this.updateTableNumber = this.updateTableNumber.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.confirmOrder = this.confirmOrder.bind(this);
    this.clearOrder = this.clearOrder.bind(this);
    this.fetchCurrentUser = this.fetchCurrentUser.bind(this);
    this.removeUserCard = this.removeUserCard.bind(this);
    this.updateActiveMenuTab = this.updateActiveMenuTab.bind(this);
    this.removeOrder = this.removeOrder.bind(this);
    this.updateOrderType = this.updateOrderType.bind(this);
    this.updatePickupDetails = this.updatePickupDetails.bind(this);
    this.updateOrderPromocode = this.updateOrderPromocode.bind(this);
    this.refreshCart = this.refreshCart.bind(this);
  }

  componentDidMount() {
    this.fetchCurrentUser();
    App.storeTableNumberFromUrl();
    if (this.state.activeBranch) {
      this.updateMenu(this.state.activeBranch);
    }
    // validate current order
    if (this.state.order) {
      OrderUtilities.getOrderDetails(this.state.order.id)
        .then(order => {
          this.setState({
            order,
            loadingOrder: false
          });
        })
        .catch(error => {
          console.error(error);
          OrderUtilities.RemoveOrderDetailsFromLocalStorage();
          this.setState({
            loadingOrder: false
          });
        });
    }
    OrderUtilities.getStampPrice().then(stampPrice => {
      this.setState({
        orderStampPrice: stampPrice.price
      });
    });
  }

  updateOrderType(type: OrderType) {
    this.setState({
      orderType: type
    });
  }

  updatePickupDetails(pickupDetails: {
    pickupCarType?: string;
    pickupCarColor?: string;
    pickupETA?: string;
  }) {
    this.setState({
      ...pickupDetails
    });
  }

  /**
   * update current menu with new branch and it's new menu.
   *
   * @param activeBranch new branch to display it's menu.
   */
  updateMenu(activeBranch: BranchInterface): Promise<unknown> {
    this.setState({ loadingBranchMenu: true });
    return BranchUtilities.getBranchMenu(`${activeBranch.id}`)
      .then(menu => {
        BranchUtilities.addActiveBranchToLocalStorage(activeBranch);
        return new Promise<void>(resolve => {
          this.setState(
            {
              menu,
              activeBranch,
              loadingBranchMenu: false
            },
            () => {
              resolve();
            }
          );
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ loadingBranchMenu: false });
      });
  }

  /**
   * Create a new order and update the order context with the new
   * order or update the selected items with new availability status.
   */
  CreateOrder(): Promise<unknown> {
    this.setState({ loadingOrder: true });
    return OrderUtilities.createOrder(
      this.state.orderType,
      this.state.selectedOrderItems,
      this.state.orderComment,
      `${this.state.activeBranch?.id}`,
      this.state.tableNumber,
      {
        pickupCarColor: this.state.pickupCarColor,
        pickupCarType: this.state.pickupCarType,
        pickupETA: this.state.pickupETA
      },
      this.state.promocode
    )
      .then(order => {
        OrderUtilities.addOrderToLocalStorage(order);
        this.setState({
          order,
          loadingOrder: false
        });
        return order;
      })
      .catch(error => {
        if (error?.updatedItems) {
          this.setState({
            selectedOrderItems: error.updatedItems,
            loadingOrder: false
          });
          return null;
        }
        const sentryEvent: Sentry.Event = {
          message: "Create Order",
          environment: `${process.env.sentry_environment}`,
          contexts: {
            user: {
              token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY),
              phone: this.state.user?.phone,
              name: this.state.user?.name
            },
            order: {
              items: this.state.selectedOrderItems,
              comment: this.state.orderComment,
              type: this.state.orderType,
              pickup: {
                pickupCarColor: this.state.pickupCarColor,
                pickupCarType: this.state.pickupCarType,
                pickupETA: this.state.pickupETA
              }
            },
            error: {
              error: JSON.stringify(error)
            }
          }
        };
        Sentry.captureEvent(sentryEvent);
        this.setState({ loadingOrder: false });

        return Promise.reject(error);
      });
  }

  confirmOrder(
    paymentMethod: PaymentMethod,
    additionalInfo?: { [index: string]: unknown }
  ): Promise<{
    order: OrderInterface;
    token?: string;
  }> {
    let promise: Promise<{
      order: OrderInterface;
      token?: string;
    }> = this.state.order ? Promise.resolve(null) : Promise.reject();

    if (additionalInfo) {
      promise = promise.then(() => {
        return this.updateOrder(additionalInfo).then(order => {
          return {
            order
          };
        });
      });
    }

    promise = promise.then(() => {
      this.setState({ loadingOrder: true });
      return OrderUtilities.confirmOrder(this.state.order.id, paymentMethod)
        .then(payload => {
          if (payload?.token) {
            return payload;
          }
          OrderUtilities.addOrderToLocalStorage(payload.order);
          this.setState({
            order: payload.order,
            loadingOrder: false
          });
          return payload;
        })
        .catch(error => {
          this.setState({ loadingOrder: false });
          console.error(error);
          return Promise.reject(error);
        });
    });

    return promise;
  }

  /**
   * clear under creation order.
   */
  clearOrder(): Promise<unknown> {
    return new Promise<void>(resolve => {
      OrderUtilities.RemoveOrderDetailsFromLocalStorage();
      this.setState(
        {
          loadingOrder: false,
          selectedOrderItems: {},
          order: null,
          orderComment: "",
          tableNumber: "",
          selectedItemsPrice: 0,
          promocode: undefined,
          promocodeDiscountAmount: undefined,
          promocodePercentage: undefined
        },
        () => {
          resolve();
        }
      );
    });
  }

  /**
   * remove order from context
   *
   * @memberof App
   */
  removeOrder() {
    this.setState({ order: null });
  }

  /**
   * validates the order with the API and update the order context with the new
   * order or update the selected items with new availability status.
   */
  updateOrder(
    additionalInfo?: Record<string, unknown>
  ): Promise<OrderInterface> {
    this.setState({ loadingOrder: true });
    return OrderUtilities.updateOrder(
      this.state.orderType,
      this.state.selectedOrderItems,
      this.state.orderComment,
      this.state.order?.id,
      this.state.tableNumber,
      {
        pickupCarColor: this.state.pickupCarColor,
        pickupCarType: this.state.pickupCarType,
        pickupETA: this.state.pickupETA
      },
      additionalInfo,
      this.state.promocode
    )
      .then(order => {
        OrderUtilities.addOrderToLocalStorage(order);
        this.setState({
          order,
          loadingOrder: false
        });
        return order;
      })
      .catch(error => {
        if (error?.updatedItems) {
          this.setState({
            selectedOrderItems: error.updatedItems,
            loadingOrder: false
          });
          return null;
        }

        if (error?.promocode) {
          this.updateOrderPromocode("invalid", null, null, null, null, true);
          Toastr.options.positionClass = "toast-top-full-width";
          Toastr.options.timeOut = 10000;
          Toastr.error(error?.promocode[0] ?? "Invalide Promocode");
        }
        const sentryEvent: Sentry.Event = {
          message: "Update Order",
          environment: `${process.env.sentry_environment}`,
          contexts: {
            user: {
              token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY),
              phone: this.state.user?.phone,
              name: this.state.user?.name
            },
            order: {
              id: this.state.order?.id,
              items: this.state.selectedOrderItems,
              comment: this.state.orderComment,
              type: this.state.orderType,
              pickup: {
                pickupCarColor: this.state.pickupCarColor,
                pickupCarType: this.state.pickupCarType,
                pickupETA: this.state.pickupETA
              }
            },
            error: {
              error: JSON.stringify(error)
            }
          }
        };
        Sentry.captureEvent(sentryEvent);
        this.setState({ loadingOrder: false });

        return error;
      });
  }

  /**
   * remove an item from currently selected items.
   * @param itemId key of the item to be removed.
   */
  removeOrderItem(itemId: string): Promise<void> {
    const { selectedOrderItems } = this.state;

    delete selectedOrderItems[itemId];

    return this.refreshCart(selectedOrderItems);
  }

  /**
   * add or update currently selected items.
   * @param item item to be added or updated.
   * @param itemId key of the item.
   */
  updateOrderItem(item: CreateOrderItemInput, itemId: string): Promise<void> {
    let selectedOrderItems = this.state.selectedOrderItems;

    if (item.quantity < 1) {
      return this.removeOrderItem(itemId);
    }

    selectedOrderItems[itemId] = item;
    selectedOrderItems = OrderUtilities.populateBOGOItems(selectedOrderItems);

    return this.refreshCart(selectedOrderItems);
  }

  refreshCart(items?: Record<string, CreateOrderItemInput>): Promise<void> {
    let selectedOrderItems = items ?? this.state.selectedOrderItems;
    selectedOrderItems = OrderUtilities.populateBOGOItems(selectedOrderItems);

    const selectedItemsPrice = App.calculateSelectedItemsPrice(
      selectedOrderItems
    );

    OrderUtilities.updateOrderItemsInLocalStorage(
      selectedOrderItems,
      selectedItemsPrice
    );

    return new Promise(resolve => {
      this.setState(
        {
          selectedOrderItems,
          selectedItemsPrice
        },
        () => resolve()
      );
    });
  }

  updateOrderComment(orderComment: string) {
    OrderUtilities.addOrderCommentToLocalStorage(orderComment);
    this.setState({ orderComment });
  }

  updateTableNumber(tableNumber: string) {
    OrderUtilities.addTableNumberToLocalStorage(tableNumber);
    this.setState({ tableNumber });
  }

  /**
   * update promocode details used in the app.
   */
  updateOrderPromocode(
    promocode: string,
    promocodeDiscountAmount?: number,
    promocodePercentage?: number,
    promocodeType?: PromocodeType,
    promocodeFreeProducts?: Product[],
    removeCode = false
  ): Promise<unknown> {
    let promise = new Promise<unknown>(resolve => {
      if (removeCode) {
        this.setState(
          {
            promocode: undefined,
            promocodeDiscountAmount: undefined,
            promocodePercentage: undefined,
            promocodeType: undefined,
            promocodeFreeProducts: undefined
          },
          () => resolve(null)
        );
      } else {
        this.setState(
          {
            promocode,
            promocodeDiscountAmount,
            promocodePercentage,
            promocodeType,
            promocodeFreeProducts
          },
          () => resolve(null)
        );
      }
    });

    // Remove items with promocode
    if (!exist(promocode)) {
      const items = this.state.selectedOrderItems;
      for (const itemKey of Object.keys(items)) {
        if (items[itemKey].promocode_bogo) {
          delete items[itemKey].promocode_bogo;
        }
      }

      promise = promise.then(() => {
        return new Promise(resolve => {
          this.setState(
            {
              selectedOrderItems: items
            },
            () => resolve(null)
          );
        });
      });
    }

    // remove any promocode free items in case of invalid promo.
    promise = promise.then(() => {
      if (!isEmpty(promocodeFreeProducts)) {
        return Promise.resolve();
      }
      const PromocodeFreeGiftsKeys = Object.keys(
        this.state.selectedOrderItems
      ).filter(key => {
        return this.state.selectedOrderItems[key].promocode_gift === true;
      });

      if (!isEmpty(PromocodeFreeGiftsKeys)) {
        for (const key of PromocodeFreeGiftsKeys) {
          this.removeOrderItem(key);
        }
      }
      return Promise.resolve();
    });

    promise = promise.then(() => {
      return this.updateOrder();
    });

    promise = promise.then(() => {
      return this.refreshCart();
    });

    return promise;
  }

  fetchCurrentUser(): Promise<UserInterface> {
    this.setState({ loadingUser: true });
    return UserUtilities.getCurrentUser()
      .then(user => {
        return new Promise((resolve: (user: UserInterface) => void) => {
          this.setState(
            {
              user,
              loadingUser: false
            },
            () => resolve(user)
          );
        });
      })
      .catch(() => {
        return new Promise((resolve: (user: UserInterface) => void) => {
          Toastr.options.positionClass = "toast-top-full-width";
          Toastr.options.timeOut = 10000;
          Toastr.success(
            "First Order? Use code MyFreebie in Cairo or BeachTime in Sahel for a FREE gift!"
          );
          this.setState({ loadingUser: false }, () => resolve(null));
        });
      });
  }

  /**
   * update state with current user.
   *
   * @param {User} user
   * @memberof App
   */
  updateCurrentUser(user: UserInterface, lastLogin?: number) {
    this.setState(prevState => {
      return {
        user,
        lastUserLogin: lastLogin ?? prevState.lastUserLogin
      };
    });
  }

  removeUserCard(): Promise<UserInterface> {
    this.setState({ loadingUser: true });
    return UserUtilities.removeUserCard().then((user: UserInterface) => {
      return new Promise(resolve => {
        this.setState(
          {
            user,
            loadingUser: false
          },
          () => resolve(user)
        );
      });
    });
  }

  /**
   * update current active menu listing tab.
   */
  updateActiveMenuTab(categoryId: number, subCategoryId: number) {
    this.setState({
      menuActiveTab: {
        categoryId,
        subCategoryId
      }
    });
  }

  render(): React.ReactNode {
    const availableRoutes = Object.keys(ROUTES).reduce((prev, cur) => {
      if (
        !this.state.menu &&
        !this.state.loadingBranchMenu &&
        !ROUTES[cur].public
      ) {
        return prev;
      }

      const Component = ROUTES[cur].component;
      return [
        ...prev,
        <Route
          key={ROUTES[cur].path}
          path={ROUTES[cur].path}
          exact={ROUTES[cur].exact}
          render={(renderProps: RouteComponentProps) => (
            <Component {...renderProps} {...ROUTES[cur].props} />
          )}
        />
      ];
    }, []);

    return (
      <MENU_CONTEXT.Provider
        value={{
          branch: this.state.activeBranch,
          categories: this.state.menu,
          loadingMenu: this.state.loadingBranchMenu,
          activeCategoryId: this.state.menuActiveTab?.categoryId,
          activeSubCategoryId: this.state.menuActiveTab?.subCategoryId,
          updateMenu: this.updateMenu,
          updateActiveTab: this.updateActiveMenuTab
        }}
      >
        <ORDER_CONTEXT.Provider
          value={{
            items: this.state.selectedOrderItems,
            order: this.state.order,
            orderType: this.state.orderType,
            updateOrderType: this.updateOrderType,
            pickupCarColor: this.state.pickupCarColor,
            pickupCarType: this.state.pickupCarType,
            pickupETA: this.state.pickupETA,
            updatePickupDetails: this.updatePickupDetails,
            stampPrice: this.state.orderStampPrice,
            loadingOrder: this.state.loadingOrder,
            itemsPrice: this.state.selectedItemsPrice,
            comment: this.state.orderComment,
            tableNumber: this.state.tableNumber,
            removeItem: this.removeOrderItem,
            updateItem: this.updateOrderItem,
            createOrder: this.CreateOrder,
            updateComment: this.updateOrderComment,
            updateTableNumber: this.updateTableNumber,
            updateOrder: this.updateOrder,
            confirmOrder: this.confirmOrder,
            clearOrder: this.clearOrder,
            removeOrder: this.removeOrder,
            updateOrderPromocode: this.updateOrderPromocode,
            promocode: this.state.promocode,
            promocodeDiscountAmount: this.state.promocodeDiscountAmount,
            promocodePercentage: this.state.promocodePercentage,
            promocodeType: this.state.promocodeType,
            promocodeFreeProducts: this.state.promocodeFreeProducts
          }}
        >
          <USER_CONTEXT.Provider
            value={{
              user: this.state.user,
              isLoading: this.state.loadingUser,
              updateUser: this.updateCurrentUser,
              lastLogin: this.state.lastUserLogin,
              refreshUser: this.fetchCurrentUser,
              removeCard: this.removeUserCard
            }}
          >
            <BrowserRouter>
              <Route
                key="scroll-top"
                render={(renderProps: RouteComponentProps) => (
                  <ScrollToTop {...renderProps} />
                )}
              />

              <Switch>
                {availableRoutes}
                <Route
                  key="not-found"
                  render={() => <Redirect to={ROUTES.Home.path} />}
                />
              </Switch>
            </BrowserRouter>
          </USER_CONTEXT.Provider>
        </ORDER_CONTEXT.Provider>
      </MENU_CONTEXT.Provider>
    );
  }
}

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});
ReactPixel.init(process.env.PIXEL_ID);
ReactPixel.pageView();
/**
 * The application.
 *
 * @type {(void|Element|React.Component<*, React.ComponentState, *>)}
 */
ReactDOM.render(<App />, document.getElementById("root"));
