/**
 * @author Ahmed Serag
 * @date 2020-08-10
 * @description declaration of available gifts based on user level
 * @filename gifts.ts
 */
import cappuccino from "static/images/products/cappuccino.png";
import espresso from "static/images/products/espresso.png";
import turkishCoffee from "static/images/products/turkish-coffee.png";
import freddoCappuccino from "static/images/products/freddo-cappuccino.png";
import freddoEspresso from "static/images/products/freddo-espresso.png";
import latteFrappe from "static/images/products/latte-frappe.png";
import mochaFrappe from "static/images/products/mocha-frappe.png";
import mojito from "static/images/products/Mojito.png";
import icedCaramelMacchiatto from "static/images/products/iced_caramel_macchiatto.png";
import lemonMintSmoothie from "static/images/products/lemon_mint_smoothie.png";
import icedLatte from "static/images/products/iced_latte.png";

export type Gift = {
  id?: number;
  name: string;
  image: string;
};

export const GIFTS_LEVELS: Record<number, Gift[]> = {
  0: [
    { name: "Cappuccino", image: cappuccino },
    { name: "Espresso", image: espresso },
    { name: "Turkish Coffee", image: turkishCoffee }
  ],
  1: [
    { name: "Freddo Cappuccino", image: freddoCappuccino },
    { name: "Freddo Espresso", image: freddoEspresso },
    { name: "Latte Frappe", image: latteFrappe }
  ]
};

export const SAHEL_GIFTS_LEVELS: Record<number, Gift[]> = {
  0: [
    { name: "Latte Frappe", image: latteFrappe },
    { name: "Lemon Mint Smoothie", image: lemonMintSmoothie },
    { name: "Iced Latte", image: icedLatte }
  ],
  1: [
    { name: "Mocha Frappe", image: mochaFrappe },
    { name: "Mojito", image: mojito },
    { name: "Iced Caramel Macchiato", image: icedCaramelMacchiatto }
  ]
};
