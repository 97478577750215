/**
 * @author Ahmed Serag
 * @date 2019-07-16
 * @description implementation of endpoints in the api.
 * @filename endpoints.ts
 */

/**
 * set of endpoints in the API.
 */
// eslint-disable-next-line object-curly-newline
export const ENDPOINTS = {
  // ADD Routes Here!
  // eslint-disable-next-line object-curly-newline
  listBranches: {
    path: "/branches",
    method: "GET"
  },
  listBranchMenu: {
    path: (branchId: string) => `/branches/${branchId}/menu`,
    method: "GET"
  },
  GetProductCustomization: {
    path: (branchId: string, productId: string) =>
      `/branches/${branchId}/menu/${productId}`,
    method: "GET"
  },
  createOrder: {
    path: (branchId: string) => `/branches/${branchId}/orders`,
    method: "POST"
  },
  getOrder: {
    path: (orderUUID: string) => `/orders/${orderUUID}`,
    method: "GET"
  },
  updateOrder: {
    path: (orderUUID: string) => `/orders/${orderUUID}`,
    method: "PUT"
  },
  getOrderPaymentToken: {
    path: (orderUUID: string) => `/orders/${orderUUID}/payment`,
    method: "GET"
  },
  confirmOrder: {
    path: (orderUUID: string) => `/orders/${orderUUID}/confirm`,
    method: "PATCH"
  },
  updateComment: {
    path: (orderUUID: string) => `/orders/${orderUUID}/comment`,
    method: "PUT"
  },
  VerifyNumber: {
    path: "/users/auth/verify",
    method: "POST"
  },
  createUser: {
    path: "/users/auth/login",
    method: "POST"
  },
  currentUser: {
    path: "/users/current",
    method: "GET"
  },
  removeUserCard: {
    path: "/users/remove-card",
    method: "PATCH"
  },
  getStampPrice: {
    path: "/orders/loyalty/price",
    method: "GET"
  },
  getStampPricePerBranch: {
    path: (branchId: number) => `/orders/loyalty/price/branch/${branchId}`,
    method: "GET"
  },
  ValidatePromocode: {
    path: "/promocodes/validate",
    method: "POST"
  }
};
