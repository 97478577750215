/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * @author Raghda Wessam
 * @date 2020-07-20
 * @description Cart items Layout of the App.
 * @filename cart-items.tsx
 */
import React from "react";
import Toastr from "toastr";
import { RouteComponentProps } from "react-router-dom";
import { ROUTES } from "consts/routes";
import { MENU_CONTEXT } from "contexts/menu-context";
import { ORDER_CONTEXT } from "contexts/order-context";
import { Product as ProductUtilities } from "utilities/product";
// TODO replace with default image!
import { exist, isEmpty as _isEmpty } from "utilities/common";
import hotDrinkIcon from "static/images/products/hot-drinks.png";
import Select from "react-select";
import {
  addToCartEvent as _addToCartEvent,
  RemoveFromCartEvent as _RemoveFromCartEvent,
  CheckoutProgressEvent as _CheckoutProgressEvent
} from "utilities/gtag-events";
import SUMMER_ILLUSTRATION from "static/images/illustrations/cart-illustration-sahel.svg";
import ReactPixel from "react-facebook-pixel";
import { Order as OrderUtilities } from "utilities/order";
import { USER_CONTEXT } from "contexts/user-context";
import { CartItem } from "../../common/cart-item";
import { Promocode } from "../../common/promocode";
import { PriceItem } from "../../common/price-item";
import { Modal } from "../../common/modal";

/**
 * state used by CartItems Component.
 */
interface CartItemsState {
  estimatedArrivalOptions: string[];
  activeModal?: {
    onSubmit: () => void;
  };
  stampPrice: number;
}

/**
 * React component to render cart items.
 */
export class CartItems extends React.Component<
  RouteComponentProps,
  CartItemsState
> {
  declare context: React.ContextType<typeof ORDER_CONTEXT>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      estimatedArrivalOptions: [
        "As soon as possible",
        "5-10 mins",
        "15-20 mins"
      ]
    };
    this.checkout = this.checkout.bind(this);
    this.updateItemQuantity = this.updateItemQuantity.bind(this);
  }

  componentDidMount() {
    ReactPixel.trackCustom("CartView");
    OrderUtilities.getStampPricePerBranch(this.context.order.branch.id).then(
      stampPrice => {
        this.setState({ stampPrice: stampPrice.price });
      }
    );
  }

  /**
   * create a new order if it doesn't already exist or
   * just update the current one.
   */
  checkout() {
    let promise: Promise<unknown>;

    if (_isEmpty(this.context.items)) {
      Toastr.error("Can't place an empty order");
      return;
    }

    if (this.context.order) {
      promise = this.context.updateOrder();
    } else {
      promise = this.context.createOrder();
    }

    promise
      .then(() => {
        _CheckoutProgressEvent({
          checkout_step: 1
        });
        const itemsKeys = Object.keys(this.context.items);
        const inValidItem = itemsKeys.find(key => {
          return this.context.items[key].available === false;
        });
        ReactPixel.track("InitiateCheckout", {
          Promocode: {
            Promocode: this.context.promocode,
            PromocodeType: this.context.promocodeType,
            PromocodeDiscountAmount: this.context.order
              ?.promocode_discount_amount
          },
          campaignDiscountAmount: this.context.order?.campaign_discount_amount,
          OrderDiscountAmount: this.context.order?.discount,
          SubTotal: this.context.order?.price,
          Tax: this.context.order?.tax,
          value: this.context.order?.price,
          TotalPrice: this.context.order?.total_price
        });

        // Block the user from checkout in case of missing items.
        if (!inValidItem) {
          this.props.history.push(ROUTES.Checkout.path);
        }
      })
      .catch(error => {
        Toastr.error(error);
      });
  }

  /**
   * update item quantity in order context.
   * @param newQuantity new quantity
   * @param itemKey key of the item in the context
   */
  updateItemQuantity(newQuantity: number, itemKey: string) {
    const selectedItem = this.context.items[itemKey];
    const currentProduct = selectedItem?.product;
    let totalPrice = 0;
    let promise: Promise<void>;

    // Early return in case of just deleting the item.
    if (newQuantity === 0) {
      promise = this.context.removeItem(itemKey);
    } else {
      if (selectedItem.extras) {
        currentProduct.extras.map(extra => {
          selectedItem.extras.map(selectedExtra => {
            if (selectedExtra.id === extra.id) {
              totalPrice += extra.price * selectedExtra.quantity;
            }
            return selectedExtra;
          });
          return extra;
        });
      }

      if (selectedItem.variants) {
        currentProduct.variants.map(variant => {
          selectedItem.variants.map(selectedVariantId => {
            if (selectedVariantId === variant.id) {
              totalPrice += variant.price;
            }
            return selectedVariantId;
          });
          return variant;
        });
      }

      if (selectedItem.size) {
        currentProduct.sizes.map(size => {
          if (selectedItem.size === size.id) {
            totalPrice += size.price;
          }
          return size;
        });
      }

      if (totalPrice === 0) {
        selectedItem.price = Number(currentProduct.price) * newQuantity;
      } else {
        selectedItem.price = Number(totalPrice) * newQuantity;
      }

      if (currentProduct.campaigns?.campaign_percentage > 0) {
        selectedItem.price -=
          (selectedItem.price *
            Number(currentProduct.campaigns?.campaign_percentage)) /
          100;
      }
    }

    selectedItem.quantity = newQuantity;
    promise = this.context.updateItem(selectedItem, itemKey);

    // update Google Analytics
    if (newQuantity > selectedItem.quantity) {
      _addToCartEvent({
        currency: "EGP",
        value: `${selectedItem.price}`,
        items: [
          {
            id: `${selectedItem.id}`,
            name: currentProduct?.name,
            quantity: 1
          }
        ]
      });
    } else if (newQuantity < selectedItem.quantity) {
      _RemoveFromCartEvent({
        currency: "EGP",
        value: `${selectedItem.price}`,
        items: [
          {
            id: `${selectedItem.id}`,
            name: currentProduct?.name,
            quantity: 1
          }
        ]
      });
    }

    promise.then(() => {
      return this.context.updateOrder();
    });
  }

  render(): React.ReactNode {
    const cartItems: React.ReactElement[] = [];
    const itemsKeys = Object.keys(this.context.items);
    this.context.stampPrice = this.state.stampPrice;
    let validOrder = !_isEmpty(itemsKeys);
    // A Cart is valid if it contains at least one charged item.
    let validCart = false;
    let isSubscriptionProduct = false;
    for (const key of itemsKeys) {
      const productItem = this.context.items[key];
      const customization = ProductUtilities.getCustomizationString(
        productItem
      );
      validOrder = validOrder && productItem.available !== false;

      validCart =
        validCart || (!productItem.is_free_gift && !productItem.promocode_gift);

      if (this.context?.orderType === "pick-up") {
        validOrder =
          validOrder &&
          !_isEmpty(this.context.pickupCarColor) &&
          !_isEmpty(this.context.pickupCarType) &&
          !_isEmpty(this.context.pickupETA);
      }
      isSubscriptionProduct =
        isSubscriptionProduct ||
        this.context.items[key].product?.is_subscription_product;
      cartItems.push(
        <CartItem
          key={key}
          itemKey={key}
          customization={customization}
          isSubscriptionProduct={
            this.context.items[key].product?.is_subscription_product || false
          }
          name={this.context.items[key].product?.name}
          image={this.context.items[key].product?.image ?? hotDrinkIcon}
          price={this.context.items[key].price}
          quantity={this.context.items[key].quantity}
          available={this.context.items[key].available !== false}
          updateQuantity={quantity => {
            if (
              this.context.items[key].is_free_gift ||
              this.context.items[key].promocode_gift
            ) {
              // TODO: I know this one looks bad and it needs to be refactored!
              this.setState({
                activeModal: {
                  onSubmit: () => {
                    this.updateItemQuantity(quantity, key);
                    this.setState({
                      activeModal: null
                    });
                  }
                }
              });
              return;
            }
            this.updateItemQuantity(quantity, key);
          }}
          gift={
            this.context.items[key].is_free_gift ||
            this.context.items[key].promocode_gift
          }
        />
      );
    }

    validOrder = validOrder && validCart;

    return (
      <MENU_CONTEXT.Consumer>
        {value => {
          return (
            <div className="cart__container">
              {this.state.activeModal && (
                <Modal
                  header="You cannot redeem this gift again"
                  body="Removing this gift will delete it forever, you can no longer redeem it again"
                >
                  <div className="action-buttons">
                    <button
                      className="secondary"
                      type="button"
                      onClick={() => this.state.activeModal.onSubmit()}
                    >
                      Remove gift
                    </button>
                    <button
                      className="primary"
                      type="button"
                      onClick={() => this.setState({ activeModal: null })}
                    >
                      Keep gift
                    </button>
                  </div>
                </Modal>
              )}
              {this.context?.itemsPrice &&
              this.context?.itemsPrice < this.context.stampPrice ? (
                <div className="cart__banner">
                  <div className="banner-text">
                    <h2>
                      {`${Number(
                        this.context.stampPrice - this.context.itemsPrice
                      ).toFixed(2)} EGP more to get a stamp`}
                    </h2>
                    <p>Collect 4 stamps and get a special gift!</p>
                  </div>
                  <button
                    className="button secondary-font"
                    type="button"
                    onClick={() => {
                      this.props.history.push(
                        ROUTES.BranchMenu.getPath(`${value?.branch?.id}`)
                      );
                    }}
                  >
                    Back to menu
                  </button>
                </div>
              ) : null}
              {this.context?.itemsPrice &&
              this.context?.itemsPrice >= this.context.stampPrice ? (
                <div className="cart__banner cart__banner--stamp">
                  <div className="banner-text">
                    <p>Placing this order will get you a stamp!</p>
                  </div>
                </div>
              ) : null}

              <USER_CONTEXT.Consumer>
                {con => {
                  return (
                    <>
                      {exist(con.user?.subscriptions) &&
                        con.user?.subscriptions?.length > 0 &&
                        this.context.order?.subscription_discount <= 0 && (
                          <div className="cart__banner">
                            <div className="banner-text">
                              <p>
                                You are placing an order that is not covered by
                                your subscription plan!
                              </p>
                            </div>
                          </div>
                        )}
                    </>
                  );
                }}
              </USER_CONTEXT.Consumer>

              <div
                className={
                  this.context?.itemsPrice &&
                  this.context?.itemsPrice < this.context.stampPrice
                    ? "cart__header cart__header--banner"
                    : this.context?.itemsPrice &&
                      this.context?.itemsPrice >= this.context.stampPrice
                    ? "cart__header cart__header--stamp"
                    : "cart__header"
                }
              >
                <button
                  className="button secondary-font"
                  type="button"
                  onClick={() => {
                    this.props.history.push(
                      ROUTES.BranchMenu.getPath(`${value.branch.id}`)
                    );
                  }}
                >
                  Back
                </button>
                <div className="main-title--wrapper">
                  <h1 className="main-title primary-font">Your Cart</h1>
                  <img
                    className="main-title--illustration"
                    src={SUMMER_ILLUSTRATION}
                    alt=""
                  />
                </div>
                <div className="cart__location">
                  <h2 className="title">Branch for Pickup</h2>
                  <p className="location">{value.branch.name}</p>
                </div>
              </div>

              <div className="cart__items">
                {value.branch?.discount > 0 && (
                  <div className="discount">
                    <p>Enjoy {value.branch.discount}% off your order!</p>
                  </div>
                )}
                <div className="cart__items-header">
                  <h2 className="title primary-font">Your order</h2>
                  <p className="items-number">
                    {Object.keys(this.context.items).reduce(
                      (sum, key) => sum + this.context.items[key].quantity,
                      0
                    )}{" "}
                    items
                  </p>
                </div>
                <ul className="cart__items-list general__list">{cartItems}</ul>
                {value.branch.is_curbside === 1 && (
                  <div className="cart__checkbox secondary-font">
                    <input
                      id="pick-up-delivery"
                      type="checkbox"
                      checked={this.context?.orderType === "pick-up"}
                      onChange={e => {
                        this.context.updateOrderType(
                          e.currentTarget.checked ? "pick-up" : "in-store"
                        );
                      }}
                    />
                    <label htmlFor="pick-up-delivery">
                      I want this order delivered to my car
                    </label>
                  </div>
                )}
                {this.context?.orderType === "pick-up" && (
                  <div className="cart__order-type__pick-up">
                    <div className="cart__pickup-details">
                      <div className="header">
                        <h2 className="title primary-font">
                          Car pickup details
                        </h2>
                        <p className="items-number">
                          {Object.keys(this.context.items).reduce(
                            (sum, key) =>
                              sum + this.context.items[key].quantity,
                            0
                          )}{" "}
                          items
                        </p>
                      </div>
                      <label htmlFor="car-type" className="secondary-font">
                        Car Type
                      </label>
                      <input
                        id="car-type"
                        name="car-type"
                        className="pickup-input"
                        placeholder="Car Type"
                        value={this.context.pickupCarType ?? ""}
                        onChange={e => {
                          this.context.updatePickupDetails({
                            pickupCarType: e.currentTarget.value
                          });
                        }}
                      />
                      <label htmlFor="car-color" className="secondary-font">
                        Car Color
                      </label>
                      <input
                        id="car-color"
                        name="car-color"
                        className="pickup-input"
                        placeholder="Car Color"
                        value={this.context.pickupCarColor ?? ""}
                        onChange={e => {
                          this.context.updatePickupDetails({
                            pickupCarColor: e.currentTarget.value
                          });
                        }}
                      />
                      <div className="select__wrapper">
                        <label htmlFor="car-color" className="secondary-font">
                          Estimated Arrival time
                        </label>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select-time react-select"
                          placeholder={
                            this.context.pickupETA ??
                            "Select Estimated arrival time"
                          }
                          options={this.state.estimatedArrivalOptions.map(
                            time => {
                              return {
                                value: time,
                                label: `${time}`
                              };
                            }
                          )}
                          onChange={arrival => {
                            this.context.updatePickupDetails({
                              pickupETA: (arrival as any).value
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="cart__comments">
                  <h3 className="title">Comments / Special Requests</h3>
                  <textarea
                    className="comment-input"
                    id="cart-comments"
                    name="cart-comments"
                    placeholder="Type here"
                    // cols={30}
                    // rows={3}
                    value={this.context.comment ?? ""}
                    onChange={e => {
                      this.context.updateComment(e.target.value);
                    }}
                  />
                </div>
                {!isSubscriptionProduct &&
                  !this.context.order?.has_subscription_item && (
                    <Promocode {...this.props} />
                  )}
                <PriceItem
                  subTotal={this.context.order?.price}
                  subscriptionDiscount={
                    this.context.order?.subscription_discount
                  }
                  tax={this.context.order?.tax}
                  total={this.context.order?.total_price}
                  discount={this.context.order?.discount}
                  discount_percentage={this.context.order?.discount_percentage}
                  promocode={this.context.order?.promocode}
                  promocodeDiscountAmount={
                    this.context.order?.promocode_discount_amount
                  }
                  specialDeliveryAmount={
                    this.context.order.special_delivery_price
                  }
                  campaignDiscountAmount={
                    this.context.order?.campaign_discount_amount
                  }
                  promocodeType={this.context.promocodeType}
                  onRemovePromoCode={() => {
                    this.context.updateOrderPromocode(
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined
                    );
                  }}
                />
              </div>
              <div className="button__wrapper">
                <button
                  type="button"
                  className={`button--primary button--fixed primary-font ${
                    this.context.loadingOrder ? "button--loading " : ""
                  }${!validOrder ? "button--disabled" : ""}`}
                  onClick={this.checkout}
                  disabled={!validOrder}
                >
                  {!this.context.loadingOrder && (
                    <div className="button-content">
                      Checkout
                      <div className="total-price">
                        <span className="price-title secondary-font">
                          Total:
                        </span>
                        <span className="item-price secondary-font">
                          {exist(this.context.order?.total_price)
                            ? Number(this.context.order?.total_price).toFixed(2)
                            : Number(this.context.itemsPrice).toFixed(2)}
                          <span className="currency secondary-font">EGP</span>
                        </span>
                      </div>
                    </div>
                  )}
                </button>
              </div>
            </div>
          );
        }}
      </MENU_CONTEXT.Consumer>
    );
  }
}
CartItems.contextType = ORDER_CONTEXT;
