/**
 * @author Ahmed Serag
 * @date 2019-07-15
 * @description declaration of available routes in the app.
 * @filename routes.ts
 */
import { SelectBranch } from "layouts/select-branch";
import { BranchMenuCategories } from "layouts/branch-menu-categories";
import { BranchMenu } from "layouts/branch-menu";
import { CustomProduct } from "layouts/custom-product";
import { CartItems } from "layouts/cart-items";
import { Checkout } from "layouts/checkout";
import { PhoneVerification } from "layouts/phone-verification";
import { ThankYou } from "layouts/thank-you";
import { FreeGift } from "layouts/free-gift";
import { TermsAndConditions } from "layouts/terms-and-conditions";
import { Confirmation } from "layouts/confirmation";
import { Error } from "layouts/error";
import { Payment } from "layouts/payment";
import { LiveLocation } from "layouts/live-location";
import { Invitations } from "layouts/invitations";

/**
 * map of available routes in the app to their locations in the url.
 */
export const ROUTES = {
  // Add Available Routes with it's Components and other details

  Home: {
    // eslint-disable-next-line object-curly-newline
    props: {
      // Add Special Props to the component if needed
      // eslint-disable-next-line object-curly-newline
    },
    path: "/",
    component: SelectBranch,
    exact: true,
    public: true
  },
  TermsAndConditions: {
    path: "/terms",
    component: TermsAndConditions,
    exact: true,
    public: true
  },
  SelectBranch: {
    path: "/select-branch",
    component: SelectBranch,
    exact: false,
    public: true
  },
  BranchMenuCategories: {
    path: "/branches/:branchId/categories",
    getPath: (branchId: string) => `/branches/${branchId}/categories`,
    component: BranchMenuCategories,
    exact: false,
    public: false
  },
  BranchMenu: {
    path: "/branches/:branchId/menu",
    getPath: (branchId: string) => `/branches/${branchId}/menu`,
    component: BranchMenu,
    exact: false,
    public: false
  },
  ProductDetails: {
    path: "/branches/:branchId/product/:productId",
    getPath: (branchId: string, productId: string) =>
      `/branches/${branchId}/product/${productId}`,
    component: CustomProduct,
    exact: false,
    public: false
  },
  branchInvitations: {
    path: "/branches/:branchId/invitations",
    component: Invitations,
    public: true,
    exact: false
  },
  CartItems: {
    path: "/cart",
    component: CartItems,
    exact: false,
    public: false
  },
  Checkout: {
    path: "/checkout",
    component: Checkout,
    exact: false,
    public: false
  },
  Payment: {
    path: "/payment",
    component: Payment,
    exact: false,
    public: false
  },
  PhoneVerification: {
    path: "/verify-phone",
    component: PhoneVerification,
    exact: false,
    public: false
  },
  ThankYou: {
    path: "/thank-you",
    component: ThankYou,
    exact: false,
    public: true
  },
  FreeGift: {
    path: "/free-gift",
    component: FreeGift,
    exact: false,
    public: false
  },
  Confirmation: {
    path: "/confirmation/:orderId",
    getPath: (orderId: string) => `/confirmation/${orderId}`,
    component: Confirmation,
    exact: false,
    public: true
  },
  LiveLocation: {
    path: "/live-location",
    component: LiveLocation,
    public: true,
    exact: false
  },
  error: {
    path: "/error",
    component: Error,
    public: true,
    exact: false
  }
};
