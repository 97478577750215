/**
 * @author Raghda Wessam
 * @date 2020-07-06
 * @description Product Card layout.
 * @filename product-card.tsx
 */
import React from "react";

interface ProductCardProps {
  image: string;
  name: string;
  description?: string;
  background?: string;
  onClick: () => void;
  style?: string;
  custom?: boolean;
  campaign_discount?: number;
  bogo?: boolean;
}
export class ProductCard extends React.Component<ProductCardProps> {
  constructor(props: ProductCardProps) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const colorMapping: Record<string, string> = {
      "#852E2C": "#FFF6DF",
      "#FFBE9F": "#852E2C",
      "#BF5627": "#FFF6DF",
      "#6BC4E8": "#FFF6DF",
      "#FFCD00": "#852E2C",
      "#6ABF4B": "#FFF6DF"
    };
    const fontColor = colorMapping[`${this.props.background}`]
      ? `${colorMapping[`${this.props.background}`]}`
      : "#FFF6DF";

    return (
      <li
        style={
          this.props.background
            ? {
                backgroundColor: `${this.props.background}`,
                color: fontColor
              }
            : {
                backgroundColor: "#ffbe9f"
              }
        }
        className={
          this.props.style
            ? `product__wrapper ${this.props.style}`
            : "product__wrapper"
        }
        onClick={this.props.onClick}
      >
        <img
          className="product__img"
          src={this.props.image}
          alt={this.props.name}
        />
        <div className="campaign">
          {this.props.campaign_discount && (
            <span className="discount">- {this.props.campaign_discount} %</span>
          )}
          {this.props.bogo && <span className="bogo">Buy 1 get 1</span>}
        </div>
        <h3 className="product__title item-title primary-font">
          {this.props.name}
        </h3>
        {this.props.description && (
          <p className="product__description subtitle secondary-font">
            {this.props.custom && this.props.description?.length > 45
              ? `${this.props.description.substring(0, 45)}...`
              : this.props?.description}
          </p>
        )}
        {this.props.children}
      </li>
    );
  }
}
