/**
 * @author Ahmed Serag
 * @date 2020-07-05
 * @description Implementation of Branches related utilities.
 * @filename branch.ts
 */
import { Branch as BranchAPI } from "api/branch";
import { Branch as BranchInterface } from "interfaces/branch";
import { Category, SubCategory } from "interfaces/category";
import {
  getPayloadData as _getPayloadData,
  handleError as _handleError,
  isEmpty
} from "./common";

/**
 * group of Branches helpers functionalities.
 */
export class Branch {
  /**
   * list all available branches.
   */
  public static listBranches(): Promise<BranchInterface[]> {
    return BranchAPI.listBranches()
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * get list of categories available in a specific branch
   *
   * @param branchId id of the branch to list it's menu
   */
  public static getBranchMenu(branchId: string): Promise<Category[]> {
    return BranchAPI.getBranchMenu(branchId)
      .then(result => {
        return _getPayloadData(result).then(menu => {
          const availableMenuCategories: Category[] = [];
          for (const category of menu) {
            if (!isEmpty(category?.subcategories)) {
              const availableSubCategories: SubCategory[] = [];
              for (const subCategory of category?.subcategories) {
                if (!isEmpty(subCategory?.products)) {
                  availableSubCategories.push(subCategory);
                }
              }
              if (!isEmpty(availableSubCategories)) {
                availableMenuCategories.push({
                  ...category,
                  subcategories: availableSubCategories
                });
              }
            }
          }

          return availableMenuCategories;
        });
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * store active branch details in local-storage.
   * @param branch branch to be added to local-storage.
   */
  public static addActiveBranchToLocalStorage(branch: BranchInterface) {
    localStorage.setItem("branch", JSON.stringify(branch));
  }

  /**
   * get active branch details from local-storage.
   */
  public static getActiveBranchFromLocalStorage(): BranchInterface {
    const branchString = localStorage.getItem("branch");
    return branchString ? JSON.parse(branchString) : null;
  }

  /**
   * remove active branch details from local- storage.
   */
  public static removeBranchFromLocalStorage() {
    localStorage.removeItem("branch");
    localStorage.removeItem("order");
  }
}
