/**
 * @author Ahmed Serag
 * @date 2020-07-07
 * @description Implementation of Product related utilities.
 * @filename product.ts
 */
import { Product as ProductAPI } from "api/product";
import {
  ProductCustomization,
  Product as ProductInstance
} from "interfaces/product";
import { CreateOrderItemInput } from "interfaces/inputs/create-order-item-input";
import {
  SubCategory as SubCategoryInterface,
  Category as CategoryInterface
} from "interfaces/category";
import {
  getPayloadData as _getPayloadData,
  handleError as _handleError
} from "./common";

/**
 * group of Product helpers functionalities.
 */
export class Product {
  /**
   * get list of product available customizations in a specific branch
   *
   */
  public static getProductCustomizations(
    branchId: string,
    productId: string
  ): Promise<ProductCustomization> {
    return ProductAPI.getProductCustomizations(branchId, productId)
      .then(result => {
        return _getPayloadData(result);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * build a customization description string from create order Item input.
   *
   * @param productItem Item that needs to extract customization from.
   */
  public static getCustomizationString(
    productItem: CreateOrderItemInput
  ): string {
    let customization = "";

    if (productItem.size) {
      customization = productItem.product.sizes.find(
        s => s.id === productItem.size
      ).name;
    }

    if (productItem.extras) {
      for (const extra of productItem.extras) {
        customization = `${customization ? `${customization}, ` : ""} ${
          extra.quantity
        }X ${productItem.product.extras.find(e => e.id === extra.id).name}`;
      }
    }

    if (productItem.variants) {
      for (const variant of productItem.variants) {
        customization = `${customization ? `${customization}, ` : ""}${
          productItem.product.variants.find(v => v.id === variant).name
        }`;
      }
    }

    return customization;
  }

  /**
   * get the subcategory of the product
   *
   * @param menu array of categories to find the product in
   * @param product product to look for
   * @returns product subCategory
   */
  public static getProductSubCategory(
    menu: CategoryInterface[],
    product: ProductInstance
  ): SubCategoryInterface {
    let subCategory: SubCategoryInterface;

    for (const category of menu) {
      subCategory = category.subcategories.find(sub => {
        return sub.products.findIndex(p => p.id === product.id) >= 0;
      });
      if (subCategory) {
        break;
      }
    }

    return subCategory;
  }
}
