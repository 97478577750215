/**
 * @author Ahmed Serag
 * @date 2021-07-28
 * @description Invitations layout.
 * @filename invitations.tsx
 */
import { ROUTES } from "consts/routes";
import { MENU_CONTEXT } from "contexts/menu-context";
import { USER_CONTEXT } from "contexts/user-context";
import { Branch } from "interfaces/branch";
import { User as UserInterface } from "interfaces/user";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Branch as BranchUtilities } from "utilities/branch";
import { getParamFromSearchUrl, isEmpty } from "utilities/common";
import Loader from "../../common/loader";

interface InvitationsState {
  loading: boolean;
  invitationBranch?: Branch;
}

/**
 * React Component that renders Invitations Layout.
 * @extends {React.Component}
 */
export class Invitations extends React.Component<
  RouteComponentProps<{ branchId: string }>,
  InvitationsState
> {
  declare context: React.ContextType<typeof USER_CONTEXT>;

  constructor(props: RouteComponentProps<{ branchId: string }>) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    const name = getParamFromSearchUrl("name", this.props.location.search);
    const phone = getParamFromSearchUrl("phone", this.props.location.search);
    const email = getParamFromSearchUrl("email", this.props.location.search);
    const branchId = this.props.match.params.branchId;
    let user: UserInterface = null;

    if (
      isEmpty(name) ||
      isEmpty(phone) ||
      isEmpty(email) ||
      isEmpty(branchId)
    ) {
      this.props.history.push(ROUTES.SelectBranch.path);
      return;
    }

    user = {
      name: name[0],
      phone: phone[0],
      email: email[0],
      id: null
    };

    this.context.updateUser(user);

    const promise = BranchUtilities.listBranches().then(branches => {
      const invitationBranch = branches.find(
        branch => Number(branch.id) === Number(branchId)
      );

      if (!invitationBranch) {
        return Promise.reject();
      }

      return new Promise(resolve => {
        this.setState(
          {
            invitationBranch,
            loading: false
          },
          () => resolve(null)
        );
      });
    });

    promise.catch(() => {
      this.props.history.push(ROUTES.SelectBranch.path);
    });
  }

  onGotoMenuClick(updateMenu: (branch: Branch) => Promise<unknown>) {
    const branchId = this.props.match.params.branchId;

    updateMenu(this.state.invitationBranch).then(() => {
      this.props.history.push(ROUTES.BranchMenuCategories.getPath(branchId));
    });
  }

  render(): React.ReactNode {
    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <div className="invitations">
        <div className="invitations__img invitations__img--top" />
        <div className="invitations__content">
          <h2 className="invitations__sub-title">
            Welcome {this.context?.user?.name}!
          </h2>
          <h1 className="invitations__main-title main-title primary-font">
            Congratulations, <br /> You Got a free Gift. <br /> Use promocode:{" "}
            <span>
              {this.state.invitationBranch?.invitation_promocode?.code}
            </span>
          </h1>
          <MENU_CONTEXT.Consumer>
            {menu => (
              <button
                type="button"
                className="invitations__button button--primary primary-font"
                onClick={() => {
                  this.onGotoMenuClick(menu.updateMenu);
                }}
              >
                Go to Food and Drink menu
              </button>
            )}
          </MENU_CONTEXT.Consumer>
        </div>
        <div className="invitations__img invitations__img--bottom" />
      </div>
    );
  }
}

Invitations.contextType = USER_CONTEXT;
