/**
 * @author Raghda Wessam
 * @date 2020-07-23
 * @description implementation of user related API calls.
 * @filename user.ts
 */
import { Payload } from "interfaces/payload";
import { PhoneVerificationInput } from "interfaces/inputs/phone-verification-input";
import { UserInput } from "interfaces/inputs/user-input";
import { User as UserInterface } from "interfaces/user";
import { exist as _exist } from "utilities/common";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

/**
 * implement user related calls.
 */
export class User {
  /**
   * call create/login endpoint to create a new user.
   *
   * @param input new user info.
   */
  public static createUser(input: UserInput): Promise<Payload<UserInterface>> {
    const promise = Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.createUser.path}`,
      {
        method: ENDPOINTS.createUser.method,
        body: JSON.stringify(input)
      }
    );

    return promise;
  }

  /**
   * Fetch current loggedIn user details
   *
   */
  public static getCurrentUser(): Promise<Payload<UserInterface>> {
    const userToken = localStorage.getItem(process.env.ACCESS_TOKEN_KEY);
    let promise: Promise<Payload<UserInterface>> = userToken
      ? Promise.resolve(null)
      : Promise.reject();

    promise = promise.then(() => {
      return Network.fetch(
        `${process.env.API_ENDPOINT}${ENDPOINTS.currentUser.path}`,
        { method: ENDPOINTS.currentUser.method }
      );
    });

    return promise;
  }

  public static removeUserCard(): Promise<Payload<UserInterface>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.removeUserCard.path}`,
      {
        method: ENDPOINTS.removeUserCard.method
      }
    );
  }

  /**
   * call Phone Verification endpoint and set user authentication keys in local storage.
   *
   * @param input verification credentials input.
   */
  public static verifyUser(
    input: PhoneVerificationInput
  ): Promise<Payload<UserInterface>> {
    let promise = Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.VerifyNumber.path}`,
      {
        method: ENDPOINTS.VerifyNumber.method,
        body: JSON.stringify(input)
      }
    );

    promise = promise.then(payload => {
      if (_exist(payload?.data, ["access_token"])) {
        localStorage.setItem(
          process.env.ACCESS_TOKEN_KEY,
          payload.data.access_token
        );
      }

      return payload;
    });

    return promise;
  }
}
