/**
 * @author Ahmed Serag
 * @date 2020-07-04
 * @description implementation of Branches related API calls.
 * @filename branch.ts
 */

import { Payload } from "interfaces/payload";
import { Branch as BranchInterface } from "interfaces/branch";
import { Category } from "interfaces/category";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

/**
 * implement network related calls.
 */
export class Branch {
  /**
   *  list available branches from API.
   *
   * @static
   * @returns {Promise<Payload<Branch>>} Payload of available branches in the API.
   */
  public static listBranches(): Promise<Payload<BranchInterface[]>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.listBranches.path}`,
      { method: ENDPOINTS.listBranches.method }
    );
  }

  /**
   *  get branch menu with it's products.
   *
   * @static
   * @returns {Promise<Payload<Branch>>} Payload of available products in the menu
   */
  public static getBranchMenu(branchId: string): Promise<Payload<Category[]>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.listBranchMenu.path(branchId)}`,
      { method: ENDPOINTS.listBranchMenu.method }
    );
  }
}
