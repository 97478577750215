/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * @author Ahmed Elshantaly
 * @date 2020-08-05
 * @description Confirmation Page
 * @filename confirmation.tsx
 */
import React from "react";
import { format } from "date-fns";
import { exist, isEmpty } from "utilities/common";
import { RouteComponentProps } from "react-router-dom";
import { ORDER_CONTEXT } from "contexts/order-context";
import { MENU_CONTEXT } from "contexts/menu-context";
import { ROUTES } from "consts/routes";
import {
  Order as OrderInterface,
  OrderItem,
  OrderStatus,
  PaymentMethod
} from "interfaces/order";
import { Order as OrderUtilities } from "utilities/order";
// TODO replace with default image!
import hotDrinkIcon from "static/images/products/hot-drinks.png";
import { USER_CONTEXT } from "contexts/user-context";

import { pageViewEvent } from "utilities/gtag-events";
import { Maps } from "utilities/maps";
import { LoyaltyStampCard } from "../../common/loyalty-stamp-card";
import { CollapsibleItem } from "../../common/collapsible-item";
import { CartItem } from "../../common/cart-item";
import Loader from "../../common/loader";
import { OrderTrackingCard } from "../../common/order-tracking-card";

export interface ConfirmationState {
  order: OrderInterface;
  isLoadingOrder: boolean;
  locationAddress?: string;
  openLocationDropDown?: boolean;
}

export class Confirmation extends React.Component<
  RouteComponentProps,
  ConfirmationState
> {
  declare context: React.ContextType<typeof ORDER_CONTEXT>;

  mapRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      isLoadingOrder: true,
      order: null
    };
  }

  componentDidMount() {
    const orderId = this.props.match.params["orderId"];
    if (!orderId) {
      this.props.history.push(ROUTES.SelectBranch.path);
      return;
    }
    pageViewEvent(
      undefined,
      this.props.location.pathname,
      `tracking-order-${orderId}`
    );

    const promise = OrderUtilities.getOrderDetails(orderId)
      .then(order => {
        this.setState({
          order,
          isLoadingOrder: false
        });
      })
      .catch(() => {
        this.props.history.push(ROUTES.error.path);
      });

    promise.then(() => {
      // TODO: REFACTOR THIS CODE SO IT CAN BE MORE UNDERSTANDABLE
      if (
        exist(this.state.order?.additional_info?.location, [
          "latitude",
          "longitude"
        ])
      ) {
        let p: Promise<unknown> = Maps.load();

        p = p.then(() => {
          return Maps.getAddressFromLatLng(
            this.state.order?.additional_info?.location.latitude,
            this.state.order?.additional_info?.location.longitude
          ).then(result => {
            return new Promise<void>(resolve => {
              this.setState(
                {
                  locationAddress: result.formatted_address
                },
                () => resolve()
              );
            });
          });
        });

        p.then(() => {
          if (this.state.locationAddress) {
            Maps.init(this.mapRef, {
              center: {
                lat: this.state.order?.additional_info?.location.latitude,
                lng: this.state.order?.additional_info?.location.longitude
              }
            }).then(map => {
              Maps.addMarkerToPosition(map, {
                lat: this.state.order?.additional_info?.location.latitude,
                lng: this.state.order?.additional_info?.location.longitude
              });
            });
          }
        });
      }
    });
  }

  render(): React.ReactNode {
    if (this.state.isLoadingOrder) {
      return <Loader />;
    }

    const cartItems: React.ReactElement[] = [];
    const itemsKeys = Object.keys(this.state.order.items);
    let orderTrackingSteps: {
      label: string;
      completed?: boolean;
    }[] = [];
    let additionalInfo: string;

    for (const key of itemsKeys) {
      const productItem: OrderItem = this.state.order.items[key];
      let customization = "";

      if (productItem.size) {
        customization = productItem.size.name;
      }

      if (productItem.extras) {
        for (const extra of productItem.extras) {
          customization = `${customization ? `${customization}, ` : ""}${
            extra.quantity
          }X ${extra.name}`;
        }
      }

      if (productItem.variants) {
        for (const variant of productItem.variants) {
          customization = `${customization ? `${customization}, ` : ""}${
            variant.name
          }`;
        }
      }

      cartItems.push(
        <CartItem
          key={key}
          itemKey={key}
          customization={customization}
          name={this.state.order.items[key].product?.name}
          image={this.state.order.items[key].product?.image ?? hotDrinkIcon}
          price={this.state.order.items[key].price}
          quantity={this.state.order.items[key].quantity}
          gift={this.state.order.items[key]?.is_free_gift}
          available
        />
      );
    }

    if (this.state.order?.additional_info) {
      // Confirmed, Prepping, On the Way, Delivered
      orderTrackingSteps = [
        {
          label: "Received",
          completed: false
        },
        {
          label: "Prepping",
          completed: false
        },
        {
          label: "On the Way",
          completed: false
        },
        {
          label: "Delivered",
          completed: false
        }
      ];

      if (exist(this.state.order?.additional_info?.location)) {
        additionalInfo = this.state.locationAddress;
      } else {
        additionalInfo = Object.keys(this.state.order?.additional_info).reduce(
          (prev, cur, index, arr) => {
            if (
              !exist(this.state.order?.additional_info[cur]) ||
              typeof this.state.order?.additional_info[cur] === "object"
            ) {
              return prev;
            }

            return `${prev}${cur} ${this.state.order?.additional_info[cur]}${
              index !== arr.length - 1 ? " | " : ""
            }`;
          },
          ""
        );
      }
    } else {
      // Confirmed, Prepping, Ready, Picked
      orderTrackingSteps = [
        {
          label: "Received",
          completed: false
        },
        {
          label: "Prepping",
          completed: false
        },
        {
          label: "Ready",
          completed: false
        },
        {
          label: "Picked",
          completed: false
        }
      ];
    }
    switch (this.state.order?.state) {
      case OrderStatus.received:
        orderTrackingSteps[0].completed = true;
        break;
      case OrderStatus.confirmed:
        orderTrackingSteps[0].completed = true;
        orderTrackingSteps[1].completed = true;
        break;
      case OrderStatus.prepared:
        orderTrackingSteps[0].completed = true;
        orderTrackingSteps[1].completed = true;
        orderTrackingSteps[2].completed = true;
        break;
      case OrderStatus.picked:
        orderTrackingSteps[0].completed = true;
        orderTrackingSteps[1].completed = true;
        orderTrackingSteps[2].completed = true;
        orderTrackingSteps[3].completed = true;
        break;
      default:
        break;
    }

    return (
      <div className="confirmation__container">
        <div className="confirmation__header">
          <h1 className="main-title primary-font">Track Order</h1>
          <div className="confirmation__order">
            <h2 className="confirmation__order-number">
              Order no. {this.state.order?.serial}
            </h2>
            <p className="confirmation__order-time">
              Placed {format(new Date(this.state.order?.created_at), "hh:mm a")}
            </p>
          </div>
        </div>
        <OrderTrackingCard steps={orderTrackingSteps} />
        <div className="confirmation__info">
          {this.state.order?.additional_info ? (
            <div className="confirmation__info-location special">
              <h2 className="confirmation__info-location-title">
                {this.state.order.branch?.special_delivery_title ??
                  "Special Delivery"}
              </h2>
              {additionalInfo && (
                <p className="confirmation__info-location-location">
                  {additionalInfo}
                </p>
              )}
              {this.state.locationAddress && (
                <div className="location-dropdown">
                  <span
                    className={`dropdown-title${
                      this.state.openLocationDropDown ? " open" : ""
                    }`}
                    onClick={() => {
                      this.setState({
                        openLocationDropDown: !this.state.openLocationDropDown
                      });
                    }}
                  >
                    See Location
                  </span>
                  <div
                    className={`map${
                      this.state.openLocationDropDown ? " open" : ""
                    }`}
                    ref={this.mapRef}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="confirmation__info-location">
              <h2 className="confirmation__info-location-title">
                Branch for Pickup
              </h2>
              <p className="confirmation__info-location-location">
                {this.state.order?.branch?.name}
              </p>
            </div>
          )}
          <div className="confirmation__info-payment">
            <h2 className="confirmation__info-payment-title">Payment Method</h2>
            <p className="confirmation__info-payment-status">
              {this.state.order.payment_method === PaymentMethod.cash
                ? "Cash"
                : "Card payment"}
            </p>
          </div>
        </div>
        {!isEmpty(this.state.order?.branch?.phone_number) && (
          <div className="confirmation__info-phone">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
              className="phone-icon"
            >
              <g fill="none" fillRule="evenodd">
                <rect
                  width="414"
                  height="945"
                  x="-33"
                  y="-257"
                  fill="#fff6df"
                  rx="25"
                />
                <path
                  stroke="#852e2c"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.2"
                  d="M24.091 25.92c-7.404-3.21-12.707-6.495-15.91-9.856-4.806-5.04-6.75-10.183-5.778-12.09S5.283-.354 8.18 2.153c2.898 2.506 3.886 4.5 1.943 5.751-1.943 1.251.979 6.712 4.359 7.506s3.009-1.52 4.1-1.52c1.091 0 6.252 3.681 6.252 4.721s-.942 2.559-2.827 3.751c-1.256.795-4.364 1.476-9.323 2.043"
                />
              </g>
            </svg>
            <div>
              <h2 className="confirmation__info-phone-title">
                Call branch
                <a
                  className="phone-number"
                  href={`tel:${this.state.order?.branch?.phone_number}`}
                >
                  {this.state.order?.branch?.phone_number}
                </a>
              </h2>
              <p className="confirmation__info-phone-info">
                To make changes or cancel order
              </p>
            </div>
          </div>
        )}
        <USER_CONTEXT.Consumer>
          {value => {
            return (
              <>
                {value.user && (
                  <div className="confirmation__loyalty">
                    <LoyaltyStampCard
                      light
                      loyaltyProgress={
                        this.state.order?.price >= this.context.stampPrice
                          ? value.user?.loyalty_progress + 1 >= 5 &&
                            this.state.order?.state !== OrderStatus.picked &&
                            this.state.order?.state !== OrderStatus.canceled
                            ? 1
                            : this.state.order?.state !== OrderStatus.picked &&
                              this.state.order?.state !== OrderStatus.canceled
                            ? value.user.loyalty_progress + 1
                            : value.user.loyalty_progress
                          : value.user.loyalty_progress
                      }
                    />
                  </div>
                )}
              </>
            );
          }}
        </USER_CONTEXT.Consumer>
        <div className="confirmation__your-order">
          <CollapsibleItem
            title="Your Order"
            styles="confirmation__collapse"
            initialOpen={false}
          >
            <ul>{cartItems}</ul>
          </CollapsibleItem>
        </div>
        <div className="confirmation__price">
          <div className="confirmation__price-sub-total">
            <span>Sub Total</span>
            <span>{this.state.order?.price?.toFixed(2)} EGP</span>
          </div>
          {exist(this.state.order?.discount) &&
            this.state.order?.discount !== 0 && (
              <div className="confirmation__price-sub-total">
                <span>Discount</span>
                <span>- {this.state.order?.discount?.toFixed(2)} EGP</span>
              </div>
            )}
          {exist(this.state.order, [
            "promocode",
            "promocode_discount_amount"
          ]) &&
            this.state.order?.promocode_discount_amount !== 0 && (
              <div className="confirmation__price-sub-total">
                <span>Promo code</span>
                <span>
                  -{" "}
                  {Number(this.state.order?.promocode_discount_amount)?.toFixed(
                    2
                  )}{" "}
                  EGP
                </span>
              </div>
            )}
          <div className="confirmation__price-vat">
            <span>VAT</span>
            <span>{this.state.order?.tax?.toFixed(2)} EGP</span>
          </div>
          {exist(this.state.order?.special_delivery_price) &&
            Number(this.state.order?.special_delivery_price) !== 0 && (
              <div className="confirmation__price-sub-total">
                <span>Delivery Fee</span>
                <span>
                  {Number(this.state.order?.special_delivery_price).toFixed(2)}{" "}
                  EGP
                </span>
              </div>
            )}
          <div className="confirmation__price-total">
            <span>Total</span>
            <span>{this.state.order?.total_price?.toFixed(2)} EGP</span>
          </div>
        </div>
        <div className="confirmation__button">
          <MENU_CONTEXT.Consumer>
            {value => {
              return (
                <button
                  type="button"
                  onClick={() => {
                    this.context.clearOrder();
                    if (exist(value.branch?.id)) {
                      this.props.history.push(
                        ROUTES.BranchMenuCategories.getPath(
                          `${value.branch?.id}`
                        )
                      );
                    } else {
                      this.props.history.push(ROUTES.SelectBranch.path);
                    }
                  }}
                >
                  Place Another Order
                </button>
              );
            }}
          </MENU_CONTEXT.Consumer>
        </div>
      </div>
    );
  }
}
Confirmation.contextType = ORDER_CONTEXT;
