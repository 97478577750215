/**
 * @author Raghda Wessam
 * @date 2020-07-24
 * @description custom product size component.
 * @filename custom-product-sizes.tsx
 */
import React from "react";
import { ProductSize } from "interfaces/product";
import smallSizeIcon from "static/images/inner-product/small-size.svg";
import smallActiveSizeIcon from "static/images/inner-product/active-small-size.svg";
import mediumSizeIcon from "static/images/inner-product/medium-size.svg";
import mediumActiveSizeIcon from "static/images/inner-product/active-medium-size.svg";
import lgSizeIcon from "static/images/inner-product/lg-size.svg";
import lgActiveSizeIcon from "static/images/inner-product/active-lg-size.svg";
import exActiveSizeIcon from "static/images/inner-product/active-ex-size.svg";
import exSizeIcon from "static/images/inner-product/ex-size.svg";
import etActiveSizeIcon from "static/images/inner-product/active-et-size.svg";
import etSizeIcon from "static/images/inner-product/et-size.svg";

import { CollapsibleItem } from "../../common/collapsible-item";

interface CustomProductSizesProps {
  sizes: ProductSize[];
  selectedItem: ProductSize;
  onChangeSizeSelection: (sizeId: number) => void;
}

/**
 * React component to custom product available sizes.
 */
export class CustomProductSizes extends React.Component<
  CustomProductSizesProps
> {
  render(): React.ReactNode {
    const { sizes } = this.props;
    const imageSizes = [
      smallSizeIcon,
      mediumSizeIcon,
      lgSizeIcon,
      exSizeIcon,
      etSizeIcon
    ];
    const activeImageSizes = [
      smallActiveSizeIcon,
      mediumActiveSizeIcon,
      lgActiveSizeIcon,
      exActiveSizeIcon,
      etActiveSizeIcon
    ];
    return (
      <CollapsibleItem
        title="size"
        selectedItemName={this.props.selectedItem?.name}
        selectedItemPrice={Number(this.props.selectedItem?.price)}
        styles="sizes__container"
        initialOpen
      >
        <ul className="size__list general__list">
          {sizes
            ? sizes.map((size, index) => {
                return (
                  <li
                    key={size.id}
                    className="size-list-item"
                    onClick={() => {
                      this.props.onChangeSizeSelection(size.id);
                    }}
                  >
                    <img
                      className="item-image"
                      src={
                        this.props.selectedItem?.id === size.id
                          ? activeImageSizes[index]
                          : imageSizes[index]
                      }
                      alt={`illustration of ${size.name} size of the drink`}
                    />
                    {size.name}
                  </li>
                );
              })
            : null}
        </ul>
      </CollapsibleItem>
    );
  }
}
