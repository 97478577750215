/**
 * @author Ahmed Serag
 * @date 2021-06-03
 * @description banner component
 * @filename banner.tsx
 */
import { ROUTES } from "consts/routes";
import { MENU_CONTEXT } from "contexts/menu-context";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

export class Banner extends React.Component<RouteComponentProps> {
  declare context: React.ContextType<typeof MENU_CONTEXT>;

  render() {
    return (
      <div className="banner-wrapper">
        <div className="branch__banner">
          <div className="banner-text">
            This menu is for{" "}
            <span className="current-branch">{this.context.branch?.name}</span>
          </div>
          <button
            className="button secondary-font"
            type="button"
            onClick={() => {
              this.props.history.push(ROUTES.SelectBranch.path);
            }}
          >
            Change
          </button>
        </div>
        {this.context.branch?.discount > 0 && (
          <div className="discount__banner">
            Enjoy {this.context.branch?.discount}% off your order!
          </div>
        )}
      </div>
    );
  }
}

Banner.contextType = MENU_CONTEXT;
