/**
 * @author Raghda Wessam
 * @date 2020-07-22
 * @description Checkout Layout of the App.
 * @filename checkout.tsx
 */
import * as Sentry from "@sentry/react";
import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { ROUTES } from "consts/routes";
import { ORDER_CONTEXT } from "contexts/order-context";
import { USER_CONTEXT } from "contexts/user-context";
import { Formik, FormikProps, Form, Field, FormikHelpers } from "formik";
import { User as UserUtilities, USER_INPUT_SCHEMA } from "utilities/user";
import { OrderStatus } from "interfaces/order";
import { UserInput } from "interfaces/inputs/user-input";
import { ERRORS } from "consts/errors";
import { CheckoutProgressEvent as _CheckoutProgressEvent } from "utilities/gtag-events";
import { User as UserInterface } from "interfaces/user";
import SUMMER_ILLUSTRATION from "static/images/illustrations/payment-illustration-sahel.svg";
import ReactPixel from "react-facebook-pixel";
import { PriceItem } from "../../common/price-item";
import Loader from "../../common/loader";

interface CheckoutState {
  termsCheck: boolean;
  confirmingOrder?: boolean;
  loading: boolean;
}

/**
 * React component to render cart items.
 */
export class Checkout extends React.Component<
  RouteComponentProps,
  CheckoutState
> {
  declare context: React.ContextType<typeof USER_CONTEXT>;

  formikRef: React.RefObject<FormikProps<UserInput>>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.formikRef = React.createRef<FormikProps<UserInput>>();
    this.state = {
      termsCheck: false,
      loading: false
    };
    this.onCreateUser = this.onCreateUser.bind(this);
  }

  componentDidMount() {
    // remove under creating user if exist
    UserUtilities.removeTemporaryUser();
  }

  onCreateUser(values: UserInput, actions?: FormikHelpers<UserInput>) {
    this.setState({ loading: true });
    UserUtilities.createUser(values)
      .then(() => {
        const newUser: UserInterface = {
          email: values.email,
          phone: values.phone,
          name: values.name,
          id: null
        };
        ReactPixel.trackCustom("InitiateRegistration");
        this.context?.updateUser(newUser, Date.now());
        UserUtilities.logOutUser();
        UserUtilities.storeTemporaryUser(newUser);
        _CheckoutProgressEvent({ checkout_step: 2 });
        this.props.history.push(ROUTES.PhoneVerification.path);
      })
      .catch(errors => {
        // User is trying to login/create multiple times without verifying with OTP.
        console.error(errors);
        if (errors === ERRORS.createUser.codeAlreadySent) {
          this.props.history.push(ROUTES.PhoneVerification.path);
        }
        actions.setSubmitting(false);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render(): React.ReactNode {
    return (
      <ORDER_CONTEXT.Consumer>
        {value => {
          const subtotalPrice = value.order?.price;
          const discount = value.order?.discount;
          const discountPercentage = value.order?.discount_percentage;
          const taxPrice = value.order?.tax;
          const totalPrice = value.order?.total_price;
          const userFormInitialValue = {
            phone: this.context.user?.phone ?? "",
            name: this.context.user?.name ?? "",
            email: this.context.user?.email ?? ""
          };
          const formInitiallyValid = USER_INPUT_SCHEMA.isValidSync(
            userFormInitialValue
          );

          if (this.context.isLoading || this.state.loading) {
            return <Loader />;
          }

          if (
            value.order?.state !== OrderStatus.drafted &&
            !this.state.confirmingOrder
          ) {
            const sentryEvent: Sentry.Event = {
              message: "Checkout page",
              environment: `${process.env.sentry_environment}`,
              contexts: {
                user: {
                  token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY),
                  phone: this.context?.user?.phone,
                  name: this.context?.user?.name
                },
                order: {
                  id: value.order?.id,
                  items: value.order?.items,
                  state: value.order?.state
                }
              }
            };
            Sentry.captureEvent(sentryEvent);
            return <Redirect to={ROUTES.error.path} />;
          }

          return (
            <div className="cart__container checkout__container">
              <div className="cart__header">
                <button
                  className="button secondary-font"
                  type="button"
                  onClick={() => {
                    this.props.history.push(ROUTES.CartItems.path);
                  }}
                >
                  Back
                </button>
                <div className="main-title--wrapper">
                  <h1 className="main-title primary-font">Information</h1>
                  <img
                    className="main-title--illustration"
                    src={SUMMER_ILLUSTRATION}
                    alt=""
                  />
                </div>
              </div>
              <div className="cart__items">
                <div className="cart__items-header">
                  <h2 className="title primary-font">Enter your Information</h2>
                </div>
                <div className="cart__content">
                  {this.context.user?.id && (
                    <div className="info__wrapper">
                      <div className="info__item">
                        <label className="info-title" htmlFor="phone">
                          Mobile number
                        </label>
                        <p className="info-value info-value--edit" id="phone">
                          {this.context.user.phone}
                          <button
                            type="button"
                            className="button--inline secondary-font"
                            onClick={() => {
                              this.context.updateUser(null);
                            }}
                          >
                            Use another account
                          </button>
                        </p>
                      </div>
                      <div className="info__item">
                        <label className="info-title" htmlFor="name">
                          Your Name
                        </label>
                        <p className="info-value" id="name">
                          {this.context.user.name}
                        </p>
                      </div>
                      <div className="info__item">
                        <label className="info-title" htmlFor="email">
                          Your Email
                        </label>
                        <p className="info-value" id="email">
                          {this.context.user.email}
                        </p>
                      </div>
                    </div>
                  )}
                  {!this.context.user?.id && (
                    <Formik
                      initialValues={userFormInitialValue}
                      innerRef={this.formikRef}
                      validationSchema={USER_INPUT_SCHEMA}
                      onSubmit={this.onCreateUser}
                      isInitialValid={formInitiallyValid}
                    >
                      {(formikBag: FormikProps<UserInput>) => {
                        return (
                          <Form className="user-input__form">
                            <div className="fields__wrapper">
                              <Field name="phone">
                                {({ field, meta }) => (
                                  <div className="form-field">
                                    <label htmlFor="phone">Mobile number</label>
                                    <input
                                      className="secondary-font"
                                      id="phone"
                                      name="phone"
                                      type="tel"
                                      placeholder="01000000000"
                                      {...field}
                                      value={field.value ?? ""}
                                      onKeyDown={e => {
                                        const symbols =
                                          "!#$%&'()*+,-./:;<=>?@[]^_`{|}~";

                                        return (
                                          (symbols.includes(e.key) ||
                                            e.keyCode > 57) &&
                                          e.preventDefault()
                                        );
                                      }}
                                    />
                                    {meta.touched && meta.error && (
                                      <div className="error">{meta.error}</div>
                                    )}
                                  </div>
                                )}
                              </Field>
                              <Field name="name">
                                {({ field, meta }) => (
                                  <div className="form-field">
                                    <label htmlFor="name">Your Name</label>
                                    <input
                                      className="secondary-font"
                                      id="name"
                                      name="name"
                                      type="text"
                                      placeholder="Name"
                                      value={field.value ?? ""}
                                      {...field}
                                    />
                                    {meta.touched && meta.error && (
                                      <div className="error">{meta.error}</div>
                                    )}
                                  </div>
                                )}
                              </Field>
                              <Field name="email">
                                {({ field, meta }) => (
                                  <div className="form-field">
                                    <label htmlFor="email">Your Email</label>
                                    <input
                                      className="secondary-font"
                                      id="email"
                                      name="email"
                                      type="text"
                                      placeholder="email@example.com"
                                      value={field.value ?? ""}
                                      {...field}
                                    />
                                    {meta.touched && meta.error && (
                                      <div className="error">{meta.error}</div>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </div>
                            <PriceItem
                              subTotal={subtotalPrice}
                              subscriptionDiscount={
                                value.order?.subscription_discount
                              }
                              tax={taxPrice}
                              total={totalPrice}
                              discount={discount}
                              discount_percentage={discountPercentage}
                              promocode={value.order?.promocode}
                              promocodeDiscountAmount={
                                value.order?.promocode_discount_amount
                              }
                              promocodeType={value.promocodeType}
                              campaignDiscountAmount={
                                value.order?.campaign_discount_amount
                              }
                              specialDeliveryAmount={
                                value.order?.special_delivery_price
                              }
                              onRemovePromoCode={() => {
                                value.updateOrderPromocode(
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined
                                );
                              }}
                            />
                            <div className="cart__term-and-conditions secondary-font">
                              <input
                                id="terms-and-conditions"
                                type="checkbox"
                                checked={this.state.termsCheck}
                                onChange={e => {
                                  this.setState({
                                    termsCheck: e.currentTarget.checked
                                  });
                                }}
                              />
                              <label htmlFor="terms-and-conditions">
                                I agree to the{" "}
                              </label>
                              <button
                                type="button"
                                className="secondary-font"
                                onClick={e => {
                                  e.preventDefault();
                                  this.context.updateUser({
                                    email: this.formikRef.current.values[
                                      "email"
                                    ],
                                    name: this.formikRef.current.values["name"],
                                    phone: this.formikRef.current.values[
                                      "phone"
                                    ],
                                    id: null
                                  });
                                  this.props.history.push(
                                    ROUTES.TermsAndConditions.path
                                  );
                                }}
                              >
                                Terms and Conditions
                              </button>
                            </div>
                            <div className="button__wrapper">
                              <button
                                className={`button--primary button--fixed primary-font ${
                                  value.loadingOrder ? "button--loading " : ""
                                }
                                ${
                                  value.loadingOrder ||
                                  (!this.context.user?.id &&
                                    (formikBag.isValid === false ||
                                      !this.state.termsCheck))
                                    ? "button--disabled"
                                    : ""
                                }`}
                                disabled={
                                  value.loadingOrder ||
                                  (!this.context.user?.id &&
                                    (formikBag.isValid === false ||
                                      !this.state.termsCheck))
                                }
                                type="submit"
                              >
                                {!value.loadingOrder && (
                                  <div className="button-content">
                                    Place Order
                                  </div>
                                )}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                </div>
              </div>
              {this.context.user?.id && (
                <>
                  <PriceItem
                    subTotal={subtotalPrice}
                    subscriptionDiscount={value.order?.subscription_discount}
                    tax={taxPrice}
                    total={totalPrice}
                    discount={discount}
                    promocodeType={value.promocodeType}
                    discount_percentage={discountPercentage}
                    promocode={value.order?.promocode}
                    promocodeDiscountAmount={
                      value.order?.promocode_discount_amount
                    }
                    campaignDiscountAmount={
                      value.order?.campaign_discount_amount
                    }
                    specialDeliveryAmount={value.order?.special_delivery_price}
                    onRemovePromoCode={() => {
                      value.updateOrderPromocode(
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined
                      );
                    }}
                  />
                  <div className="button__wrapper">
                    <button
                      className={`button--primary button--fixed primary-font ${
                        value.loadingOrder ? "button--loading " : ""
                      }`}
                      type="button"
                      onClick={() => {
                        this.setState({ confirmingOrder: true, loading: true });
                        _CheckoutProgressEvent({ checkout_step: 4 });
                        // Continue to payment page
                        value.updateOrder().then(() => {
                          this.props.history.push(ROUTES.Payment.path);
                        });
                      }}
                    >
                      {!value.loadingOrder && (
                        <div className="button-content">Continue Checkout</div>
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          );
        }}
      </ORDER_CONTEXT.Consumer>
    );
  }
}
Checkout.contextType = USER_CONTEXT;
