/**
 * @author Raghda Wessam
 * @date 2020-07-24
 * @description custom product extras component.
 * @filename custom-product-extras.tsx
 */
import React from "react";
import { ProductExtra } from "interfaces/product";
import { CollapsibleItem } from "../../common/collapsible-item";
import { QuantityCounter } from "../../common/quantity-counter";

interface CustomProductExtrasProps {
  extras: ProductExtra[];
  selectedExtras: Record<
    string,
    {
      id: number;
      quantity: number;
      itemPrice: number;
    }
  >;
  onSelectExtra: (extra: ProductExtra, quantity: number) => void;
}

/**
 * React component to render custom product available extras.
 */
export class CustomProductExtras extends React.Component<
  CustomProductExtrasProps
> {
  render(): React.ReactNode {
    const { extras } = this.props;
    const selectedExtrasKeys = Object.keys(this.props.selectedExtras);
    const totalExtrasPrice = selectedExtrasKeys.reduce((prev, cur) => {
      // eslint-disable-next-line prettier/prettier
      return (
        prev +
        this.props.selectedExtras[cur].itemPrice *
          this.props.selectedExtras[cur].quantity
      );
    }, 0);

    return (
      <CollapsibleItem
        title="Extras"
        styles="extras__container"
        initialOpen
        selectedItemPrice={totalExtrasPrice}
      >
        <ul className="extras__list general__list">
          {extras.map(extra => {
            return (
              <li key={extra.id} className="extra-list-item">
                <span className="extra-name">{extra.name}</span>
                <span className="extra-price">{`${extra.price} EGP`}</span>
                <QuantityCounter
                  currentQuantity={
                    this.props.selectedExtras[extra.id]
                      ? this.props.selectedExtras[extra.id].quantity
                      : 0
                  }
                  onQuantityUpdate={quantity => {
                    this.props.onSelectExtra(extra, quantity);
                  }}
                />
              </li>
            );
          })}
        </ul>
      </CollapsibleItem>
    );
  }
}
