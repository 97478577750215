/**
 * @author Ahmed Serag
 * @date 2020-07-19
 * @description Implementation of order context that
 * holds under creation order.
 * @filename order-context.ts
 */
import * as React from "react";
import { CreateOrderItemInput } from "interfaces/inputs/create-order-item-input";
import {
  Order,
  OrderType,
  PaymentMethod,
  PromocodeType
} from "interfaces/order";
import { Product } from "interfaces/product";

/**
 * order context that holds under creation order.
 */
export const ORDER_CONTEXT: React.Context<{
  /**
   * created order from BE.
   */
  order?: Order;
  /**
   * minimum order price that's eligible for a stamp.
   */
  stampPrice?: number;
  /**
   * selected items to be included in the order.
   */
  items: Record<string, CreateOrderItemInput>;
  /**
   * price of items in the order removing unavailable items.
   */
  itemsPrice: number;
  /**
   * a boolean which is true if the order is loading.
   */
  loadingOrder: boolean;
  /**
   * type of the order created in terms of delivery.
   */
  orderType: OrderType;
  updateOrderType: (orderType: OrderType) => void;
  /**
   * type of the car the order delivered to.
   */
  pickupCarType?: string;
  /**
   * color of the car the order delivered to.
   */
  pickupCarColor?: string;
  /**
   * estimated arrival time for delivery.
   */
  pickupETA?: string;
  /**
   * update pickup details for the order
   */
  updatePickupDetails: (pickupDetails: {
    pickupCarType?: string;
    pickupCarColor?: string;
    pickupETA?: string;
  }) => void;
  /**
   * a function to update current selected items
   */
  updateItem: (item: CreateOrderItemInput, itemId: string) => Promise<void>;
  /**
   * remove item from selected items.
   */
  removeItem: (itemId: string) => Promise<void>;
  /**
   * create order of the current selected items
   */
  createOrder: () => Promise<unknown>;
  /**
   * a comment added to the order.
   */
  comment?: string;
  /**
   * add new comment to the order.
   */
  updateComment: (comment: string) => void;
  /**
   *incase customer is having order at the store.
   *
   * @type {string}
   */
  tableNumber?: string;
  /**
   * add table number to the order.
   */
  updateTableNumber: (tableNumber: string) => void;
  /**
   * update current order with new items and validates it from the backend.
   */
  updateOrder: () => Promise<unknown>;
  /**
   * confirm order to be sent to barista
   */
  confirmOrder: (
    paymentMethod: PaymentMethod
  ) => Promise<{
    order: Order;
    token?: string;
  }>;
  /**
   * clear context order and related details.
   */
  clearOrder: () => Promise<unknown>;

  /**
   * remove order object only from context
   *
   */
  removeOrder: () => void;
  /**
   * promocode to be used to create the order
   */
  promocode?: string;

  /**
   * discount percentage applied by the promocode
   */
  promocodePercentage?: number;

  /**
   * amount reduced by the promocode
   */
  promocodeDiscountAmount?: number;

  /**
   *type of promocode applied on the order.
   *
   * @type {PromocodeType}
   */
  promocodeType?: PromocodeType;

  promocodeFreeProducts?: Product[];

  /**
   * add the promocode to the
   */
  updateOrderPromocode: (
    code: string,
    discountAmount?: number,
    percentage?: number,
    type?: string,
    promocodeFreeProducts?: Product[]
  ) => Promise<unknown>;
}> = React.createContext({
  items: null,
  itemsPrice: null,
  loadingOrder: null,
  updateItem: null,
  removeItem: null,
  createOrder: null,
  updateComment: null,
  updateTableNumber: null,
  updateOrder: null,
  confirmOrder: null,
  clearOrder: null,
  removeOrder: null,
  orderType: null,
  updateOrderType: null,
  updatePickupDetails: null,
  updateOrderPromocode: null
});
