/**
 * @author Ahmed Elshantaly
 * @date 2020-08-09
 * @description Loyalty Stamp Card
 * @filename loyalty-stamp-card.tsx
 */
import React from "react";
import { USER_CONTEXT } from "contexts/user-context";

export class LoyaltyStampCard extends React.Component<{
  light?: boolean;
  loyaltyProgress: number;
}> {
  declare context: React.ContextType<typeof USER_CONTEXT>;

  render(): React.ReactNode {
    let displayedLevel1 = this.context.user?.loyalty_level + 1;
    let displayedLevel2 = this.context.user?.loyalty_level + 2;
    if (displayedLevel1 > 3) {
      displayedLevel1 = 1;
    }

    if (displayedLevel2 > 3) {
      displayedLevel2 = 1;
    }
    return (
      <div className={`loyalty-stamp-card${this.props?.light ? " light" : ""}`}>
        <p className="title">Stamp Card</p>
        {this.context.user?.loyalty_progress === 4 &&
          this.context.user?.loyalty_progress !==
            this.props.loyaltyProgress && (
            <div className="loyalty-stamp-card__level">
              {`${4 -
                this.props
                  .loyaltyProgress} more stamp(s) for a level ${displayedLevel2} gift`}
            </div>
          )}

        {this.props.loyaltyProgress < 4 &&
          this.context.user?.loyalty_progress !== 4 && (
            <div className="loyalty-stamp-card__level">
              {`${4 -
                this.props
                  .loyaltyProgress} more stamp(s) for a level ${displayedLevel1} gift`}
            </div>
          )}

        <ul className="loyalty-stamp-card__list general__list">
          <li
            className={
              this.props.loyaltyProgress > 0
                ? this.context.user?.loyalty_progress % 4 ===
                    this.props.loyaltyProgress - 1 &&
                  (this.context.user?.loyalty_progress === 0 ||
                    this.context.user?.loyalty_progress === 4)
                  ? "loyalty-stamp-card__list-item loyalty-stamp-card__list-item--active loyalty-stamp-card__list-item--temp"
                  : "loyalty-stamp-card__list-item loyalty-stamp-card__list-item--active"
                : "loyalty-stamp-card__list-item"
            }
          >
            <div
              className={`stamp-wrapper${this.props?.light ? " light" : ""}`}
            />
          </li>
          <li
            className={
              this.props.loyaltyProgress > 1
                ? this.context.user?.loyalty_progress ===
                    this.props.loyaltyProgress - 1 &&
                  this.context.user?.loyalty_progress === 1
                  ? "loyalty-stamp-card__list-item loyalty-stamp-card__list-item--active loyalty-stamp-card__list-item--temp"
                  : "loyalty-stamp-card__list-item loyalty-stamp-card__list-item--active"
                : "loyalty-stamp-card__list-item"
            }
          >
            <div
              className={`stamp-wrapper${this.props?.light ? " light" : ""}`}
            />
          </li>
          <li
            className={
              this.props.loyaltyProgress > 2
                ? this.context.user?.loyalty_progress ===
                    this.props.loyaltyProgress - 1 &&
                  this.context.user?.loyalty_progress === 2
                  ? "loyalty-stamp-card__list-item loyalty-stamp-card__list-item--active loyalty-stamp-card__list-item--temp"
                  : "loyalty-stamp-card__list-item loyalty-stamp-card__list-item--active"
                : "loyalty-stamp-card__list-item"
            }
          >
            <div
              className={`stamp-wrapper${this.props?.light ? " light" : ""}`}
            />
          </li>
          <li
            className={
              this.props.loyaltyProgress > 3
                ? this.context.user?.loyalty_progress ===
                    this.props.loyaltyProgress - 1 &&
                  this.context.user?.loyalty_progress === 3
                  ? "loyalty-stamp-card__list-item loyalty-stamp-card__list-item--active loyalty-stamp-card__list-item--temp"
                  : "loyalty-stamp-card__list-item loyalty-stamp-card__list-item--active"
                : "loyalty-stamp-card__list-item"
            }
          >
            <div
              className={`stamp-wrapper${this.props?.light ? " light" : ""}`}
            />
          </li>
        </ul>
      </div>
    );
  }
}

LoyaltyStampCard.contextType = USER_CONTEXT;
