/**
 * @author Ahmed Serag
 * @date 2020-07-06
 * @description list branch-menu items
 * @filename branch-menu-categories.tsx
 */
import React from "react";

export interface ModalProps {
  header: string;
  body: string;
}

export class Modal extends React.Component<ModalProps> {
  render() {
    return (
      <div className="modal-wrapper">
        <div className="modal">
          <h2 className="modal-header">{this.props.header}</h2>
          <p className="modal-body">{this.props.body}</p>
          {this.props.children}
        </div>
      </div>
    );
  }
}
