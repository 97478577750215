/**
 * @author Ahmed Serag
 * @date 2020-07-06
 * @description list branch-menu items
 * @filename branch-menu-categories.tsx
 */
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import orderBy from "lodash.orderby";
import Toastr from "toastr";
import { CreateOrderItemInput } from "interfaces/inputs/create-order-item-input";
import { MENU_CONTEXT } from "contexts/menu-context";
import { ORDER_CONTEXT } from "contexts/order-context";
import { ROUTES } from "consts/routes";
import plusIcon from "static/images/icons/plus-light.svg";
import subtractIcon from "static/images/icons/subtract-light.svg";
// TODO replace with default image!
import hotDrinkIcon from "static/images/products/hot-drinks.png";
import addIcon from "static/images/icons/add-light.svg";
import {
  addToCartEvent as _addToCartEvent,
  beginCheckoutEvent as _beginCheckoutEvent,
  pageViewEvent,
  RemoveFromCartEvent as _RemoveFromCartEvent,
  ViewItemEventParameters
} from "utilities/gtag-events";
import { Product as ProductUtilities } from "utilities/product";
import { OrderStatus } from "interfaces/order";
import ReactPixel from "react-facebook-pixel";
import { ProductCard } from "../../common/product-card";
import { CategorySelector } from "./category-selector";
import Loader from "../../common/loader";
import { Banner } from "../../common/banner";
/**
 * React component to render branch menu.
 */

export class BranchMenu extends React.Component<RouteComponentProps> {
  declare context: React.ContextType<typeof MENU_CONTEXT>;

  componentDidMount() {
    ReactPixel.trackCustom("CategoryView");
  }

  categoryHandler = categoryId => {
    const activeCategory =
      this.context?.categories.find(
        category => Number(category.id) === categoryId
      ) ?? this.context?.categories[0];

    this.context.updateActiveTab(
      categoryId,
      activeCategory.subcategories[0].id
    );

    pageViewEvent(
      undefined,
      this.props.location.pathname,
      `menu-category [${activeCategory?.name}] -> [${activeCategory?.subcategories[0]?.name}]`
    );
  };

  render(): React.ReactNode {
    if (this.context.loadingMenu) {
      return <Loader />;
    }

    const activeCategory =
      this.context?.categories?.find(
        c => Number(c.id) === this.context.activeCategoryId
      ) ?? this.context?.categories[0];

    const activeSubCategory =
      activeCategory.subcategories.find(
        s => Number(s.id) === this.context.activeSubCategoryId
      ) ?? activeCategory.subcategories[0];

    const availableSubCategories = orderBy(
      activeCategory.subcategories,
      ["sequence"],
      ["asc"]
    );
    ReactPixel.trackCustom("SubCategoryChanged", {
      category: activeSubCategory.name
    });

    return (
      <ORDER_CONTEXT.Consumer>
        {value => {
          if (value.order && value.order?.state !== OrderStatus.drafted) {
            value.clearOrder();
          }
          return (
            <>
              <Banner {...this.props} />
              <section
                className={`section categories__section categories__section--wrapper ${
                  this.context.branch?.discount ? " banner-large" : ""
                }`}
              >
                <CategorySelector
                  availableCategories={this.context.categories}
                  categoryId={activeCategory.id}
                  categoryHandler={this.categoryHandler}
                  type="category"
                />
                <CategorySelector
                  availableCategories={availableSubCategories}
                  categoryId={activeSubCategory.id}
                  categoryHandler={subCategoryId => {
                    const newSubCategory = activeCategory.subcategories.find(
                      s => Number(s.id) === this.context.activeSubCategoryId
                    );
                    pageViewEvent(
                      undefined,
                      this.props.location.pathname,
                      `menu-category [${activeCategory?.name}] -> [${newSubCategory?.name}]`
                    );
                    this.context.updateActiveTab(
                      this.context.activeCategoryId,
                      subCategoryId
                    );
                  }}
                  type="subCategory"
                />
                <>
                  <h3 className="subcategory__title primary-font">
                    {activeSubCategory.name}
                    <p className="vat-description secondary-font">
                      All prices exclude VAT
                    </p>
                  </h3>
                  <ul
                    className={
                      Object.keys(value.items).length > 0
                        ? "product__list product__list--items general__list"
                        : "product__list general__list"
                    }
                  >
                    {activeSubCategory.products?.map(product => {
                      let selectedItem: CreateOrderItemInput;
                      const selectedItemQuantity = Object.keys(
                        value.items
                      ).reduce((prev, cur) => {
                        return value.items[cur].id === Number(product.id)
                          ? prev + value.items[cur].quantity
                          : prev;
                      }, 0);
                      let itemKey: string = Object.keys(value.items).find(
                        key => {
                          return (
                            value.items[key].id === Number(product.id) &&
                            !value.items[key].is_free_gift &&
                            !value.items[key].promocode_gift
                          );
                        }
                      );
                      if (!itemKey) {
                        // create new item if not found before
                        itemKey = `${product.id}`;
                        selectedItem = {
                          id: Number(product.id),
                          quantity: 0,
                          price: 0,
                          product
                        };
                      }
                      selectedItem = selectedItem ?? value.items[itemKey];

                      return (
                        <ProductCard
                          style={
                            product.customizable
                              ? "product__custom"
                              : "product__custom product__custom--counter"
                          }
                          custom={product.customizable}
                          key={product.id}
                          bogo={product.campaigns?.has_bogo}
                          campaign_discount={
                            product.campaigns?.campaign_percentage
                          }
                          onClick={() => {
                            if (product.customizable) {
                              this.props.history.push(
                                ROUTES.ProductDetails.getPath(
                                  `${this.context.branch.id}`,
                                  `${product.id}`
                                )
                              );
                            }
                          }}
                          name={product.name}
                          image={product.image ?? hotDrinkIcon}
                          description={product.description.replace(
                            new RegExp(/\\n/g),
                            " "
                          )}
                        >
                          {product.price && (
                            <p className="product__price">
                              {product.campaigns?.campaign_percentage > 0
                                ? (product.price *
                                    (100 -
                                      product.campaigns?.campaign_percentage)) /
                                  100
                                : product.price}
                              <span className="currency">EGP</span>
                            </p>
                          )}
                          {product.campaigns?.campaign_percentage > 0 && (
                            <p className="product__price--old">
                              {product.price}
                              <span className="currency">EGP</span>
                            </p>
                          )}
                          {product.customizable ? (
                            <div className="product__footer">
                              <button
                                className="button primary-font"
                                type="button"
                              >
                                {product.is_subscription_product
                                  ? "Explore"
                                  : "Customize"}
                              </button>
                            </div>
                          ) : null}
                          {!product.customizable && selectedItemQuantity === 0 && (
                            <div className="product__footer product__add">
                              <img
                                src={plusIcon}
                                alt="add product"
                                onClick={() => {
                                  selectedItem.quantity += 1;
                                  selectedItem.price +=
                                    product.campaigns.campaign_percentage > 0
                                      ? (Number(product.price) *
                                          (100 -
                                            product.campaigns
                                              ?.campaign_percentage)) /
                                        100
                                      : Number(product.price);
                                  _addToCartEvent({
                                    currency: "EGP",
                                    value: `${selectedItem.price}`,
                                    items: [
                                      {
                                        id: `${selectedItem.id}`,
                                        name: selectedItem?.product?.name,
                                        quantity: 1,
                                        category: activeSubCategory?.name
                                      }
                                    ]
                                  });
                                  value.updateItem(selectedItem, itemKey);
                                }}
                              />
                            </div>
                          )}
                          {!product.customizable && selectedItemQuantity > 0 && (
                            <div className="product__footer product__footer--counter product__add">
                              <img
                                src={subtractIcon}
                                alt="remove product"
                                onClick={() => {
                                  selectedItem.quantity -= 1;
                                  selectedItem.price -=
                                    product.campaigns.campaign_percentage > 0
                                      ? (Number(product.price) *
                                          (100 -
                                            product.campaigns
                                              ?.campaign_percentage)) /
                                        100
                                      : Number(product.price);
                                  if (selectedItemQuantity === 0) {
                                    value.removeItem(itemKey);
                                  } else {
                                    _RemoveFromCartEvent({
                                      currency: "EGP",
                                      value: `${selectedItem.price}`,
                                      items: [
                                        {
                                          id: `${selectedItem.id}`,
                                          name: selectedItem?.product?.name,
                                          quantity: 1
                                        }
                                      ]
                                    });
                                    value.updateItem(selectedItem, itemKey);
                                  }
                                }}
                              />
                              {selectedItemQuantity}
                              <img
                                src={addIcon}
                                alt="add product"
                                onClick={() => {
                                  selectedItem.quantity += 1;
                                  selectedItem.price +=
                                    product.campaigns.campaign_percentage > 0
                                      ? (Number(product.price) *
                                          (100 -
                                            product.campaigns
                                              ?.campaign_percentage)) /
                                        100
                                      : Number(product.price);
                                  _addToCartEvent({
                                    currency: "EGP",
                                    value: `${selectedItem.price}`,
                                    items: [
                                      {
                                        id: `${selectedItem.id}`,
                                        name: selectedItem?.product?.name,
                                        quantity: 1,
                                        category: activeSubCategory?.name
                                      }
                                    ]
                                  });
                                  value.updateItem(selectedItem, itemKey);
                                }}
                              />
                            </div>
                          )}
                        </ProductCard>
                      );
                    })}
                  </ul>
                  {Object.keys(value.items).length > 0 && (
                    <div className="button__wrapper button__wrapper--fixed button__wrapper--view button-wrapper--count">
                      <button
                        type="button"
                        className={`button--primary button--fixed button--count primary-font${
                          value.loadingOrder ? " button--loading" : ""
                        }`}
                        disabled={value.loadingOrder}
                        onClick={() => {
                          let promise: Promise<unknown>;

                          if (value.order) {
                            promise = value.updateOrder();
                          } else {
                            promise = value.createOrder();
                          }
                          promise
                            .then(() => {
                              _beginCheckoutEvent({
                                currency: "EGP",
                                value: value.itemsPrice,
                                items: Object.keys(value.items).map(
                                  (item): ViewItemEventParameters => {
                                    return {
                                      id: `${value.items[item].id}`,
                                      name: `${value.items[item].product?.name}`,
                                      price: value.items[item].price,
                                      quantity: value.items[item].quantity,
                                      category: ProductUtilities.getProductSubCategory(
                                        this.context?.categories,
                                        value.items[item].product
                                      )?.name
                                    };
                                  }
                                )
                              });
                              this.props.history.push(ROUTES.CartItems.path);
                            })
                            .catch(err => {
                              Toastr.options.positionClass =
                                "toast-top-full-width";
                              Toastr.error(err);
                            });
                        }}
                      >
                        {!value.loadingOrder && (
                          <div className="button-content">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <span className="item-count">
                                {Object.keys(value.items).reduce(
                                  (sum, key) => sum + value.items[key].quantity,
                                  0
                                )}
                              </span>
                              View Order
                            </div>
                            <div className="total-price">
                              <span className="price-title secondary-font">
                                Total:
                              </span>
                              <span className="item-price secondary-font">
                                {value.itemsPrice
                                  ? Number(value.itemsPrice).toFixed(2)
                                  : 0}
                                <span className="currency secondary-font">
                                  EGP
                                </span>
                              </span>
                            </div>
                          </div>
                        )}
                      </button>
                    </div>
                  )}
                </>
              </section>
            </>
          );
        }}
      </ORDER_CONTEXT.Consumer>
    );
  }
}
BranchMenu.contextType = MENU_CONTEXT;
