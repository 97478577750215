/**
 * @author Ahmed Serag
 * @date 2020-07-06
 * @description list branch-menu items
 * @filename branch-menu-categories.tsx
 */
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { MENU_CONTEXT } from "contexts/menu-context";
import { ROUTES } from "consts/routes";
import hotDrinkIcon from "static/images/products/hot-drinks.png";
import { pageViewEvent } from "utilities/gtag-events";
import SUMMER_ILLUSTRATION from "static/images/illustrations/menu-illustration-sahel.svg";
import POPUP_IMAGE from "static/images/icons/popup.png";
import orderBy from "lodash.orderby";
import ReactPixel from "react-facebook-pixel";
import Popup from "reactjs-popup";
import { ProductCard } from "../../common/product-card";
import Loader from "../../common/loader";
import { Banner } from "../../common/banner";
/**
 * React component to render branch menu.
 */
export class BranchMenuCategories extends React.Component<RouteComponentProps> {
  declare context: React.ContextType<typeof MENU_CONTEXT>;

  componentDidMount() {
    pageViewEvent(undefined, this.props.location.pathname, "menu-categories");
    ReactPixel.trackCustom("MenuView");
  }

  render(): React.ReactNode {
    if (this.context.loadingMenu) {
      return <Loader />;
    }

    let subCategories: {
      name: string;
      image: string;
      id: number;
      description: string;
      categoryId: number;
      color_code?: string;
      sequence?: number;
    }[] = this.context?.categories?.reduce((prev, current) => {
      const subs = current.subcategories.map(s => {
        return {
          name: s.name,
          image: s.image ?? hotDrinkIcon,
          id: s.id,
          description: s.description,
          categoryId: current.id,
          color_code: s.color_code,
          sequence: s.sequence
        };
      });

      return [...prev, ...subs];
    }, []);

    subCategories = orderBy(subCategories, ["sequence"], ["asc"]);
    return (
      <>
        <Banner {...this.props} />
        <section
          className={`section categories__section${
            this.context.branch?.discount ? " banner-large" : ""
          }`}
        >
          <div className="categories__title--wrapper">
            <h1 className="categories__title main-title primary-font sahel">
              Menu
            </h1>
            <img
              className="categories__title--illustration"
              src={SUMMER_ILLUSTRATION}
              alt=""
            />
          </div>
          <Popup
            open={this.context.branch?.enable_app_popup}
            modal
            className="custom-popup"
            closeOnDocumentClick={false}
            closeOnEscape={false}
          >
            <div style={{ textAlign: "center" }}>
              <h1>Our New App is HERE!</h1>
              <img className="rounder-curve" src={POPUP_IMAGE} alt="" />
              <p>
                Click{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://onelink.to/oppebanner"
                >
                  Here
                </a>{" "}
                to Download
              </p>
            </div>
          </Popup>
          {subCategories && (
            <ul className="product__list general__list">
              {subCategories?.map(subCategory => {
                return (
                  <ProductCard
                    onClick={() => {
                      this.context.updateActiveTab(
                        subCategory.categoryId,
                        subCategory.id
                      );
                      this.props.history.push(
                        ROUTES.BranchMenu.getPath(`${this.context.branch?.id}`)
                      );
                    }}
                    key={subCategory.id}
                    name={subCategory.name}
                    image={subCategory.image}
                    background={subCategory.color_code}
                  />
                );
              })}
            </ul>
          )}
        </section>
      </>
    );
  }
}
BranchMenuCategories.contextType = MENU_CONTEXT;
