/* eslint-disable jsx-a11y/alt-text */
/**
 * @author Raghda Wessam
 * @date 2020-07-20
 * @description Single Cart item Layout.
 * @filename cart-item.tsx
 */
import React from "react";
import addIcon from "static/images/icons/add.svg";
import removeIcon from "static/images/icons/subtract.svg";
import deleteIcon from "static/images/icons/delete.svg";
import GIFT_ICON from "static/images/icons/gift-light.svg";

interface CartItemProps {
  itemKey: string;
  image: string;
  name: string;
  customization?: string;
  quantity: number;
  price: number;
  available?: boolean;
  updateQuantity?: (newQuantity: number) => void;
  gift?: boolean;
  isSubscriptionProduct?: boolean;
}

/**
 * React component to render single cart item.
 */
export class CartItem extends React.Component<CartItemProps> {
  render(): React.ReactNode {
    const {
      itemKey,
      image,
      name,
      customization,
      price,
      quantity,
      available,
      updateQuantity,
      gift,
      isSubscriptionProduct
    } = this.props;

    return (
      <li
        key={itemKey}
        className={`cart__item${!available ? " cart__item--out-of-stock" : ""}${
          gift ? " cart__item--gift" : ""
        }`}
      >
        {gift && (
          <div className="cart__item--free-gift">
            <img src={GIFT_ICON} />
            <span>Free gift</span>
          </div>
        )}
        <div className="item-img">
          <img src={image} alt={name} />
        </div>
        <div className="item-description">
          <h3 className="item-title">{name}</h3>
          {customization && (
            <p className="item-customization">{customization}</p>
          )}

          {/* // TODO: activate this when edit is implemented */}
          {/* <button
            type="button"
            className="item-edit secondary-font"
            onClick={() => {
              console.log("edit order");
            }}
          >
            Edit Order
          </button> */}
        </div>
        <div className="item-extra">
          <p className="item-price">
            {gift ? (
              <>Free Gift</>
            ) : (
              <>
                {Number(price).toFixed(2)}
                <span className="currency">EGP</span>
              </>
            )}
          </p>
          {updateQuantity && (
            <div className="item-counter">
              {isSubscriptionProduct ? (
                <>
                  <button
                    type="button"
                    className="button--inline secondary-font"
                    style={{ color: "red" }}
                    onClick={() => {
                      updateQuantity(quantity - 1);
                    }}
                  >
                    Remove
                  </button>
                </>
              ) : (
                <>
                  <img
                    src={Number(quantity) <= 1 ? deleteIcon : removeIcon}
                    alt="remove item icon"
                    onClick={() => updateQuantity(quantity - 1)}
                  />
                  <span className="quantity">{quantity}</span>
                  <img
                    src={addIcon}
                    alt="add item icon"
                    style={{
                      opacity: gift ? 0.5 : 1
                    }}
                    onClick={() => {
                      if (!gift) {
                        updateQuantity(quantity + 1);
                      }
                    }}
                  />
                </>
              )}
            </div>
          )}
        </div>
        {!available && (
          <div className="cart__content--out-of-stock">
            <h3 className="title">Out of stock</h3>
            <button
              type="button"
              className="button--inline"
              onClick={() => updateQuantity(0)}
            >
              Remove from cart
            </button>
          </div>
        )}
      </li>
    );
  }
}
