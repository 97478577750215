/**
 * @author Ahmed Serag
 * @date 2020-07-05
 * @description implementation of Branch data model interface.
 * @filename branch.ts
 */
import { Common } from "./common";

export interface Branch extends Common {
  image: string;
  UUID?: string;
  is_curbside?: 0 | 1;
  discount?: number;
  is_special_delivery?: boolean;
  special_delivery_description?: string;
  special_delivery_info?: string;
  special_delivery_title?: string;
  special_delivery_price?: number;
  phone_number?: string;
  sector?: {
    name: string;
    id: number;
  };
  availability: BranchAvailability;
  invitation_promocode: {
    id: number;
    code: string;
  };
  enable_app_popup?: boolean;
}

export enum BranchAvailability {
  closed = "CLOSED",
  opened = "OPENED"
}

export interface RoomDelivery {
  roomNumber: string;
  floorNumber: string;
}
