/**
 * @author Raghda Wessam
 * @date 2020-07-26
 * @description counter component responsible for incerementing/decrementing number of objects.
 * @filename quantity-counter.tsx
 */
import React from "react";
import addIcon from "static/images/icons/add.svg";
import subtractIcon from "static/images/icons/subtract.svg";
import { exist as _exist } from "utilities/common";
/**
 * React component to render quantity counter.
 */

interface QuantityCounterProps {
  minQuantity?: number;
  currentQuantity: number;
  onQuantityUpdate: (quantity: number) => void;
}
export class QuantityCounter extends React.Component<QuantityCounterProps> {
  render(): React.ReactNode {
    return (
      <div className="quantity-counter__container">
        <button
          className="quantity-counter__button quantity-counter__button--decrement"
          type="button"
          onClick={() => {
            // min quantity by default is 0 or minQuantity props
            const minQuantity = _exist(this.props.minQuantity)
              ? this.props.minQuantity
              : 0;

            if (this.props.currentQuantity > minQuantity) {
              this.props.onQuantityUpdate(this.props.currentQuantity - 1);
            }
          }}
        >
          <img src={subtractIcon} alt="decrement icon" />
        </button>
        <span className="quantity-counter__value">{`${this.props.currentQuantity}`}</span>
        <button
          className="quantity-counter__button quantity-counter__button--increment"
          type="button"
          onClick={() => {
            this.props.onQuantityUpdate(this.props.currentQuantity + 1);
          }}
        >
          <img src={addIcon} alt="increment icon" />
        </button>
      </div>
    );
  }
}
