/* eslint-disable no-restricted-globals */
/**
 * @author Raghda Wessam
 * @date 2020-07-22
 * @description Phone Verification Layout.
 * @filename phone-verification.tsx
 */
import React from "react";
import Toastr from "toastr";
import { USER_CONTEXT } from "contexts/user-context";
import { ROUTES } from "consts/routes";
import { exist, isEmpty } from "utilities/common";
import { RouteComponentProps } from "react-router-dom";
import { ERRORS } from "consts/errors";

import { PhoneVerificationInput } from "interfaces/inputs/phone-verification-input";
import { User as UserUtilities } from "utilities/user";
import { CheckoutProgressEvent as _CheckoutProgressEvent } from "utilities/gtag-events";
import ReactPixel from "react-facebook-pixel";

interface PhoneVerificationState {
  verificationInput: [number, number, number, number];
}

/**
 * React component to render phone verification page.
 */
export class PhoneVerification extends React.Component<
  RouteComponentProps,
  PhoneVerificationState
> {
  declare context: React.ContextType<typeof USER_CONTEXT>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      verificationInput: [null, null, null, null]
    };

    this.isInputValid = this.isInputValid.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.getCodeBoxElement = this.getCodeBoxElement.bind(this);
    this.onSubmitPhoneVerificationCode = this.onSubmitPhoneVerificationCode.bind(
      this
    );
  }

  /**
   * move and focus the next input field
   *
   * @param {number} index
   * @param { React.ChangeEvent<HTMLInputElement>} event
   * @memberof PhoneVerification
   */
  onChangeInput(
    index: 0 | 1 | 2 | 3,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const currentInput = this.state.verificationInput;
    let newValue;

    if (
      // if the new value added after the current value, then remove the current value
      !isEmpty(currentInput[index]) &&
      event.currentTarget.value.indexOf(`${currentInput[index]}`) === 0
    ) {
      newValue = event.currentTarget.value.slice(1);
    } else if (
      // if the new value added before the current value, then remove the current value
      !isEmpty(currentInput[index]) &&
      event.currentTarget.value.indexOf(`${currentInput[index]}`) ===
        event.currentTarget.value.length - 1
    ) {
      newValue = event.currentTarget.value.slice(
        0,
        event.currentTarget.value.length - 1
      );
    } else {
      // Add the new value directly otherwise
      newValue = event.currentTarget.value;
    }

    if (newValue.length === 0) {
      currentInput[index] = null;

      if (index > 0) {
        this.getCodeBoxElement(index - 1).focus();
      }

      this.setState({ verificationInput: currentInput });
      return;
    }

    if (!isNaN(Number(newValue))) {
      let lastPosition = index;
      // should fill the current input and the following inputs
      // if the length of string is greater than 1
      for (let i = index; i < 4 && newValue.length > i - index; i += 1) {
        currentInput[i] = Number(newValue.slice(i - index, i - index + 1));
        lastPosition = i;
      }
      this.setState({ verificationInput: currentInput });
      if (lastPosition < 3) {
        this.getCodeBoxElement(lastPosition + 1).focus();
      } else {
        this.getCodeBoxElement(index).blur();
      }
    }
  }

  onKeyDown(
    index: 0 | 1 | 2 | 3,
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (
      event.keyCode === 8 &&
      !exist(this.state.verificationInput[index]) &&
      index > 0
    ) {
      this.getCodeBoxElement(index - 1).focus();
    }
  }

  /**
   * send request to the backend to verify the user and
   * update user context with new logged in user.
   * @param values  digits  values for phone verification.
   */
  onSubmitPhoneVerificationCode() {
    const userInput: PhoneVerificationInput = {
      verification_code: `${this.state.verificationInput.join("")}`,
      phone: this.context?.user?.phone
    };
    UserUtilities.verifyPhone(userInput)
      .then(user => {
        this.context?.updateUser(user);
        _CheckoutProgressEvent({ checkout_step: 3 });
        UserUtilities.removeTemporaryUser();
        this.props.history.push(ROUTES.Checkout.path);
        ReactPixel.track("CompleteRegistration");
      })
      .catch(() => {
        Toastr.options.positionClass = "toast-top-full-width";
        Toastr.error("Wrong verification code");
      });
  }

  // eslint-disable-next-line class-methods-use-this
  getCodeBoxElement(index: number): HTMLInputElement {
    return document.getElementById(`codeBox${index}`) as HTMLInputElement;
  }

  /**
   * @returns a flag which is true if the current input is valid
   */
  isInputValid(): boolean {
    const isValid =
      this.state.verificationInput.findIndex(v => {
        return !exist(v) || isNaN(v);
      }) === -1;

    return isValid;
  }

  render(): React.ReactNode {
    const tempUser = UserUtilities.getTemporaryUser();
    const currentUser = this.context.user ?? tempUser;

    return (
      <div className="verification">
        <div className="verification__img" />
        <h1 className="verification__header main-title primary-font">
          Phone verification
        </h1>
        <p className="verification__description">
          A verification code was sent to the number
        </p>
        <p className="verification__description verification__description--phone">
          {currentUser?.phone}
        </p>
        <div className="fields__wrapper">
          <div className="form-field">
            <input
              className={`digit-field${
                !exist(this.state.verificationInput[0]) ? "" : " active"
              }`}
              id="codeBox0"
              name="codeBox0"
              type="text"
              inputMode="numeric"
              value={this.state.verificationInput[0] ?? ""}
              onChange={e => {
                this.onChangeInput(0, e);
              }}
            />
          </div>

          <div className="form-field">
            <input
              className={`digit-field${
                !exist(this.state.verificationInput[1]) ? "" : " active"
              }`}
              id="codeBox1"
              name="codeBox1"
              inputMode="numeric"
              type="text"
              value={this.state.verificationInput[1] ?? ""}
              onChange={e => {
                this.onChangeInput(1, e);
              }}
              onKeyDown={e => {
                this.onKeyDown(1, e);
              }}
            />
          </div>

          <div className="form-field">
            <input
              className={`digit-field${
                !exist(this.state.verificationInput[2]) ? "" : " active"
              }`}
              id="codeBox2"
              name="codeBox2"
              type="text"
              inputMode="numeric"
              value={this.state.verificationInput[2] ?? ""}
              onChange={e => {
                this.onChangeInput(2, e);
              }}
              onKeyDown={e => {
                this.onKeyDown(2, e);
              }}
            />
          </div>

          <div className="form-field">
            <input
              className={`digit-field${
                !exist(this.state.verificationInput[3]) ? "" : " active"
              }`}
              id="codeBox3"
              name="codeBox3"
              type="number"
              inputMode="numeric"
              value={this.state.verificationInput[3] ?? ""}
              onChange={e => {
                this.onChangeInput(3, e);
              }}
              onKeyDown={e => {
                this.onKeyDown(3, e);
              }}
            />
          </div>
        </div>
        <div className="btn-wrapper">
          <button
            type="submit"
            disabled={!this.isInputValid()}
            className={
              !this.isInputValid()
                ? "verification__button button--primary primary-font button--disabled"
                : "verification__button button--primary primary-font"
            }
            onClick={this.onSubmitPhoneVerificationCode}
          >
            Verify & Proceed
          </button>
        </div>

        <p className="verification__description">
          Didn&apos;t receive the code?
          <span className="verification__description--resend">
            <button
              type="button"
              className="button--inline secondary-font"
              onClick={() => {
                const values = {
                  phone: currentUser.phone,
                  name: currentUser.name,
                  email: currentUser.email
                };
                UserUtilities.createUser(values)
                  .then(() => {
                    Toastr.success("Message sent successfully");
                    this.context?.updateUser(
                      {
                        ...values,
                        id: null
                      },
                      Date.now()
                    );
                    this.props.history.push(ROUTES.PhoneVerification.path);
                  })
                  .catch(errors => {
                    // User is trying to login/create multiple times without verifying with OTP.
                    console.error(errors);
                    Toastr.error(errors);
                    if (errors === ERRORS.createUser.codeAlreadySent) {
                      this.props.history.push(ROUTES.PhoneVerification.path);
                    }
                  });
              }}
            >
              Resend Code
            </button>
          </span>
        </p>
      </div>
    );
  }
}
PhoneVerification.contextType = USER_CONTEXT;
