/**
 * @author Ahmed Serag
 * @date 2020-07-06
 * @description list branch-menu items
 * @filename branch-menu-categories.tsx
 */
import React from "react";
import { RouteComponentProps } from "react-router-dom";

export class ScrollToTop extends React.Component<RouteComponentProps> {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}
