/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * @author Raghda Wessam
 * @date 2020-07-23
 * @description Price Breakdown Layout.
 * @filename price-item.tsx
 */
import { PromocodeType } from "interfaces/order";
import React from "react";
import { exist } from "utilities/common";

interface PriceItemProps {
  subTotal: number;
  tax: number;
  balance?: number;
  total: number;
  discount?: number;
  discount_percentage?: number;
  promocode?: string;
  promocodeType?: PromocodeType;
  promocodeDiscountAmount?: number;
  onRemovePromoCode?: () => void;
  campaignDiscountAmount?: number;
  specialDeliveryAmount?: number;
  subscriptionDiscount?: number;
}

/**
 * React component to render price breakdown.
 */
export class PriceItem extends React.Component<PriceItemProps> {
  constructor(props: PriceItemProps) {
    super(props);
    this.state = { isLoading: true };
  }

  render(): React.ReactNode {
    const {
      subTotal,
      tax,
      balance,
      total,
      discount,
      promocode,
      promocodeDiscountAmount,
      campaignDiscountAmount,
      specialDeliveryAmount,
      subscriptionDiscount
    } = this.props;
    const totalPrice = specialDeliveryAmount
      ? Number(total) + Number(specialDeliveryAmount)
      : Number(total);
    return (
      <div className="cart__price">
        <ul className="price-list general__list">
          <li className="price-list-item">
            <h2 className="price-title">Sub Total</h2>
            <p className="price-value item-price">
              {subTotal?.toFixed(2)} <span className="currency">EGP</span>
            </p>
          </li>
          {exist(campaignDiscountAmount) &&
            Number(campaignDiscountAmount) !== 0 && (
              <li className="price-list-item">
                <h2 className="price-title">Items Discount</h2>
                <p className="price-value item-price">
                  - {Number(campaignDiscountAmount)?.toFixed(2)}{" "}
                  <span className="currency">EGP</span>
                </p>
              </li>
            )}
          {exist(discount) && discount !== 0 && (
            <li className="price-list-item">
              <h2 className="price-title">Branch Discount</h2>
              <p className="price-value item-price">
                - {discount?.toFixed(2)} <span className="currency">EGP</span>
              </p>
            </li>
          )}
          {exist(subscriptionDiscount) && Number(subscriptionDiscount) > 0 && (
            <li className="price-list-item">
              <h2 className="price-title">Subscription Discount</h2>
              <p className="price-value item-price">
                - {Number(subscriptionDiscount)?.toFixed(2)}{" "}
                <span className="currency">EGP</span>
              </p>
            </li>
          )}
          <li className="price-list-item">
            <h2 className="price-title">Tax</h2>
            <p className="price-value item-price">
              {tax?.toFixed(2)} <span className="currency">EGP</span>
            </p>
          </li>
          {exist(specialDeliveryAmount) &&
            specialDeliveryAmount &&
            Number(specialDeliveryAmount) !== 0 && (
              <li className="price-list-item">
                <h2 className="price-title">Delivery Fee</h2>
                <p className="price-value item-price">
                  {Number(specialDeliveryAmount).toFixed(2)}{" "}
                  <span className="currency">EGP</span>
                </p>
              </li>
            )}
          {balance && (
            <li className="price-list-item">
              <h2 className="price-title">Balance Used</h2>
              <p className="price-value item-price">
                {balance?.toFixed(2)} <span className="currency">EGP</span>
              </p>
            </li>
          )}
          {promocode && (
            <li className="price-list-item promocode">
              <h2 className="price-title promocode">
                Promo code <span>{promocode}</span>
              </h2>
              <p className="price-value item-price">
                <>
                  {this.props.promocodeType === PromocodeType.discount ? (
                    <>
                      - {Number(promocodeDiscountAmount)?.toFixed(2)}{" "}
                      <span className="currency">EGP</span>
                    </>
                  ) : (
                    <span className="currency">{this.props.promocodeType}</span>
                  )}

                  {/* {this.props.onRemovePromoCode && (
                    <span
                      className="remove-code"
                      onClick={() => this.props.onRemovePromoCode()}
                    >
                      Remove code
                    </span>
                  )} */}
                </>
              </p>
            </li>
          )}
          <li className="price-list-item">
            <h2 className="price-title">Total</h2>
            <p className="price-value item-price">
              {totalPrice?.toFixed(2)} <span className="currency">EGP</span>
            </p>
          </li>
        </ul>
      </div>
    );
  }
}
