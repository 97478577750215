/**
 * @author Ahmed Serag
 * @date 2020-07-20
 * @description Implementation of Order related utilities.
 * @filename order.ts
 */
import * as Sentry from "@sentry/react";
import cloneDeep from "lodash.clonedeep";
import {
  Order as OrderInterface,
  OrderType,
  PaymentMethod
} from "interfaces/order";
import { Order as OrderAPI } from "api/order";
import { CreateOrderItemInput } from "interfaces/inputs/create-order-item-input";
import { Payload, ValidatePromocodeResponse } from "interfaces/payload";
import { differenceInHours } from "date-fns";
import orderBy from "lodash.orderby";
import {
  getPayloadData as _getPayloadData,
  handleError as _handleError
} from "./common";
import { purchaseEvent } from "./gtag-events";

/**
 * group of order helpers functionalities.
 */
export class Order {
  /**
   * Get Order Details based on order id
   */
  public static getOrderDetails(orderId: string): Promise<OrderInterface> {
    return OrderAPI.getOrderDetails(orderId)
      .then(orderPayload => {
        return _getPayloadData(orderPayload);
      })
      .catch(error => {
        const sentryEvent: Sentry.Event = {
          message: "Get Order details",
          environment: `${process.env.sentry_environment}`,
          contexts: {
            user: {
              token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY)
            },
            order: {
              id: orderId
            },
            error: {
              error: JSON.stringify(error)
            }
          }
        };
        Sentry.captureEvent(sentryEvent);
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * create order input and send register the order in the database.
   *
   * @param items a record of the selected items from the context.
   */
  public static createOrder(
    orderType: OrderType,
    items: Record<string, CreateOrderItemInput>,
    comment: string,
    branchId: string,
    tableNumber?: string,
    pickupDetails?: {
      pickupCarType?: string;
      pickupCarColor?: string;
      pickupETA?: string;
    },
    promocode?: string
  ): Promise<OrderInterface> {
    let updatedComment = comment;
    if (orderType === "pick-up") {
      updatedComment = Order.populatePickupComment(comment, pickupDetails);
    }
    return OrderAPI.createOrder(
      {
        comment: updatedComment,
        table_number: tableNumber,
        items: Order.getOrderItemsInput(items),
        promocode
      },
      branchId
    )
      .then(orderPayload => {
        return _getPayloadData(orderPayload);
      })
      .catch(error => Order.handleOrderValidationFailure(error, items));
  }

  /**
   * create order input and send register the order in the database.
   *
   * @param items a record of the selected items from the context.
   */
  public static updateOrder(
    orderType: OrderType,
    items: Record<string, CreateOrderItemInput>,
    comment: string,
    orderId: string,
    tableNumber?: string,
    pickupDetails?: {
      pickupCarType?: string;
      pickupCarColor?: string;
      pickupETA?: string;
    },
    additional_info?: { [index: string]: unknown },
    promocode?: string
  ): Promise<OrderInterface> {
    let updatedComment = comment;
    if (orderType === "pick-up") {
      updatedComment = Order.populatePickupComment(comment, pickupDetails);
    }

    return OrderAPI.updateOrder(
      {
        table_number: tableNumber,
        comment: updatedComment,
        items: Order.getOrderItemsInput(items),
        additional_info,
        promocode
      },
      orderId
    )
      .then(orderPayload => {
        return _getPayloadData(orderPayload);
      })
      .catch(error => Order.handleOrderValidationFailure(error, items));
  }

  public static updateOrderComment(
    comment: string,
    orderId: string
  ): Promise<OrderInterface> {
    return OrderAPI.updateOrderComment(comment, orderId)
      .then(orderPayload => {
        return _getPayloadData(orderPayload);
      })
      .catch(error => Promise.reject(_handleError(error)));
  }

  public static populatePickupComment(
    comment: string,
    pickupDetails: {
      pickupCarType?: string;
      pickupCarColor?: string;
      pickupETA?: string;
    }
  ): string {
    return `${comment}
    
    
    Pickup details:
          - Car Model: ${pickupDetails.pickupCarType}
          - Car Color: ${pickupDetails.pickupCarColor}
          - ETA: ${pickupDetails.pickupETA}
    `;
  }

  public static getOrderItemsInput(
    items: Record<string, CreateOrderItemInput>
  ): CreateOrderItemInput[] {
    const itemsKeys = Object.keys(items);
    const itemsInput: CreateOrderItemInput[] = itemsKeys.map(itemKey => {
      const input: CreateOrderItemInput = {
        id: items[itemKey].id,
        quantity: items[itemKey].quantity
      };

      if (items[itemKey].promocode_gift) {
        input.promocode_gift = items[itemKey].promocode_gift;
      }

      if (items[itemKey].size) {
        input.size = items[itemKey].size;
      }
      if (items[itemKey].extras) {
        input.extras = items[itemKey].extras;
      }
      if (items[itemKey].variants) {
        input.variants = items[itemKey].variants;
      }

      return input;
    });

    return itemsInput;
  }

  /**
   * update order items with it's availability based on order missing items.
   * @param response error from Order Related API calls
   * @param currentItems current items that needs to be validated.
   */
  public static handleOrderValidationFailure(
    response: Payload<{
      missing_products?: number[];
      missing_extras?: number[];
      missing_variants?: number[];
    }>,
    currentItems: Record<string, CreateOrderItemInput>
  ) {
    const updatedItems = cloneDeep(currentItems);
    const itemsKeys = Object.keys(currentItems);
    const missingItems: boolean =
      response?.data?.missing_products?.length > 0 ||
      response?.data?.missing_extras?.length > 0 ||
      response?.data?.missing_variants?.length > 0;

    if (response?.promocode?.length > 0) {
      return Promise.reject({ promocode: response?.promocode });
    }

    if (!missingItems) {
      return Promise.reject(_handleError(response));
    }

    if (response?.data.missing_products?.length > 0) {
      for (const key of itemsKeys) {
        if (response.data.missing_products.indexOf(currentItems[key].id) >= 0) {
          updatedItems[key].available = false;
        }
      }
    }
    // TODO handle missing extras and missing variants!
    return Promise.reject({ updatedItems });
  }

  /**
   * generate a payment token for the order.
   */
  public static GetOrderPaymentToken(
    orderId: string
  ): Promise<{
    token: string;
  }> {
    return OrderAPI.getOrderPaymentToken(orderId)
      .then(orderPayload => {
        return _getPayloadData(orderPayload);
      })
      .catch(error => {
        const sentryEvent: Sentry.Event = {
          message: "Get Order Payment token",
          environment: `${process.env.sentry_environment}`,
          contexts: {
            user: {
              token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY)
            },
            order: {
              id: orderId
            },
            error: {
              error: JSON.stringify(error)
            }
          }
        };
        Sentry.captureEvent(sentryEvent);
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * confirm order details
   *
   * @static
   * @param {string} orderId
   * @returns {Promise<OrderInterface>}
   * @memberof Order
   */
  public static confirmOrder(
    orderId: string,
    paymentMethod: PaymentMethod
  ): Promise<{ order: OrderInterface; token?: string }> {
    return OrderAPI.confirmOrder(orderId, paymentMethod)
      .then(orderPayload => {
        return _getPayloadData(orderPayload).then(order => {
          const orderData = order.order;
          purchaseEvent({
            transaction_id: orderData.id,
            affiliation: orderData.branch.name,
            value: orderData.total_price,
            tax: orderData.tax,
            shipping: 0,
            currency: "EGP",
            items: orderData.items.map(item => {
              return {
                id: `${item.id}`,
                price: item.price,
                quantity: item.quantity,
                name: item.product?.name
              };
            })
          });
          return order;
        });
      })
      .catch(error => {
        const sentryEvent: Sentry.Event = {
          message: "Confirm Order ",
          environment: `${process.env.sentry_environment}`,
          contexts: {
            user: {
              token: localStorage.getItem(process.env.ACCESS_TOKEN_KEY)
            },
            order: {
              id: orderId
            },
            error: {
              error: JSON.stringify(error)
            }
          }
        };
        Sentry.captureEvent(sentryEvent);
        return Promise.reject(_handleError(error));
      });
  }

  public static getStampPrice(): Promise<{ price: number }> {
    return OrderAPI.getStampPrice()
      .then(payload => {
        return _getPayloadData(payload);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  public static getStampPricePerBranch(
    branchId: number
  ): Promise<{ price: number }> {
    return OrderAPI.getStampPricePerBranch(branchId)
      .then(payload => {
        return _getPayloadData(payload);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  public static validatePromocode(
    code: string,
    branchId?: string,
    orderId?: string,
    productsIds?: { id: number; quantity: number }[]
  ): Promise<ValidatePromocodeResponse> {
    return OrderAPI.validatePromocode(code, branchId, orderId, productsIds)
      .then(payload => {
        return _getPayloadData(payload);
      })
      .catch(error => {
        return Promise.reject(_handleError(error));
      });
  }

  /**
   * populate all order related details from local-storage.
   */
  public static loadOrderDetailsFromLocalStorage(): {
    order: OrderInterface;
    items: Record<string, CreateOrderItemInput>;
    itemsPrice: number;
    comment: string;
    tableNumber: string;
  } {
    const comment = localStorage.getItem("order-comment");
    // TODO: Remove Order Table number
    const tableNumber = null;
    const orderString = localStorage.getItem("order");
    const itemsPrice = localStorage.getItem("items-price");
    const itemsKeys = localStorage.getItem("items-keys");
    const items: Record<string, CreateOrderItemInput> = {};

    if (itemsKeys) {
      for (const key of itemsKeys.split("&")) {
        const itemString = key ? localStorage.getItem(`item-${key}`) : null;
        if (itemString) {
          items[key] = JSON.parse(itemString);
        }
      }
    }

    return {
      comment,
      tableNumber,
      items,
      itemsPrice:
        itemsPrice && !Number.isNaN(itemsPrice) ? Number(itemsPrice) : null,
      order: orderString ? (JSON.parse(orderString) as OrderInterface) : null
    };
  }

  /**
   * add an already created item in local-storage as an active order.
   *
   * @param order add an order to local-storage.
   */
  public static addOrderToLocalStorage(order: OrderInterface) {
    localStorage.setItem("order", JSON.stringify(order));
  }

  /**
   * clear all local storage order related details including
   * *Order*, *comment*, *items*, *items-keys* and *items-price*
   */
  public static RemoveOrderDetailsFromLocalStorage() {
    const itemsKeys = (localStorage.getItem("items-keys") ?? "").split("&");

    localStorage.removeItem("order");
    localStorage.removeItem("comment");
    for (const key of itemsKeys) {
      localStorage.removeItem(`item-${key}`);
    }

    localStorage.removeItem("items-keys");
    localStorage.removeItem("items-price");
    localStorage.removeItem("order-comment");
  }

  /**
   * add/update an order item in local storage.
   *
   * @param key key of the order item in local-storage
   * @param item item to be added in local storage
   * @param newItemsPrice new items price resulted from adding the item.
   */
  public static updateOrderItemsInLocalStorage(
    newItems: Record<string, CreateOrderItemInput>,
    newItemsPrice: number
  ) {
    const currentItemsKeys = (localStorage.getItem("items-keys") ?? "").split(
      "&"
    );
    const removedItemsKeys = currentItemsKeys.filter(key => !(key in newItems));
    const newItemsKeys = Object.keys(newItems).join("&");

    for (const key of removedItemsKeys) {
      localStorage.removeItem(`item-${key}`);
    }

    for (const key in newItems) {
      localStorage.setItem(`item-${key}`, JSON.stringify(newItems[key]));
    }

    localStorage.setItem("items-keys", newItemsKeys);
    localStorage.setItem("items-price", `${newItemsPrice}`);
  }

  /**
   * remove a specific order item from the local-storage.
   *
   * @param key key of the item in local-storage
   * @param newItemsPrice new items price resulted from removing the item.
   */
  public static removeOrderItemFromLocalStorage(
    key: string,
    newItemsPrice: number
  ) {
    const itemsKeys = (localStorage.getItem("items-keys") ?? "").split("&");
    itemsKeys.splice(itemsKeys.indexOf(key), 1);
    localStorage.setItem("items-keys", itemsKeys.join("&"));
    localStorage.removeItem(`item-${key}`);
    localStorage.setItem("items-price", `${newItemsPrice}`);
  }

  /**
   * store order comment in local-storage
   * @param comment comment to be added to the order
   */
  public static addOrderCommentToLocalStorage(comment: string) {
    localStorage.setItem("order-comment", comment);
  }

  /**
   * store table number in local storage
   *
   * @static
   * @param {string} tableNumber
   * @memberof Order
   */
  public static addTableNumberToLocalStorage(tableNumber: string) {
    localStorage.setItem("table-number-timestamp", Date.now().toString());
    localStorage.setItem("order-table-number", tableNumber);
  }

  public static getTableNumberFromLocalStorage(): string {
    const tableNumberLastSaved = localStorage.getItem("table-number-timestamp");
    let tableNumber = localStorage.getItem("order-table-number");

    if (
      tableNumberLastSaved &&
      differenceInHours(Date.now(), Number(tableNumberLastSaved)) > 6
    ) {
      localStorage.removeItem("table-number-timestamp");
      localStorage.removeItem("order-table-number");
      tableNumber = null;
    }

    return tableNumber;
  }

  public static populateBOGOItems(
    items: Record<string, CreateOrderItemInput>
  ): Record<string, CreateOrderItemInput> {
    const updatedItems: Record<string, CreateOrderItemInput> = {};
    const updatedOrderItems: Record<string, CreateOrderItemInput[]> = {};

    for (const key of Object.keys(items)) {
      let newItems: CreateOrderItemInput[] = [items[key]];
      if (
        (items[key].product?.campaigns?.has_bogo && items[key].quantity > 1) ||
        items[key].promocode_bogo
      ) {
        newItems = [];
        for (let i = 0; i < items[key].quantity; i += 1) {
          newItems.push({
            ...items[key],
            quantity: 1,
            price: items[key].price / items[key].quantity
          });
        }
      }
      if (items[key].product?.id in updatedOrderItems) {
        updatedOrderItems[items[key].product?.id] = updatedOrderItems[
          items[key].product?.id
        ].concat(newItems);
      } else {
        updatedOrderItems[items[key].product?.id] = newItems;
      }
    }

    for (const key of Object.keys(updatedOrderItems)) {
      if (
        updatedOrderItems[key][0].product?.campaigns?.has_bogo ||
        updatedOrderItems[key][0].promocode_bogo
      ) {
        updatedOrderItems[key] = orderBy(
          updatedOrderItems[key],
          ["price"],
          ["desc"]
        );
      }

      for (let i = 0; i < updatedOrderItems[key].length; i += 1) {
        if (
          (updatedOrderItems[key][i].product?.campaigns?.has_bogo ||
            updatedOrderItems[key][i].promocode_bogo) &&
          i >= updatedOrderItems[key].length / 2
        ) {
          updatedOrderItems[key][i].is_free_gift = true;
        } else {
          updatedOrderItems[key][i].is_free_gift = false;
        }

        const updatedItemKey = Order.generateItemKey(
          updatedOrderItems[key][i],
          i
        );
        if (updatedOrderItems[key][i].is_free_gift !== true) {
          if (updatedItems[updatedItemKey]) {
            updatedItems[updatedItemKey] = {
              ...updatedItems[updatedItemKey],
              quantity:
                updatedItems[updatedItemKey].quantity +
                updatedOrderItems[key][i].quantity,
              price:
                updatedItems[updatedItemKey].price +
                updatedOrderItems[key][i].price
            };
          } else {
            updatedItems[updatedItemKey] = updatedOrderItems[key][i];
          }
        } else {
          updatedItems[updatedItemKey] = updatedOrderItems[key][i];
        }
      }
    }

    return updatedItems;
  }

  static generateItemKey(item: CreateOrderItemInput, index = 0): string {
    let itemKey = `${item.product?.id ?? item.id}`;

    if (item.extras) {
      itemKey += `-${item.extras.map(e => `${e.id},${e.quantity}`)}`;
    } else {
      itemKey += `-e`;
    }

    if (item.size) {
      itemKey += `-${item.size}`;
    } else {
      itemKey += `-s`;
    }

    if (item.variants) {
      itemKey += `-${item.variants.map(v => `${v}`)}`;
    } else {
      itemKey += `-v`;
    }

    if (item.promocode_gift || item.is_free_gift) {
      itemKey += `-g${index}`;
    }

    return itemKey;
  }
}
