/**
 * @author Ahmed Serag
 * @date 2020-07-20
 * @description implementation of Order related API calls.
 * @filename order.ts
 */
import { Payload, ValidatePromocodeResponse } from "interfaces/payload";
import {
  Order as OrderInterface,
  PaymentMethod,
  PaymentMethodCommand
} from "interfaces/order";
import { CreateOrderInput } from "interfaces/inputs/create-order-input";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

/**
 * implement Order related calls.
 */
export class Order {
  /**
   * fetch order details based on order id.
   */
  public static getOrderDetails(
    orderId: string
  ): Promise<Payload<OrderInterface>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getOrder.path(orderId)}`,
      { method: ENDPOINTS.getOrder.method }
    );
  }

  /**
   * create a new order in the database.
   */
  public static createOrder(
    input: CreateOrderInput,
    branchId: string
  ): Promise<Payload<OrderInterface>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.createOrder.path(branchId)}`,
      {
        method: ENDPOINTS.createOrder.method,
        body: JSON.stringify(input)
      }
    );
  }

  /**
   * update an already existing order with new items and comment.
   */
  public static updateOrder(
    input: CreateOrderInput,
    orderId: string
  ): Promise<Payload<OrderInterface>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.updateOrder.path(orderId)}`,
      {
        method: ENDPOINTS.updateOrder.method,
        body: JSON.stringify(input)
      }
    );
  }

  /**
   * update an already existing order with new items and comment.
   */
  public static updateOrderComment(
    comment: string,
    orderId: string
  ): Promise<Payload<OrderInterface>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.updateComment.path(orderId)}`,
      {
        method: ENDPOINTS.updateComment.method,
        body: JSON.stringify({ comment })
      }
    );
  }

  /**
   * create a payment token for the order to
   * call the payment 3rd party with it.
   */
  public static getOrderPaymentToken(
    orderId: string
  ): Promise<Payload<{ token: string }>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getOrderPaymentToken.path(
        orderId
      )}`,
      {
        method: ENDPOINTS.getOrderPaymentToken.method
      }
    );
  }

  /**
   * confirm already created order
   *
   * @static
   * @param {string} orderId id of previously created order
   * @returns {Promise<Payload<OrderInterface>>}
   * @memberof Order
   */
  public static confirmOrder(
    orderId: string,
    paymentMethod: PaymentMethod
  ): Promise<Payload<{ order: OrderInterface; token?: string }>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.confirmOrder.path(orderId)}`,
      {
        method: ENDPOINTS.confirmOrder.method,
        body: JSON.stringify({
          payment_method:
            paymentMethod === PaymentMethod.card
              ? PaymentMethodCommand.card
              : PaymentMethodCommand.cash
        })
      }
    );
  }

  /**
   *  get price at which the user pays to get a stamp.
   */
  public static getStampPrice(): Promise<Payload<{ price: number }>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getStampPrice.path}`,
      { method: ENDPOINTS.getStampPrice.method }
    );
  }

  /**
   *  get price at which the user pays to get a stamp per branch.
   */
  public static getStampPricePerBranch(
    branchId: number
  ): Promise<Payload<{ price: number }>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.getStampPricePerBranch.path(
        branchId
      )}`,
      { method: ENDPOINTS.getStampPrice.method }
    );
  }

  /**
   * Create an API request to validate the promocode.
   * @param code code to be validated
   * @param branch_id id of the branch to validate the promocode against
   * @param order_id order to validate its items
   * @param products_ids ids of the products to validate
   * @returns
   */
  public static validatePromocode(
    code: string,
    branch_id?: string,
    order_id?: string,
    products_ids?: { id: number; quantity: number }[]
  ): Promise<Payload<ValidatePromocodeResponse>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.ValidatePromocode.path}`,
      {
        method: ENDPOINTS.ValidatePromocode.method,
        body: JSON.stringify({
          promocode: code,
          branch_id,
          order_id,
          products_ids
        })
      }
    );
  }
}
