/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * @author Ahmed Serag
 * @date 2021-06-01
 * @description promocode component
 * @filename promocode.tsx
 */
import { MENU_CONTEXT } from "contexts/menu-context";
import { ORDER_CONTEXT } from "contexts/order-context";
import React from "react";
import { exist, isEmpty } from "utilities/common";
import { Order as OrderUtilities } from "utilities/order";
import Toastr from "toastr";
import { PromocodeType } from "interfaces/order";
import { RouteComponentProps } from "react-router-dom";
import { ROUTES } from "consts/routes";

interface PromocodeState {
  code: string;
  errorMessage?: string;
  loading?: boolean;
}

/**
 * React component to render promocode input.
 */
export class Promocode extends React.Component<
  RouteComponentProps,
  PromocodeState
> {
  declare context: React.ContextType<typeof ORDER_CONTEXT>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      code: "",
      loading: false
    };
    this.validatePromocode = this.validatePromocode.bind(this);
    this.onSubmitPromocode = this.onSubmitPromocode.bind(this);
  }

  componentDidMount() {
    if (isEmpty(this.context.order?.promocode)) {
      return;
    }
    const promise = new Promise(resolve => {
      this.setState(
        {
          code: this.context.order?.promocode
        },
        () => {
          resolve(this.validatePromocode(this.context.order?.branch_id));
        }
      );
    });

    promise.catch(error => {
      Toastr.error(error);
      this.context.updateOrderPromocode(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );
    });
  }

  onSubmitPromocode(branchId: string) {
    this.setState({ loading: true });
    this.validatePromocode(branchId)
      .then(() => {
        this.setState({ loading: false });
        if (this.context.promocodeType === PromocodeType.freeGift) {
          this.props.history.push(ROUTES.FreeGift.path);
        }
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  validatePromocode(branchId: string) {
    const itemsIds: { id: number; quantity: number }[] = Object.keys(
      this.context.items
    ).map(item => {
      // TODO: remove quantity from API calls as it doesn't make sense having it there.
      return {
        id: this.context.items[item].id,
        quantity: this.context.items[item].quantity
      };
    });
    const code: string = this.state.code;
    let promise: Promise<unknown> = Promise.resolve();

    if (!isEmpty(this.state.code)) {
      promise = OrderUtilities.validatePromocode(
        code,
        branchId,
        this.context.order?.id,
        itemsIds
      )
        .then(promocodeDetails => {
          const promises: Promise<void>[] = [];
          if (
            promocodeDetails?.promocode_order_min_amount &&
            Number(promocodeDetails?.promocode_order_min_amount) >
              Number(this.context.order?.total_price)
          ) {
            return Promise.reject(
              `Promo-code is applicable when cart size is ${promocodeDetails?.promocode_order_min_amount} EGP or above.`
            );
          }

          if (promocodeDetails.code_type === PromocodeType.bogo) {
            if (isEmpty(promocodeDetails.eligible_products)) {
              Toastr.error(
                "You need to add another eligible item to apply the promocode"
              );
            }

            let shouldAddAnotherItem = false;

            for (const item of Object.keys(this.context.items)) {
              if (this.context.items[item].quantity < 2) {
                shouldAddAnotherItem = true;
              }
              if (
                promocodeDetails.eligible_products.includes(
                  this.context.items[item].id
                )
              ) {
                promises.push(
                  this.context.updateItem(
                    {
                      ...this.context.items[item],
                      promocode_bogo: true
                    },
                    item
                  )
                );
              }
            }

            if (shouldAddAnotherItem) {
              Toastr.error(
                "You need to add another eligible item to apply the promocode"
              );
            }
          }
          return Promise.all(promises).then(() => {
            return this.context.updateOrderPromocode(
              code,
              promocodeDetails.discount_amount,
              promocodeDetails.percentage,
              promocodeDetails.code_type,
              promocodeDetails.free_gift_products
            );
          });
        })
        .catch(errorMessage => {
          this.setState({ errorMessage });
          this.context.updateOrderPromocode(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
          );
          return Promise.reject(errorMessage);
        });
    }
    return promise.then(() => {
      this.setState({ errorMessage: undefined });
    });
  }

  render(): React.ReactNode {
    return (
      <div className="promocode-wrapper">
        <h3 className="title secondary-font">Add Promo Code</h3>
        <div
          className={`promocode-input-wrapper${
            this.state.errorMessage
              ? " error"
              : this.context.order?.promocode
              ? " success"
              : ""
          }`}
        >
          <MENU_CONTEXT.Consumer>
            {menuValue => {
              return (
                <>
                  <input
                    placeholder="add promocode"
                    type="text"
                    disabled={
                      this.context.loadingOrder ||
                      exist(this.context.order?.promocode)
                    }
                    className="promocode-input"
                    defaultValue={this.context.order?.promocode ?? ""}
                    onChange={e => {
                      this.setState({
                        code: e.currentTarget.value,
                        errorMessage: null
                      });
                    }}
                  />
                  {this.state.errorMessage ||
                  this.context.order?.promocode ? null : (
                    <button
                      className={
                        isEmpty(this.state.code) || this.state.loading
                          ? "promocode-apply-btn promocode-apply-btn--disabled"
                          : "promocode-apply-btn"
                      }
                      type="button"
                      disabled={isEmpty(this.state.code) || this.state.loading}
                      onClick={() => {
                        this.onSubmitPromocode(`${menuValue.branch.id}`);
                      }}
                    >
                      Apply
                    </button>
                  )}
                </>
              );
            }}
          </MENU_CONTEXT.Consumer>
        </div>
        {this.state.errorMessage ? (
          <div className="promocode-error">{this.state.errorMessage}</div>
        ) : this.context.order?.promocode ? (
          <div className="promocode-success">
            Code applied successfully
            <span
              className="remove-code"
              onClick={() => {
                this.context.updateOrderPromocode(
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  undefined
                );
              }}
            >
              Remove code
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

Promocode.contextType = ORDER_CONTEXT;
