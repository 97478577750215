/**
 * @author Ahmed Serag
 * @date 2020-07-07
 * @description implementation of Product related API calls.
 * @filename product.ts
 */
import { Payload } from "interfaces/payload";
import { ProductCustomization } from "interfaces/product";
import { Network } from "./network";
import { ENDPOINTS } from "./endpoints";

/**
 * implement Product related calls.
 */
export class Product {
  /**
   *  get product available customizations.
   */
  public static getProductCustomizations(
    branchId: string,
    productId: string
  ): Promise<Payload<ProductCustomization>> {
    return Network.fetch(
      `${process.env.API_ENDPOINT}${ENDPOINTS.GetProductCustomization.path(
        branchId,
        productId
      )}`,
      { method: ENDPOINTS.listBranchMenu.method }
    );
  }
}
