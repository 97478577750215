/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * @author Ahmed Serag
 * @date 2020-10-18
 * @description choose payment Layout of the App.
 * @filename payment.tsx
 */
import React from "react";
import * as Sentry from "@sentry/react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import Toastr from "toastr";
import { ROUTES } from "consts/routes";
import { ORDER_CONTEXT } from "contexts/order-context";
import { USER_CONTEXT } from "contexts/user-context";
import { exist as _exist, getParamFromSearchUrl } from "utilities/common";
import { Order as OrderUtilities } from "utilities/order";
import {
  Order as OrderInterface,
  OrderStatus,
  PaymentMethod
} from "interfaces/order";
import CARD_PAYMENT from "static/images/icons/master-card.png";
import CASH_PAYMENT from "static/images/icons/cash.svg";
import { ERRORS } from "consts/errors";
import { SetCheckoutOptionEvent as _SetCheckoutOptionEvent } from "utilities/gtag-events";
import { MENU_CONTEXT } from "contexts/menu-context";
import { Field, Form, Formik, FormikProps } from "formik";
import * as YUP from "yup";
import { Maps } from "utilities/maps";
import PAYMENT_ILLUSTRATION from "static/images/illustrations/payment-illustration.svg";
import ReactPixel from "react-facebook-pixel";
import { PriceItem } from "../../common/price-item";
import Loader from "../../common/loader";

interface CheckoutState {
  confirmingOrder?: boolean;
  paymentMethod?: PaymentMethod;
  loading: boolean;
  specialDelivery?: boolean;
  location?: GeolocationPosition;
  map?: google.maps.Map;
  locationMarker?: google.maps.Marker;
}

/**
 * React component to render Payment layout
 */
export class Payment extends React.Component<
  RouteComponentProps,
  CheckoutState
> {
  declare context: React.ContextType<typeof USER_CONTEXT>;

  formikRef?: React.RefObject<FormikProps<any>>;

  watchPositionId?: number;

  mapRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      loading: false,
      specialDelivery: true,
      paymentMethod: PaymentMethod.cash
    };
    this.formikRef = React.createRef<FormikProps<any>>();
    this.mapRef = React.createRef();

    this.onConfirmOrder = this.onConfirmOrder.bind(this);
    this.onShareLocation = this.onShareLocation.bind(this);
    this.updateMapLocation = this.updateMapLocation.bind(this);
  }

  componentDidMount() {
    const paymentSuccessParams = getParamFromSearchUrl(
      "success",
      this.props.location.search
    );

    if (
      paymentSuccessParams?.length > 0 &&
      paymentSuccessParams[0] !== "true"
    ) {
      Toastr.error("Failed to process payment!");
    }
    // load google maps script
    Maps.load();
  }

  componentWillUnmount() {
    if (this.watchPositionId) {
      navigator.geolocation.clearWatch(this.watchPositionId);
    }
  }

  onConfirmOrder(
    order: OrderInterface,
    confirmOrderContext: (
      paymentMethod: PaymentMethod,
      additionalInfo?: { [index: string]: unknown }
    ) => Promise<{
      order: OrderInterface;
      token?: string;
    }>
  ) {
    if (!_exist(this.state.paymentMethod)) {
      Toastr.error("No Payment Method selected");
      return;
    }
    _SetCheckoutOptionEvent({
      checkout_step: 5,
      checkout_option: this.state.paymentMethod
    });
    let additionalInfo: { [index: string]: any };

    if (this.state.specialDelivery && this.formikRef?.current) {
      if (!this.formikRef?.current?.isValid && !this.state.location) {
        Toastr.error("Special Delivery Items missing!");
        return;
      }

      // if (
      //   _exist(this.formikRef.current.values?.location) &&
      //   !this.state.location
      // ) {
      //   Toastr.error("Location is required!");
      //   return;
      // }

      additionalInfo = this.formikRef.current.values;

      if (this.state.location) {
        additionalInfo["location"] = {
          latitude: this.state.location.coords?.latitude,
          longitude: this.state.location.coords?.longitude
        };
      }
    }

    this.setState({ confirmingOrder: true, loading: true });
    confirmOrderContext(this.state.paymentMethod, additionalInfo)
      .then(payload => {
        const newOrder = payload.order;
        ReactPixel.track("Purchase", {
          TotalPrice: newOrder.total_price,
          value: newOrder.price,
          currency: "EGP",
          PromocodeDiscountAmount: newOrder.promocode_discount_amount,
          campaignDiscountAmount: newOrder.campaign_discount_amount,
          OrderDiscountAmount: newOrder.discount,
          SubTotal: newOrder.price,
          Tax: newOrder.tax,
          HasGift: newOrder.gift,
          PaymentMethod: this.state.paymentMethod
        });

        if (_exist(payload.token)) {
          window.location.assign(
            `${process.env.ACCEPT_IFRAME_URL}${payload.token}`
          );
          return;
        }
        OrderUtilities.RemoveOrderDetailsFromLocalStorage();
        this.context.refreshUser();
        if (_exist(newOrder, ["gift"]) && newOrder.gift > -1) {
          this.props.history.push(ROUTES.FreeGift.path);
        } else {
          this.props.history.push(ROUTES.Confirmation.getPath(newOrder.id));
        }
      })
      .catch(error => {
        console.error(error);
        Toastr.error(ERRORS.unexpected);
        // this.props.history.push(ROUTES.error.path);
        this.setState({ confirmingOrder: false, loading: false });
      });
  }

  onShareLocation() {
    if (this.watchPositionId) {
      return;
    }

    if (navigator.geolocation) {
      this.watchPositionId = navigator.geolocation.watchPosition(
        location => {
          this.setState({ location });
        },
        e => {
          console.error("watchPosition", e);
          const sentryEvent: Sentry.Event = {
            message: "Share location",
            environment: `${process.env.sentry_environment}`,
            contexts: {
              error: {
                message: e.message,
                code: e.code
              }
            }
          };
          Sentry.captureEvent(sentryEvent);
          let errorMessage = e?.message ?? e;
          if (errorMessage === "User denied Geolocation") {
            errorMessage = "Please enable location access";
          }

          Toastr.error(errorMessage);
          if (this.watchPositionId) {
            navigator.geolocation.clearWatch(this.watchPositionId);
            this.watchPositionId = undefined;
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 60 * 1000
        }
      );
    } else {
      Toastr.error("Browser doesn't support geolocation");
      const sentryEvent: Sentry.Event = {
        message: "Share location",
        environment: `${process.env.sentry_environment}`,
        contexts: {
          error: {
            error: "Browser doesn't support geolocation"
          }
        }
      };
      Sentry.captureEvent(sentryEvent);
    }
  }

  updateMapLocation() {
    let promise: Promise<unknown> = Promise.resolve();

    if (!this.state.map) {
      promise = Maps.init(this.mapRef).then(map => {
        return new Promise<void>(resolve => {
          this.setState({ map }, () => resolve());
        });
      });
    }

    if (!this.state.locationMarker) {
      promise = promise.then(() => {
        const locationMarker = Maps.addMarkerToPosition(this.state.map, {
          lat: this.state.location.coords.latitude,
          lng: this.state.location.coords.longitude
        });
        return new Promise<void>(resolve => {
          this.setState({ locationMarker }, () => resolve());
        });
      });
    }

    promise.then(() => {
      this.state.locationMarker.setPosition({
        lat: this.state.location.coords.latitude,
        lng: this.state.location.coords.longitude
      });
    });
  }

  render(): React.ReactNode {
    const paymentSuccessParams = getParamFromSearchUrl(
      "success",
      this.props.location.search
    );
    let paymentSuccess: boolean;

    if (paymentSuccessParams?.length > 0) {
      paymentSuccess =
        paymentSuccessParams && paymentSuccessParams[0] === "true";
    }

    return (
      <ORDER_CONTEXT.Consumer>
        {value => {
          const subtotalPrice = value.order?.price;
          const discount = value.order?.discount;
          const discountPercentage = value.order?.discount_percentage;
          const taxPrice = value.order?.tax;
          const totalPrice = value.order?.total_price;
          const branchDeliveryFee = value.order?.branch?.special_delivery_price;
          const branchId = value.order?.branch?.id;
          if (
            value.loadingOrder ||
            this.context.isLoading ||
            this.state.loading
          ) {
            return <Loader />;
          }

          // check if the order is processed and redirect it to confirmation page
          if (value.order?.state === OrderStatus.received && paymentSuccess) {
            OrderUtilities.RemoveOrderDetailsFromLocalStorage();
            if (_exist(value.order, ["gift"]) && value.order.gift > -1) {
              return <Redirect to={ROUTES.FreeGift.path} />;
            }
            return (
              <Redirect to={ROUTES.Confirmation.getPath(value?.order?.id)} />
            );
          }

          if (
            value.order?.state !== OrderStatus.drafted &&
            !this.state.confirmingOrder
          ) {
            return <Redirect to={ROUTES.SelectBranch.path} />;
          }

          if (!_exist(this.context?.user?.id)) {
            return <Redirect to={ROUTES.Checkout.path} />;
          }

          return (
            <div className="payment cart__container checkout__container">
              <div className="cart__header">
                <button
                  className="button secondary-font"
                  type="button"
                  onClick={() => {
                    this.props.history.push(ROUTES.Checkout.path);
                  }}
                >
                  Back
                </button>
                <div className="main-title--wrapper">
                  <h1 className="main-title primary-font">Payment</h1>
                  <img
                    className="main-title--illustration"
                    src={PAYMENT_ILLUSTRATION}
                    alt=""
                  />
                </div>
              </div>
              <div className="cart__items">
                <MENU_CONTEXT.Consumer>
                  {menuValue => {
                    if (!menuValue.branch?.is_special_delivery) {
                      return <></>;
                    }
                    const fieldsNames =
                      menuValue.branch?.special_delivery_info?.split(",") ?? [];

                    return (
                      <>
                        <div className="cart__items-header">
                          <h2 className="title primary-font">Pickup Method</h2>
                        </div>
                        <div className="radio__wrapper pickup-methods">
                          <div
                            className={`pickup-method-option-wrapper ${
                              this.state.specialDelivery ? "selected" : ""
                            }`}
                            onClick={() => {
                              if (!this.state.specialDelivery) {
                                this.setState({ specialDelivery: true });
                              }
                            }}
                          >
                            <div
                              className={`pickup-method-option ${
                                this.state.specialDelivery ? "selected" : ""
                              }`}
                            >
                              {menuValue.branch?.special_delivery_title}
                            </div>
                          </div>
                          <div
                            className={`pickup-method-option-wrapper ${
                              !this.state.specialDelivery ? "selected" : ""
                            }`}
                            onClick={() => {
                              if (this.state.specialDelivery) {
                                this.setState({ specialDelivery: false });
                              }
                            }}
                          >
                            <div
                              className={`pickup-method-option ${
                                !this.state.specialDelivery ? "selected" : ""
                              }`}
                            >
                              Store Pickup
                            </div>
                          </div>
                        </div>
                        {this.state.specialDelivery && (
                          <div className="special-delivery-inputs">
                            <p className="description subtitle-payment secondary-font">
                              {menuValue.branch?.special_delivery_description}
                            </p>
                            <Formik
                              innerRef={this.formikRef}
                              initialValues={fieldsNames.reduce((prev, cur) => {
                                const key = cur;
                                if (
                                  key.toLowerCase().trim() === "table number" &&
                                  OrderUtilities.getTableNumberFromLocalStorage()
                                ) {
                                  return {
                                    ...prev,
                                    [cur]: OrderUtilities.getTableNumberFromLocalStorage()
                                  };
                                }
                                if (cur.toLowerCase().trim() === "location") {
                                  return {
                                    ...prev,
                                    location: ""
                                  };
                                }
                                return {
                                  ...prev,
                                  [cur]: ""
                                };
                              }, {})}
                              onSubmit={values => {
                                console.log(values);
                              }}
                              validationSchema={(() => {
                                const locationAttr = fieldsNames.find(
                                  cur => cur.toLowerCase().trim() === "location"
                                );
                                const validationObject = fieldsNames.reduce(
                                  (prev, cur) => {
                                    let validation: YUP.StringSchema<string>;

                                    if (locationAttr) {
                                      validation = YUP.string().nullable();
                                    } else {
                                      validation = YUP.string().required();
                                    }

                                    if (
                                      cur.toLowerCase().trim() === "location"
                                    ) {
                                      return {
                                        ...prev,
                                        location: validation
                                      };
                                    }

                                    return {
                                      ...prev,
                                      [cur]: validation
                                    };
                                  },
                                  {}
                                );
                                return YUP.object().shape(validationObject);
                              })()}
                            >
                              {() => {
                                return (
                                  <Form>
                                    {fieldsNames.map((fieldName, index) => {
                                      if (
                                        fieldName.toLowerCase().trim() ===
                                        "location"
                                      ) {
                                        return (
                                          <div className="location-field-wrapper">
                                            <div className="location-field-label">
                                              Share location to find you easier
                                              {this.state.location && (
                                                <span className="location-shared">
                                                  <svg
                                                    width="21px"
                                                    height="21px"
                                                    viewBox="0 0 21 21"
                                                    version="1.1"
                                                  >
                                                    <g
                                                      id="Beach-ordering"
                                                      stroke="none"
                                                      strokeWidth="1"
                                                      fill="none"
                                                      fillRule="evenodd"
                                                    >
                                                      <g
                                                        id="beach-order-2/03"
                                                        transform="translate(-33.000000, -634.000000)"
                                                      >
                                                        <g
                                                          id="check/filled"
                                                          transform="translate(33.000000, 634.000000)"
                                                        >
                                                          <path
                                                            d="M9.6,20.2590107 C16.9694428,21.6321802 19.2,14.7814229 19.2,10.3014229 C19.2,5.82142292 15.2051775,3.25997753 9.95280603,0.701422916 C4.70043457,-1.8571317 0,2.86493493 0,10.3014229 C0,17.7379109 2.2305572,18.8858411 9.6,20.2590107 Z"
                                                            id="Oval-Copy-2"
                                                            fill="#BF5627"
                                                          />
                                                          <path
                                                            d="M7.6958199,8.74353239 C6.87733579,11.3821261 6.87733579,12.7014229 7.6958199,12.7014229 C8.92354607,12.7014229 16.7901068,1.7965186 19.6781816,1.7965186"
                                                            id="Path-2"
                                                            stroke="#FFF6DF"
                                                            stroke-Width="2.2"
                                                            strokeLinecap="round"
                                                          />
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </svg>
                                                  Location shared
                                                </span>
                                              )}
                                            </div>
                                            <div
                                              className="location-field"
                                              onClick={this.onShareLocation}
                                            >
                                              Share location
                                            </div>
                                          </div>
                                        );
                                      }

                                      return (
                                        <Field name={fieldName} key={index}>
                                          {({ field, meta }) => (
                                            <div className="form-field">
                                              <label htmlFor="floor-number">
                                                {fieldName}
                                              </label>
                                              <input
                                                id="floor-number"
                                                name="floor-number"
                                                type="text"
                                                placeholder={fieldName}
                                                autoComplete="off"
                                                {...field}
                                              />
                                              {meta.touched && meta.error && (
                                                <div className="error">
                                                  {meta.error}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </Field>
                                      );
                                    })}
                                  </Form>
                                );
                              }}
                            </Formik>
                          </div>
                        )}
                      </>
                    );
                  }}
                </MENU_CONTEXT.Consumer>
                {/* <div
                  className="map-container"
                  ref={this.mapRef}
                  style={{
                    height:
                      this.state.specialDelivery &&
                      this.state.location &&
                      this.state.map
                        ? "170px"
                        : "0",
                    margin: "30px"
                  }}
                /> */}
                <div className="cart__payment-method-header">
                  <h2 className="title primary-font">Select Payment Method</h2>
                </div>
                <div className="cart__content">
                  <div className="radio__wrapper">
                    {value.order?.total_price + Number(branchDeliveryFee) >
                    0 ? (
                      <>
                        {/* Card payment */}
                        <div
                          className={`radio__item-wrapper ${
                            this.state.paymentMethod === PaymentMethod.card
                              ? "radio__item-wrapper-selected"
                              : ""
                          }`}
                        >
                          <div
                            className={`radio__item ${
                              this.state.paymentMethod === PaymentMethod.card
                                ? "radio__item-selected"
                                : ""
                            }`}
                            onClick={e => {
                              e.preventDefault();
                              if (
                                this.state.paymentMethod !== PaymentMethod.card
                              ) {
                                this.setState({
                                  paymentMethod: PaymentMethod.card
                                });
                              }
                            }}
                          >
                            <img src={CARD_PAYMENT} alt="Card payment" />
                            <span>Card Payment</span>
                          </div>
                        </div>
                        {/* cash payment */}
                        {branchId !== 84 && (
                          <>
                            <div
                              className={`radio__item-wrapper ${
                                this.state.paymentMethod === PaymentMethod.cash
                                  ? "radio__item-wrapper-selected"
                                  : ""
                              }`}
                            >
                              <div
                                className={`radio__item ${
                                  this.state.paymentMethod ===
                                  PaymentMethod.cash
                                    ? "radio__item-selected"
                                    : ""
                                }`}
                                onClick={e => {
                                  e.preventDefault();
                                  if (
                                    this.state.paymentMethod !==
                                    PaymentMethod.cash
                                  ) {
                                    this.setState({
                                      paymentMethod: PaymentMethod.cash
                                    });
                                  }
                                }}
                              >
                                <img src={CASH_PAYMENT} alt="Cash payment" />
                                <span>Cash</span>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="radio__item-wrapper radio__item-wrapper-selected">
                        <div
                          className="radio__item radio__item-selected"
                          onClick={e => {
                            e.preventDefault();

                            this.setState({
                              paymentMethod: PaymentMethod.cash
                            });
                          }}
                        >
                          <img src={CASH_PAYMENT} alt="Cash payment" />
                          <span>Redeem</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {this.context.user?.card_masked &&
                    this.state.paymentMethod === PaymentMethod.card && (
                      <div className="info__wrapper">
                        <div className="info__item">
                          <label className="info-title" htmlFor="phone">
                            Card number
                          </label>
                          <p className="info-value info-value--edit" id="phone">
                            {this.context.user.card_masked ?? "XXX XXXX 3924"}
                            <button
                              type="button"
                              className="button--inline secondary-font"
                              onClick={() => {
                                this.context.removeCard();
                              }}
                            >
                              Remove Card
                            </button>
                          </p>
                        </div>
                        <div className="info__item">
                          <label className="info-title" htmlFor="name">
                            Card type
                          </label>
                          <p className="info-value" id="name">
                            {this.context.user.card_subtype}
                          </p>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {this.context.user?.id && (
                <>
                  <PriceItem
                    subTotal={subtotalPrice}
                    subscriptionDiscount={value.order?.subscription_discount}
                    tax={taxPrice}
                    total={totalPrice}
                    discount={discount}
                    discount_percentage={discountPercentage}
                    promocode={value.order?.promocode}
                    promocodeType={value.promocodeType}
                    specialDeliveryAmount={
                      this.state.specialDelivery && branchDeliveryFee
                    }
                    promocodeDiscountAmount={
                      value.order?.promocode_discount_amount
                        ? Number(value.order.promocode_discount_amount)
                        : undefined
                    }
                  />
                  <div className="button__wrapper">
                    <button
                      className={`button--primary button--fixed primary-font ${
                        value.loadingOrder ? "button--loading " : ""
                      } ${this.state.paymentMethod ? "" : "button--disabled"}`}
                      type="button"
                      disabled={!this.state.paymentMethod}
                      onClick={() => {
                        this.onConfirmOrder(value.order, value.confirmOrder);
                      }}
                    >
                      {!value.loadingOrder && (
                        <div className="button-content">Place Order</div>
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          );
        }}
      </ORDER_CONTEXT.Consumer>
    );
  }
}
Payment.contextType = USER_CONTEXT;
