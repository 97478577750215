/**
 * @author Ahmed Serag
 * @date 2020-07-19
 * @description implementation of Order interface.
 * @filename order.ts
 */

import {
  ProductSize,
  ProductVariantOption,
  ProductExtra,
  Product
} from "./product";
import { Branch } from "./branch";

export interface Order {
  id: string;
  items: OrderItem[];
  state: OrderStatus;
  price: number;
  payment_method: string;
  tax: number;
  total_price: number;
  branch_id: string;
  /**
   * level of the gift the order eligible for.
   */
  gift?: number;
  created_at?: string;
  branch?: Branch;
  serial?: number;
  discount?: number;
  discount_percentage?: number;
  additional_info?: { [index: string]: any };
  comment?: string;
  promocode?: string;
  promocode_discount_amount?: number;
  campaign_discount_amount?: number;
  special_delivery_price?: boolean;
  subscription_discount?: number;
  has_subscription_item?: boolean;
}

export type OrderType = "in-store" | "pick-up";

export interface OrderItemExtra extends ProductExtra {
  quantity: number;
}

export interface OrderItem {
  id: number;
  product: Product;
  price: number;
  quantity: number;
  size: ProductSize;
  variants?: ProductVariantOption[];
  extras?: OrderItemExtra[];
  branch_discount_amount?: number;
  campaign_discount_amount?: number;
  campaign_percentage?: number;
  tax?: number;
  is_free_gift?: boolean;
}

export interface OrderPrice {
  subTotal: string;
  vat: string;
  tax: string;
  total: string;
}

export enum OrderStatus {
  prepared = "prepared",
  drafted = "draft",
  received = "received",
  picked = "picked",
  canceled = "canceled",
  confirmed = "confirmed"
}

export enum PaymentMethod {
  card = "creditCard",
  cash = "cash"
}

export enum PaymentMethodCommand {
  card = "CARD",
  cash = "CASH"
}

export enum PromocodeType {
  discount = "discount",
  freeGift = "free_gift",
  bogo = "bogo"
}
