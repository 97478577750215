/**
 * @author Ahmed Elshantaly
 * @date 2020-07-21
 * @description list of categories
 * @filename category-selector.tsx
 */
import React from "react";
import { Category, SubCategory } from "interfaces/category";
import categoryBorderImage from "../../../../static/images/sub-categories/sub-category.svg";

/**
 * React component to render list of categories.
 */

type CategoryProps = {
  availableCategories: (Category | SubCategory)[];
  categoryId: number;
  categoryHandler: (categoryId: number) => void;
  type: "category" | "subCategory";
};
export class CategorySelector extends React.Component<CategoryProps> {
  constructor(props: CategoryProps) {
    super(props);
    this.state = { activeSubCategory: 0 };
    this.handleSubCategoryClick = this.handleSubCategoryClick.bind(this);
  }

  handleSubCategoryClick(index) {
    this.setState({
      activeSubCategory: index
    });
  }

  render(): React.ReactNode {
    return (
      <section className="category-selector__section">
        <ul className="category-selector__list">
          {this.props.availableCategories.map(
            (category: Category | SubCategory, index) => {
              return (
                <li
                  style={{
                    backgroundImage: `url( ${
                      this.props.type === "subCategory"
                        ? categoryBorderImage
                        : category.image
                    })`,
                    borderRadius: "22px 15px 28px 27px",
                    backgroundColor:
                      category.id === this.props.categoryId && "#FFCD00"
                  }}
                  onClick={() => {
                    if (this.props.type === "subCategory") {
                      this.handleSubCategoryClick(index);
                      this.props.categoryHandler(
                        +this.props.availableCategories[index].id
                      );
                    } else {
                      this.props.categoryHandler(category.id);
                    }
                  }}
                  key={category.id}
                  className="category-selector__list-item"
                >
                  <div
                    className="category-selector__list-item-data"
                    style={{
                      fontSize: `${this.props.type === "subCategory" &&
                        "14px"}`,
                      padding: `${this.props.type === "subCategory" &&
                        "0 15px"}`
                    }}
                  >
                    {category.name}
                  </div>
                </li>
              );
            }
          )}
        </ul>
      </section>
    );
  }
}
