/**
 * @author Raghda Wessam
 * @date 2020-07-23
 * @description Thank You page.
 * @filename thank-you.tsx
 */
import React from "react";

/**
 * React component to render thank you page.
 */
export class ThankYou extends React.Component {
  render(): React.ReactNode {
    return (
      <div className="verification">
        <div className="verification__img" />
        <h1 className="verification__header main-title primary-font">
          Thank You
        </h1>
        <p className="verification__description">
          Thank you for choosing Cilantro. Enjoy you order!
        </p>
      </div>
    );
  }
}
