/**
 * @author Ahmed Serag
 * @date 2020-08-06
 * @description Error page.
 * @filename error.tsx
 */
import { ROUTES } from "consts/routes";
import { ORDER_CONTEXT } from "contexts/order-context";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

/**
 * React component to render Error page.
 */
export class Error extends React.Component<RouteComponentProps> {
  declare context: React.ContextType<typeof ORDER_CONTEXT>;

  constructor(props: RouteComponentProps) {
    super(props);

    this.onGoBack = this.onGoBack.bind(this);
  }

  onGoBack(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    this.context.clearOrder().finally(() => {
      this.props.history.push(ROUTES.BranchMenu.path);
    });
  }

  render(): React.ReactNode {
    return (
      <div className="verification">
        <div className="verification__img" />
        <h1 className="verification__header main-title primary-font">
          Something went wrong
        </h1>
        <p className="verification__description">
          Seems like something went wrong, but that’s okay, we’ll take you back
          home
        </p>
        <button
          type="button"
          onClick={this.onGoBack}
          className="button--primary primary-font"
        >
          Go back
        </button>
      </div>
    );
  }
}

Error.contextType = ORDER_CONTEXT;
